import React from "react";
import TrachTubeWarningPng from "./assets/trach-tube.png";
import styled from "@emotion/styled";
import { Popover, Typography } from "@mui/material";
import { VitruvianAnimationForMedium } from "./assets/VitruvianAnimationForMedium";

const TrachTubeStyle = styled("img")`
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.3));
  top: 15.5%;
  left: 42%;
  width: 17%;
  animation-delay: 700ms;
  clip-path: polygon(
    52% 95%,
    56% 91%,
    55% 46%,
    65% 38%,
    90% 30%,
    91% 25%,
    87% 14%,
    70% 20%,
    67% 16%,
    63% 16%,
    53% 11%,
    44% 12%,
    34% 17%,
    29% 17%,
    28% 20%,
    12% 17%,
    9% 31%,
    28% 36%,
    33% 41%,
    37% 41%,
    41% 45%,
    45% 46%,
    45% 89%,
    48% 93%
  );
  ${VitruvianAnimationForMedium}
`;

function TrachTubeLayer() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <TrachTubeStyle
        src={TrachTubeWarningPng}
        aria-owns={open ? "TrachTube-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="TrachTube-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>Indwelling Tracheostomy tube</Typography>
      </Popover>
    </React.Fragment>
  );
}

export default TrachTubeLayer;
