import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { changeShowScoring } from "../redux/slices/showScoring";
import { changeShowCodes } from "../redux/slices/showCodes";

import { teal, grey, indigo } from "@mui/material/colors";
import { Palette as PaletteIcon } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton as MuiIconButton,
  ListItemButton,
  Typography,
} from "@mui/material";

import { THEMES } from "../constants";
import useTheme from "../hooks/useTheme";

type DemoButtonType = {
  active?: boolean;
};

const DemoButton = styled.div<DemoButtonType>`
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.paper};
  height: 80px;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.825rem;
  position: relative;
  border: 1px solid
    ${(props) =>
      !props.active
        ? props.theme.palette.action.selected
        : props.theme.palette.action.active};
`;

type DemoButtonInnerType = {
  selectedTheme: string;
};

const DemoButtonInner = styled.div<DemoButtonInnerType>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${(props) => props.theme.palette.action.selected};
  position: relative;

  ${(props) =>
    props.selectedTheme === THEMES.DEFAULT &&
    css`
      background: linear-gradient(-45deg, #23303f 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.THINLAYER &&
    css`
      background: linear-gradient(-45deg, ${indigo[500]} 50%, ${grey[100]} 0);
    `}
    ${(props) =>
    props.selectedTheme === THEMES.RAMSAY &&
    css`
      background: linear-gradient(-45deg, #297ca8 50%, ${grey[100]} 0);
    `}
    ${(props) =>
    props.selectedTheme === THEMES.PHOENIX &&
    css`
      background: linear-gradient(-45deg, #29496b 50%, ${grey[100]} 0);
    `}
    ${(props) =>
    props.selectedTheme === THEMES.NORTHWELL &&
    css`
      background: linear-gradient(-45deg, #007db8 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.DARK &&
    css`
      background: #23303f;
    `}
  ${(props) =>
    props.selectedTheme === THEMES.LIGHT &&
    css`
      background: ${grey[100]};
    `}
  ${(props) =>
    props.selectedTheme === THEMES.BLUE &&
    css`
      background: linear-gradient(-45deg, #4782da 50%, ${grey[100]} 0);
    `}
  ${(props) =>
    props.selectedTheme === THEMES.GREEN &&
    css`
      background: linear-gradient(-45deg, ${teal[500]} 50%, ${grey[100]} 0);
    `}
`;

const DemoTitle = styled(Typography)`
  text-align: center;
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }

  z-index: 1200;
`;

const Wrapper = styled.div`
  width: 258px;
  overflow-x: hidden;
`;

const Heading = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

type DemoTypes = {
  title: string;
  themeVariant: string;
};

function Demo({ title, themeVariant }: DemoTypes) {
  const { theme, setTheme } = useTheme();

  return (
    <Grid item xs={6}>
      <DemoButton
        active={themeVariant === theme}
        onClick={() => setTheme(themeVariant)}
      >
        <DemoButtonInner selectedTheme={themeVariant} />
      </DemoButton>
      <DemoTitle variant="subtitle2" gutterBottom>
        {title}
      </DemoTitle>
    </Grid>
  );
}

function Demos() {
  const dispatch = useDispatch();
  const showScoring = useSelector((state: any) => state.showScoring.value);
  const handleScoringChange = () => {
    localStorage.setItem("storedScores", JSON.stringify(!showScoring));
    dispatch(changeShowScoring(!showScoring));
  };
  const showCodes = useSelector((state: any) => state.showCodes.value);
  const handleCodesChange = () => {
    localStorage.setItem("storedCodes", JSON.stringify(!showCodes));
    dispatch(changeShowCodes(!showCodes));
  };

  return (
    <Wrapper>
      <Heading>Select a demo</Heading>

      <Box px={4} my={3}>
        <Grid container spacing={3}>
          <Demo title="ThinLayer" themeVariant={THEMES.THINLAYER} />
          <Demo title="NHS" themeVariant={THEMES.BLUE} />
          <Demo title="UCLH" themeVariant={THEMES.GREEN} />
          <Demo title="Ramsay" themeVariant={THEMES.RAMSAY} />
          <Demo title="Northwell" themeVariant={THEMES.NORTHWELL} />
          <Demo title="Phoenix" themeVariant={THEMES.PHOENIX} />
        </Grid>
      </Box>
      <Heading>Features</Heading>
      <Box px={4} mb={3}>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showScoring}
                  onChange={handleScoringChange}
                />
              }
              label="Scoring"
            />
            <FormControlLabel
              control={
                <Checkbox checked={showCodes} onChange={handleCodesChange} />
              }
              label="Codes"
            />
          </FormGroup>
        </Grid>
      </Box>
    </Wrapper>
  );
}

function Settings() {
  const [state, setState] = useState({
    isOpen: false,
  });

  const toggleDrawer = (open: boolean) => () => {
    setState({ ...state, isOpen: open });
  };

  return (
    <React.Fragment>
      <IconButton size="large" onClick={toggleDrawer(true)}>
        <PaletteIcon />
      </IconButton>
      {/* <Fab color="primary" aria-label="Edit" onClick={toggleDrawer(true)}>
        <PaletteIcon />
      </Fab> */}
      <Drawer anchor="right" open={state.isOpen} onClose={toggleDrawer(false)}>
        <Demos />
      </Drawer>
    </React.Fragment>
  );
}

export default Settings;
