import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import MdtIconSelect from "../../components/forms/formComponents/MdtIconSelect";
import PatientPicker from "../../components/forms/formComponents/PatientPicker";
import TeamPicker from "../../components/forms/formComponents/TeamPicker";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import "dayjs/locale/en-gb";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useState } from "react";

import {
  Box,
  Button,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { changeNewMeeting } from "../../redux/slices/newMeeting";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function MdtCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [meetingDesc, setMeetingDesc] = useState<string>("");
  const [meetingDate, setMeetingDate] = useState<Dayjs | null>(null);
  const [meetingTime, setMeetingTime] = useState<Dayjs | null>(null);
  const handleCreateMeeting = () => {
    dispatch(
      changeNewMeeting({
        desc: meetingDesc,
        date: meetingDate?.format("YYYY-MM-DD"),
        time: meetingTime?.format("hh:mm a"),
      })
    );
    console.log(meetingTime?.format("HH:mm"));
    navigate("/mdt/new");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ display: "flex", alignItems: "flex-start", mt: 3, px: 3 }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <MdtIconSelect />
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                label="Meeting Date"
                sx={{ ml: 3 }}
                value={meetingDate}
                onChange={(newValue) => setMeetingDate(newValue)}
              />
              <TimePicker
                ampm={true}
                label="Meeting Time"
                sx={{ ml: 3 }}
                value={meetingTime}
                onChange={(newValue) => setMeetingTime(newValue)}
              />
            </LocalizationProvider>
          </Box>
          <TextField
            id="outlined-basic"
            color="primary"
            sx={{ ml: 3, width: "30%" }}
            label="Description"
            value={meetingDesc}
            variant="outlined"
            onChange={(e) => setMeetingDesc(e.target.value)}
            multiline
          />
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<AddCircle />}
            sx={{ height: "51px", ml: 4 }}
            onClick={handleCreateMeeting}
          >
            <Typography variant="h5">Create Meeting</Typography>
          </Button>
        </Box>
        <Divider mt={6} mb={6} />
        <Box px={3}>
          <Typography variant="h4">Patients</Typography>
          <PatientPicker />
        </Box>
        <Divider mt={2} mb={6} />
        <Box px={3}>
          <Typography variant="h4" mt={6}>
            Team
          </Typography>
          <TeamPicker />
        </Box>
      </CardContent>
    </Card>
  );
}

function CreateMDT() {
  return (
    <React.Fragment>
      <Helmet title="Create MDT" />
      <Breadcrumbs aria-label="Breadcrumb" mb={5}>
        <Link component={NavLink} to="/">
          Home
        </Link>
        <Link component={NavLink} to="/mdt/dashboard">
          MDT Dashboard
        </Link>
      </Breadcrumbs>
      <Stack direction="row" pb={3} spacing={2} alignItems="center">
        <Typography variant="h3"> Create MDT meeting</Typography>
      </Stack>

      <Divider mb={6} mt={2} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MdtCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CreateMDT;
