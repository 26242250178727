import React from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
// import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { NEWS } from "../../constants";

// import { useGetPatientQuery } from "../../redux/store";

import PatientTimeline from "../../components/timeline/patientTimeline";
import PatientCharts from "../../components/patientChart/PatientCharts";

import DischargeReport from "../../components/patient/DischargeReport";
import PatientStoryApiTest from "../../components/patient/apiTestFiles/PatientStoryApiTest";
import GanttPanel from "../../components/gantt/GantPanel";

import {
  Avatar,
  Box,
  // CardContent,
  // CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Popover,
  Button,
  Tab,
  Typography,
} from "@mui/material";

import { GroupAdd, Mic } from "@mui/icons-material";
import { AiIcon } from "../../components/icons/TLSvgIcons";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function PanelDischarge() {
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={5} pr={8}>
          {/* Discharge  */}
          <DischargeReport />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={7}>
          {/* Timeline and charts  */}
          <PatientCharts />
          <PatientTimeline />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function PatientCard({ patient }: { patient: any }) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Card mb={6}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, mt: 4, mx: 8, borderColor: "divider" }}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab label="OVERVIEW" value="1" />
            <Tab label="MEDICATIONS" value="7" />
            <Tab label="LABORATORY" value="2" />
            <Tab label="MICROBIOLOGY" value="3" />
            <Tab label="IMAGING" value="4" />
            <Tab label="CARE TEAM" value="5" />
            <Tab label="DISCHARGE SUMMARY" value="6" />
            <Button sx={{ marginLeft: "auto" }} startIcon={<GroupAdd />}>
              Create MDT
            </Button>
          </TabList>
        </Box>
        <TabPanel sx={{ p: 0 }} value="1">
          <PatientStoryApiTest patient={patient} />
        </TabPanel>
        <TabPanel sx={{ p: 8 }} value="7">
          <GanttPanel />
        </TabPanel>
        <TabPanel sx={{ p: 8 }} value="6">
          <PanelDischarge />
        </TabPanel>
      </TabContext>
    </Card>
  );
}

function TLAssistant() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <Button
        aria-describedby={id}
        color="secondary"
        variant="contained"
        onClick={handleClick}
        startIcon={<AiIcon />}
      >
        TL Assistant
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            bgcolor: "background.paper",
            borderRadius: 1,
            p: 4,
            width: 300,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              borderRadius: 2,
              p: 3,
            }}
          >
            Are the nursing notes missing any relevant documentation of the
            patient's hospital course?
          </Box>
          <Box
            sx={{
              borderRadius: 2,
              p: 3,
              backgroundColor: "#FFFBF7",
              border: 1,
              borderColor: "secondary.main",
              color: "#000000CC",
              mt: 4,
            }}
          >
            It appears that several of the nursing notes are missing
            documentation of the patient's vital signs, pain levels, any changes
            in the patient's condition, and nursing interventions performed.
            Additionally, there is limited documentation of the patient's
            physical and mental state.
          </Box>
          <FormControl sx={{ mt: 6 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-query">Query</InputLabel>
            <OutlinedInput
              id="outlined-adornment-query"
              autoComplete="off"
              inputProps={{ autocomplete: "off" }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="voice entry" edge="end">
                    <Mic />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          {/* <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <IconButton aria-label="attach">
              <AttachFile />
            </IconButton>
            <IconButton color="primary" aria-label="send">
              <Send />
            </IconButton>
          </Stack> */}
        </Box>
      </Popover>
    </div>
  );
}

function Patient() {
  // const { patientId } = useParams<{ patientId: string }>();

  // const { data, error, isLoading } = useGetPatientQuery(patientId);

  const demoPatient = {
    id: "1",
    firstName: "Susan",
    lastName: "Calvin",
    mrn: "1434534",
    age: "71",
    acuity: 3,
    hospitalDuration: "8",
    location: "SICU",
    dob: "1953-03-24",
    height: "152",
    weight: "72",
    conditions: [
      "Congestive Heart Failure",
      "COPD",
      "Gastroesophageal Reflux Disease",
      "Hypothyroidism",
      "Lung Cancer",
      "Pneumonia",
      "Pulmonary Hypertension",
      "Respiratory Failure",
      "Tracheomalacia",
      "UTI",
    ],
    allergies: ["Penicillin", "Codeine"],
  };

  const content = <PatientCard patient={demoPatient} />;

  return (
    <React.Fragment>
      <Helmet title="Susan Calvin" />
      <Breadcrumbs aria-label="Breadcrumb" mb={5}>
        <Link component={NavLink} to="/">
          Home
        </Link>
        <Link component={NavLink} to="/list-starred">
          Starred
        </Link>
      </Breadcrumbs>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Avatar
            aria-label={"NEWS score: " + demoPatient.acuity}
            sx={{
              bgcolor: NEWS[demoPatient.acuity],
              color: "black",
              border: "1px solid #0000001A",
              mr: 3,
            }}
          >
            {demoPatient.acuity}
          </Avatar>
          <Typography variant="h3" display="inline">
            Susan Calvin
          </Typography>
        </Box>
        <TLAssistant />
      </Box>

      <Divider mb={6} mt={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Patient;
