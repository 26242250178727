import { createSlice } from "@reduxjs/toolkit";

export interface SnapshotState {
  value: number;
}

const initialState: SnapshotState = {
  value: 29,
};

const snapshotSlice = createSlice({
  name: "snapshot",
  initialState,
  reducers: {
    changeSnapshot: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeSnapshot } = snapshotSlice.actions;

export default snapshotSlice.reducer;
