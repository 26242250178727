import { createSlice } from "@reduxjs/toolkit";

export interface CalvinStarState {
  value: boolean;
}

const initialState: CalvinStarState = {
  value: true,
};

const calvinStarSlice = createSlice({
  name: "calvinStar",
  initialState,
  reducers: {
    changeCalvinStar: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeCalvinStar } = calvinStarSlice.actions;

export default calvinStarSlice.reducer;
