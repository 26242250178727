import { Paper } from "@mui/material";
import styled from "@emotion/styled";
// import * as icons from "../timeline/TimelineIcons";
import SimpleTimelineMarker from "../SimpleTimelineMarker";
import TLIcon from "../../icons/TLIcon";
import { useDispatch } from "react-redux";
import { changeDay } from "../../../redux/slices/day";

const Slot = styled(Paper)({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  alignItems: "flex-start",
  gap: "4px",
  backgroundColor: "#F7F9FC",
  borderTop: "1px solid #E4E4E4",
  borderBottom: "1px solid #E4E4E4",
  padding: "5px 5px",
  boxShadow: "none",
  borderRadius: 0,
});

const TimelineMarkerSlot = (props: any) => {
  const dispatch = useDispatch();
  const handleClick = (newValue: number) => {
    dispatch(changeDay(newValue));
  };

  function getFilteredReports() {
    return props.reports.filter((report: any) => report.icon !== "NurseIcon");
  }

  return (
    <Slot onClick={() => handleClick(props.value)}>
      {getFilteredReports().map((report: any) => {
        // const ThisIcon = icons.stringMap[report.icon];
        return (
          <SimpleTimelineMarker
            color={report.color}
            bgColor={report.bgColor}
            icon={<TLIcon name={report.icon} />}
            label={report.title}
            key={report.id}
          />
        );
      })}
    </Slot>
  );
};

export default TimelineMarkerSlot;
