import React from "react";
import { Helmet } from "react-helmet-async";
import ScreenImage from "../../assets/images/Add Code_V1.png";

function CodingDetail() {
  return (
    <React.Fragment>
      <Helmet title="Coding Detail" />
      <img src={ScreenImage} alt="GP view" style={{ width: "100%" }} />
    </React.Fragment>
  );
}

export default CodingDetail;
