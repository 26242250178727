import * as React from "react";

import { Box, Fab, IconButton, Popover, Stack } from "@mui/material";

// import { List } from "@mui/icons-material";
import {
  AccessAlarm,
  Accessible,
  AccountBalance,
  AccountCircle,
  AddLocation,
  AddBox,
  AllInclusive,
  Apartment,
  Assessment,
  AttachFile,
  Audiotrack,
  BackHand,
  Balance,
  Biotech,
  Bookmark,
  BrightnessHigh,
  Cable,
  Calculate,
  CalendarMonth,
  Chair,
  ChildFriendly,
  Coffee,
  Groups,
  Home,
  List,
  LocalHospital,
  ManageSearch,
  Person,
  PostAdd,
  Preview,
  Receipt,
  RecentActors,
  Star,
  ViewWeek,
  Warning as DischargeDue,
  WorkspacePremium,
} from "@mui/icons-material";

const iconArray = [
  <AccessAlarm />,
  <Accessible />,
  <AccountBalance />,
  <AccountCircle />,
  <AddLocation />,

  <AddBox />,
  <AllInclusive />,
  <Apartment />,
  <Assessment />,
  <AttachFile />,
  <Audiotrack />,
  <BackHand />,
  <Balance />,
  <Biotech />,
  <Bookmark />,
  <BrightnessHigh />,
  <Cable />,
  <Calculate />,
  <CalendarMonth />,
  <Chair />,
  <ChildFriendly />,
  <Coffee />,

  <Groups />,
  <Home />,
  <List />,
  <LocalHospital />,
  <ManageSearch />,
  <Person />,
  <PostAdd />,
  <Preview />,
  <Receipt />,
  <RecentActors />,
  <Star />,
  <ViewWeek />,
  <DischargeDue />,
  <WorkspacePremium />,
];

function MdtIconSelect() {
  const [icon, setIcon] = React.useState(<Groups />);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <Fab
        color="primary"
        aria-label="Select icon"
        onClick={handleClick}
        size="small"
        sx={{ boxShadow: 0 }}
      >
        {icon}
      </Fab>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            bgcolor: "background.paper",
            borderRadius: 1,
            p: 4,
            width: 308,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            useFlexGap
            sx={{ flexWrap: "wrap" }}
          >
            {iconArray.map((icon, index) => (
              <IconButton
                key={index}
                onClick={() => {
                  setIcon(icon);
                  handleClose();
                }}
              >
                {icon}
              </IconButton>
            ))}
          </Stack>
        </Box>
      </Popover>
    </React.Fragment>
  );
}

export default MdtIconSelect;
