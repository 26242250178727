const tempUp = (ctx: any, value: any) => {
  const difference = ctx.p0.parsed.y - ctx.p1.parsed.y;
  const baseline = ctx.p1.parsed.y > 37.2;
  if (difference < -0.2 && baseline) {
    return value;
  }
  return undefined;
};

const chartData = [
  {
    chartName: "HR",
    data: {
      labels: [
        "#1 AM",
        "#1 PM",
        "#2 AM",
        "#2 PM",
        "#3 AM",
        "#3 PM",
        "#4 AM",
        "#4 PM",
        "#5 AM",
        "#5 PM",
        "#6 AM",
        "#6 PM",
        "#7 AM",
        "#7 PM",
        "#8 AM",
        "#8 PM",
      ],
      datasets: [
        {
          label: "HR",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#313F9F",
          tension: 0,
          data: [
            84, 92, 100, 103, 120, 150, 145, 160, 120, 118, 110, 105, 84, 92,
            100, 103,
          ],
        },
      ],
    },
  },
  {
    chartName: "Temp",
    data: {
      labels: [
        "#1 AM",
        "#1 PM",
        "#2 AM",
        "#2 PM",
        "#3 AM",
        "#3 PM",
        "#4 AM",
        "#4 PM",
        "#5 AM",
        "#5 PM",
        "#6 AM",
        "#6 PM",
        "#7 AM",
        "#7 PM",
        "#8 AM",
        "#8 PM",
      ],
      datasets: [
        {
          label: "Temp",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#313F9F",
          tension: 0,
          data: [
            36.4, 36.8, 37.2, 37.6, 38.1, 38, 38.6, 39, 37.5, 37.6, 36.5, 36.8,
            36.4, 36.8, 37.2, 37.3,
          ],
          spanGaps: true,
          segment: {
            borderColor: (ctx: any) => tempUp(ctx, "#F44336") || "#313F9F",
            backgroundColor: (ctx: any) =>
              tempUp(ctx, "#F4433633") || "transparent",
          },
        },
      ],
    },
  },
  {
    chartName: "RR",
    data: {
      labels: [
        "#1 AM",
        "#1 PM",
        "#2 AM",
        "#2 PM",
        "#3 AM",
        "#3 PM",
        "#4 AM",
        "#4 PM",
        "#5 AM",
        "#5 PM",
        "#6 AM",
        "#6 PM",
        "#7 AM",
        "#7 PM",
        "#8 AM",
        "#8 PM",
      ],
      datasets: [
        {
          label: "RR",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#313F9F",
          tension: 0,
          data: [9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48, 51, 54],
        },
      ],
    },
  },
  {
    chartName: "BP",
    data: {
      labels: [
        "#1 AM",
        "#1 PM",
        "#2 AM",
        "#2 PM",
        "#3 AM",
        "#3 PM",
        "#4 AM",
        "#4 PM",
        "#5 AM",
        "#5 PM",
        "#6 AM",
        "#6 PM",
        "#7 AM",
        "#7 PM",
        "#8 AM",
        "#8 PM",
      ],
      datasets: [
        {
          label: "SBP",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#313F9F",
          borderDash: [4, 4],
          tension: 0,
          data: [
            105, 110, 120, 115, 100, 107, 123, 126, 135, 115, 123, 107, 105,
            110, 120, 115,
          ],
        },
        {
          label: "DBP",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#FF932E",
          tension: 0,
          data: [
            80, 88, 97, 102, 93, 90, 86, 80, 76, 90, 87, 77, 80, 85, 90, 95,
          ],
        },
      ],
    },
  },
  {
    chartName: "SpO2",
    data: {
      labels: [
        "#1 AM",
        "#1 PM",
        "#2 AM",
        "#2 PM",
        "#3 AM",
        "#3 PM",
        "#4 AM",
        "#4 PM",
        "#5 AM",
        "#5 PM",
        "#6 AM",
        "#6 PM",
        "#7 AM",
        "#7 PM",
        "#8 AM",
        "#8 PM",
      ],
      datasets: [
        {
          label: "SPo2",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#313F9F",
          tension: 0,
          data: [
            60, 62, 64, 66, 68, 70, 72, 74, 76, 78, 80, 82, 84, 86, 88, 90,
          ],
        },
      ],
    },
  },
  {
    chartName: "Hct",
    data: {
      labels: [
        "#1 AM",
        "#1 PM",
        "#2 AM",
        "#2 PM",
        "#3 AM",
        "#3 PM",
        "#4 AM",
        "#4 PM",
        "#5 AM",
        "#5 PM",
        "#6 AM",
        "#6 PM",
        "#7 AM",
        "#7 PM",
        "#8 AM",
        "#8 PM",
      ],
      datasets: [
        {
          label: "Hct",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#313F9F",
          borderDash: [4, 4],
          tension: 0,
          data: [
            10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 60, 55, 50, 45,
          ],
        },
      ],
    },
  },
  {
    chartName: "Hgb",
    data: {
      labels: [
        "#1 AM",
        "#1 PM",
        "#2 AM",
        "#2 PM",
        "#3 AM",
        "#3 PM",
        "#4 AM",
        "#4 PM",
        "#5 AM",
        "#5 PM",
        "#6 AM",
        "#6 PM",
        "#7 AM",
        "#7 PM",
        "#8 AM",
        "#8 PM",
      ],
      datasets: [
        {
          label: "Hgb",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#313F9F",
          borderDash: [4, 4],
          tension: 0,
          data: [
            10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 20, 19, 18, 17,
          ],
        },
      ],
    },
  },
];

export default chartData;
