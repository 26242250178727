import * as React from "react";

import { Box, Typography } from "@mui/material";
import * as icons from "../timeline/TimelineIcons";
import ReportCardApi from "../../patientCards/ReportCardApi";
import ReportCardPicture from "../../patientCards/ReportCardPicture";
import XRayPicture from "../../../assets/images/x-ray.png";
import EKG from "../../../assets/images/ekg.png";
import PETScan from "../../../assets/images/cardiac-pet-scan.png";
import PreOpLabs from "./PreOpLabs";

const MDTReports = () => {
  return (
    <Box>
      <Typography variant="h4" sx={{ pl: 2, mt: 4, mb: 4 }}>
        Report Timeline
      </Typography>
      {/* <Typography variant="body2" sx={{ pl: 2, mb: 1 }}>
        January 20th, 2019
      </Typography> */}

      <ReportCardApi
        title={
          new Date("2019-01-20").toLocaleDateString() +
          " - Hospitalisation (NSTEMI)"
        }
        content="NSTEMI in Left Anterior Descending coronary distribution with preserved LVEF (50%); successfully stented without complications."
        color="#777777"
        bgColor="#F8F9FC"
        icon="local_hospital"
        open={false}
        key={1}
        date="2019-01-20"
        time="13:00"
        by="Dr Eric Ries"
      />

      <ReportCardApi
        title="12/15/2023 -  GP Visit & Referral"
        content="Regular annual checkup, noteworthy for right hip discomfort limiting his ability to exercise, and making climbing up to his second story flat difficult. Referral to orthopedics."
        color="#777777"
        bgColor="#F8F9FC"
        speciality="G.P."
        icon="medical_services"
        open={false}
        key={3}
        date="2023-12-15"
        time="13:00"
        by="Dr Nick Taylor"
      />
      <ReportCardApi
        title={
          new Date("2023-12-29").toLocaleDateString() + "-  Orthopaedic Consult"
        }
        content="Hip series shows degenerative changes to R hip consistent with osteoarthritis. Candidate for hip arthroplasty if he can be cleared from a CV perspective."
        color="#777777"
        bgColor="#F8F9FC"
        icon="local_hospital"
        speciality="Orthopaedic Surgeon"
        open={false}
        key={4}
        date="2023-12-29"
        time="13:00"
        by="Mr Cash"
      />
      <ReportCardPicture
        title={new Date("2023-12-29").toLocaleDateString() + " -  Hip X-Rays"}
        content="Osteoarthritic changes, R hip."
        color="#777777"
        bgColor="#F8F9FC"
        speciality="Staff Radiologist"
        icon={<icons.XrayIcon />}
        picture={XRayPicture}
        open={true}
        key={5}
        date={new Date("2023-12-29").toLocaleDateString()}
        time="13:00"
        by="Dr John Ferrier"
      />
      <ReportCardPicture
        title={
          new Date("2024-01-10").toLocaleDateString() + " - Cardiac PET scan"
        }
        content="Pharmacologic cardiac stress test/PET scan: no focal ischemic abnormalities"
        color="#777777"
        bgColor="#F8F9FC"
        speciality="Cardiologist"
        icon={<icons.MonitorHeart />}
        picture={PETScan}
        open={true}
        key={6}
        date={new Date("2024-01-10").toLocaleDateString()}
        time="13:00"
        by="Dr Mark Appleby"
      />
      <PreOpLabs />

      <ReportCardPicture
        title={new Date("2024-01-10").toLocaleDateString() + " -  EKG"}
        content="Normal sinus rhythm; old anterior wall MI apparent acute ischemia."
        color="#777777"
        bgColor="#F8F9FC"
        icon={<icons.MonitorHeart />}
        speciality="Cardiologist"
        picture={EKG}
        open={false}
        key={7}
        date={new Date("2024-01-10").toLocaleDateString()}
        time="13:00"
        by="Dr Mark Appleby"
      />
    </Box>
  );
};

export default MDTReports;
