import Pop from "./Pop";
import { Box, IconButton, Typography } from "@mui/material";

import { AiIcon } from "../icons/TLSvgIcons";

interface AiApprovalProps {
  approved: boolean;
  approvedBy: string;
  content: JSX.Element;
}

const AiApproval = ({ approved, approvedBy, content }: AiApprovalProps) => {
  return (
    <Pop
      borderColor="#ffcea2"
      backgroundColor="#fffbf7"
      textcolor="black"
      padding="8px 0 0 8px"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <span>{content}</span>
        <Typography align="right">
          <IconButton
            aria-label="approve"
            size="small"
            color="secondary"
            sx={{ marginLeft: "auto" }}
          >
            <AiIcon />
          </IconButton>
        </Typography>
      </Box>
    </Pop>
  );
};

export default AiApproval;
