import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import snapshotReducer from "./slices/snapshot";
import calvinReducer from "./slices/calvinStar";
import marcotteReducer from "./slices/marcotteStar";
import commentTimeline from "./slices/commentTimeline";
import dayReducer from "./slices/day";
import graphSizeReducer from "./slices/graphSize";
import graphAxisReducer from "./slices/graphAxis";
import graphTypeReducer from "./slices/graphType";
import newMeetingReducer from "./slices/newMeeting";
import newListReducer from "./slices/newList";
import showScoring from "./slices/showScoring";
import showCodes from "./slices/showCodes";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { patientApi } from "./apis/patientApi";
import { scoresApi } from "./apis/scoresApi";
import { vitruvianApi } from "./apis/vitruvianApi";

import { observationsApi } from "./apis/observationsApi";
import { summaryApi } from "./apis/summaryApi";
import { reportsApi } from "./apis/reportsApi";
import { admissionsApi } from "./apis/admissionsApi";

export const store = configureStore({
  reducer: {
    [patientApi.reducerPath]: patientApi.reducer,
    [scoresApi.reducerPath]: scoresApi.reducer,
    [vitruvianApi.reducerPath]: vitruvianApi.reducer,
    [observationsApi.reducerPath]: observationsApi.reducer,
    [summaryApi.reducerPath]: summaryApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [admissionsApi.reducerPath]: admissionsApi.reducer,
    counter: counterReducer,
    snapshot: snapshotReducer,
    calvinStar: calvinReducer,
    marcotteStar: marcotteReducer,
    commentTimeline: commentTimeline,
    showScoring: showScoring,
    showCodes: showCodes,
    day: dayReducer,
    graphSize: graphSizeReducer,
    graphAxis: graphAxisReducer,
    graphType: graphTypeReducer,
    newMeeting: newMeetingReducer,
    newList: newListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      patientApi.middleware,
      scoresApi.middleware,
      vitruvianApi.middleware,
      observationsApi.middleware,
      summaryApi.middleware,
      reportsApi.middleware,
      admissionsApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export { useGetPatientQuery } from "./apis/patientApi";
export { useGetScoresQuery } from "./apis/scoresApi";
export { useGetVitruvianQuery } from "./apis/vitruvianApi";
export { useGetObservationsQuery } from "./apis/observationsApi";
export { useGetSummaryQuery } from "./apis/summaryApi";
export { useGetReportsQuery } from "./apis/reportsApi";
export { useGetAdmissionsQuery } from "./apis/admissionsApi";

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
