import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import styled from "@emotion/styled";
import { Line } from "react-chartjs-2";
import chartData from "./chartData";

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        color: "rgba(0,0,0,0.05)",
      },
    },
    y: {
      display: true,
      borderDash: [5, 5],
      grid: {
        color: "rgba(0,0,0,0)",
        fontColor: "#fff",
      },
    },
  },
};

const ChartWrapper = styled.div`
  height: 240px;
  width: 100%;
`;

function PatientCharts() {
  const [whichChart, setWhichChart] = useState<string>("HR");

  const handleChartChange = (
    event: React.MouseEvent<HTMLElement>,
    newWhichChart: string
  ) => {
    setWhichChart(newWhichChart);
  };

  const findChartObject = (whichChart: string) => {
    return chartData.find((chart) => chart.chartName === whichChart);
  };

  const chartObject = findChartObject(whichChart);

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "grey.300",
        borderRadius: 3,
        px: 2,
        py: 2,
        height: 300,
        mb: 4,

        position: "relative",
      }}
    >
      <ToggleButtonGroup
        color="primary"
        value={whichChart}
        exclusive
        onChange={handleChartChange}
        size="small"
        sx={{
          mb: 2,
        }}
      >
        <ToggleButton value="HR">HR</ToggleButton>
        <ToggleButton value="Temp">Temp</ToggleButton>
        <ToggleButton value="RR">RR</ToggleButton>
        <ToggleButton value="BP">BP</ToggleButton>
        <ToggleButton value="SpO2">SpO2</ToggleButton>
        <ToggleButton value="Hct">Hct</ToggleButton>
        <ToggleButton value="Hgb">Hgb</ToggleButton>
      </ToggleButtonGroup>
      <ChartWrapper>
        <Line data={(chartObject as any).data} options={options} />
      </ChartWrapper>
    </Box>
  );
}

export default PatientCharts;
