import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseApi } from "../../config";

const patientApi = createApi({
  reducerPath: "patientApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseApi.url,
  }),
  endpoints: (builder) => ({
    getPatient: builder.query({
      query: (id) => {
        return {
          url: `/patient/${id}`,
          method: "GET",
          headers: {
            "x-api-key":
              "PMAK-6564d8090d4c2f36a8aa257b-3030dde9b280ee9393e56a5cee917b7bb0",
          },
        };
      },
    }),
  }),
});

export const { useGetPatientQuery } = patientApi;
export { patientApi };

// headers: {
//     "X-Api-Key":
//       "PMAK-6564d8090d4c2f36a8aa257b-3030dde9b280ee9393e56a5cee917b7bb07",
//   },

// baseUrl: "https://ade4bfd8-c188-4e0b-9831-aefe0fe297cf.mock.pstmn.io/",
// REACT_APP_API_BASE_URL
