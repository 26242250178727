import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Button,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { AddCircle, Groups } from "@mui/icons-material";
import MdtTeam from "../../components/patient/mdt/MdtTeam";

import MdtMeetingList from "../../components/patient/mdt/MdtMeetingList";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function EmptyCard() {
  return (
    <Card mb={6}>
      <MdtMeetingList />
    </Card>
  );
}

function MDTListPage() {
  const thisDate = new Date().toDateString();
  return (
    <React.Fragment>
      <Helmet title="MDT" />

      <Breadcrumbs aria-label="Breadcrumb" mb={2}>
        <Link component={NavLink} to="/">
          Home
        </Link>
        <Link component={NavLink} to="/mdt/dashboard">
          MDT Dashboard
        </Link>
      </Breadcrumbs>
      <Grid justifyContent="space-between" alignItems="center" container>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Groups fontSize="large" color="primary" />
            <Typography variant="h3"> MDT Meeting - {thisDate}</Typography>
          </Stack>
        </Grid>

        <Grid item pb={0}>
          <Stack direction="row" spacing={2} alignItems="center">
            <MdtTeam />
            <Button
              component={NavLink}
              to="/mdt/add-patient"
              variant="contained"
              color="primary"
              startIcon={<AddCircle />}
            >
              Add Patient
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <Divider mb={6} mt={2} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default MDTListPage;
