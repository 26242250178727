import ReportCard from "../../patientCards/ReportCard";
import ReportCardPictureOnly from "../../patientCards/ReportCardPictureOnly";
import FrailtyPic from "../../../assets/images/frailty.png";
import RiskAssessment from "./RiskAssesment";
import { Box, Typography } from "@mui/material";

const CCI = () => {
  const content = (
    <div>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Charlson Comorbidity Index (CCI): 4 points
      </Typography>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Estimated 10-year mortality: 53%
      </Typography>
      <p>
        INPUTS: Age —{`>`} 3 = 70–79 years
        <br />
        Myocardial infarction —{`>`} 1 = Yes
        <br />
        CHF —{`>`} 0 = No
        <br />
        Peripheral vascular disease —{`>`} 0 = No
        <br />
        CVA or TIA —{`>`} 0 = No
        <br />
        Dementia —{`>`} 0 = No
        <br />
        COPD —{`>`} 0 = No
        <br />
        Connective tissue disease —{`>`} 0 = No
        <br />
        Peptic ulcer disease —{`>`} 0 = No
        <br />
        Liver disease —{`>`} 0 = None
        <br />
        Diabetes mellitus —{`>`} 0 = None or diet controlled
        <br />
        Hemiplegia —{`>`} 0 = No
        <br />
        Moderate to severe CKD —{`>`} 0 = No
        <br />
        Solid tumor —{`>`} 0 = None
        <br />
        Leukemia —{`>`} 0 = No
        <br />
        Lymphoma —{`>`} 0 = No
        <br />
        AIDS —{`>`} 0 = No
        <br />
      </p>
    </div>
  );
  return (
    <ReportCard
      title="Charlson Comorbidity Index: 4"
      content={content}
      open={false}
      padding={2}
    />
  );
};

const PatientScoresMDT = () => {
  return (
    <Box pl={2}>
      <Typography variant="h4" sx={{ mt: 0, mb: 3, pl: 2 }}>
        Risks
      </Typography>

      <RiskAssessment />

      <CCI />
      <ReportCardPictureOnly
        title="Clinical Frailty Scale: 6"
        picture={FrailtyPic}
        open={false}
        padding={2}
      />
    </Box>
  );
};

export default PatientScoresMDT;
