import React from "react";
import styled from "@emotion/styled";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";

import { Card as MuiCard } from "@mui/material";
import { DataGrid, GridEventListener, GridToolbar } from "@mui/x-data-grid";

import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";

import mdtListData from "./MdtListData";
import MdtMeetingListOptions from "./MdtMeetingListOptions";
import { NEWS } from "../../../constants";

const Card = styled(MuiCard)(spacing);

const MdtMeetingList = () => {
  const navigate = useNavigate();
  const handleCellClick: GridEventListener<"cellClick"> = (params) => {
    if (params.field !== "actions") {
      navigate(`/mdt/patient/${params.id}`);
    }
  };

  return (
    <Card mb={6}>
      <DataGrid
        sx={{ pt: 1 }}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        rows={mdtListData}
        columns={[
          {
            field: "news",
            headerName: "NEWS",
            headerAlign: "center",
            align: "center",
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: NEWS[params.value],
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      border: "1px solid #0000001A",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {params.value}
                  </div>
                </div>
              );
            },
          },

          { field: "name", headerName: "Name", width: 180 },
          {
            field: "complaint",
            headerName: "Presenting Complaint",
            flex: 1,
          },
          {
            field: "owner",
            headerName: "Owner",
            width: 200,
          },
          {
            field: "request",
            headerName: "Request Raised",
            disableColumnMenu: true,
            headerAlign: "center",
            align: "center",
            width: 140,
            renderCell: (params) => {
              return params.value + " days";
            },
          },
          {
            field: "review",
            headerName: "Review",
            disableColumnMenu: true,
            width: 80,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
              if (params.value) {
                return <CheckCircle color="success" />;
              } else {
                return <RadioButtonUnchecked color="disabled" />;
              }
            },
          },
          {
            field: "actions",
            headerName: "Actions",
            headerAlign: "center",
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            width: 80,
            renderCell: (params) => {
              return <MdtMeetingListOptions />;
            },
          },
        ]}
        onCellClick={handleCellClick}
        pageSizeOptions={[5, 10, 25, 100]}
      />
    </Card>
  );
};

export default MdtMeetingList;
