import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DashboardLayoutSVG from "./layouts/DashboardSVG";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Page components
import Blank from "./pages/pages/Blank";
import StarredListPage from "./pages/pages/StarredListPage";
import TuesdayListPage from "./pages/pages/TuesdayListPage";
import DischargeDueListPage from "./pages/pages/DischargeDueListPage";
import ICUStudyListPage from "./pages/pages/ICUStudyListPage";
import MDTListPage from "./pages/pages/MDTListPage";
import ListPage from "./pages/pages/ListPage";
import PatientDetailPage from "./pages/pages/PatientDetailPage";
import PatientViewPage from "./pages/pages/PatientViewPage";
import Home from "./pages/pages/Home";
import Reports from "./pages/pages/Reports";
import APITest from "./pages/pages/APITest";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Pricing from "./pages/pages/Pricing";
import Settings from "./pages/pages/Settings";
import Projects from "./pages/pages/Projects";
import Chat from "./pages/pages/Chat";
import Accreditation from "./pages/pages/Accreditation";
import GPPage from "./pages/pages/GPPage";
import CodingList from "./pages/pages/CodingList";
import CodingDetail from "./pages/pages/CodingDetail";

import PatientCodingPage from "./pages/pages/PatientCodingPage";
import Patient from "./pages/pages/Patient";
import PatientMDT from "./pages/pages/PatientMDT";
import PatientApiTest from "./pages/pages/PatientApiTest";
import CreateMDT from "./pages/pages/CreateMDT";
import MDTNewListPage from "./pages/pages/MDTNewListPage";
import CreateList from "./pages/pages/CreateList";
import NewListPage from "./pages/pages/NewListPage";

import Management from "./pages/pages/Management";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import GanttTestPage from "./pages/pages/GantTestPage";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const MdtDashboard = async(() => import("./pages/pages/MdtDashboard"));

const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// element: (
//   <AuthGuard>
//     <DashboardLayout />
//   </AuthGuard>
// ),

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),

    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/patient/:patientId",
        element: <Patient />,
      },
      {
        path: "/patient-mdt/:patientId",
        element: <PatientMDT />,
      },
      {
        path: "/mdt/patient/:patientId",
        element: <PatientMDT />,
      },
      {
        path: "/mdt/:meetingId",
        element: <MDTListPage />,
      },
      {
        path: "/mdt/new",
        element: <MDTNewListPage />,
      },
      {
        path: "/mdt/dashboard",
        element: <MdtDashboard />,
      },
      {
        path: "/mdt/create-meeting",
        element: <CreateMDT />,
      },
      {
        path: "/create-list",
        element: <CreateList />,
      },
      {
        path: "/new-list",
        element: <NewListPage />,
      },
      {
        path: "/patient-api-test/:patientId",
        element: <PatientApiTest />,
      },
      {
        path: "/list-page",
        element: <ListPage />,
      },
      {
        path: "/list-starred",
        element: <StarredListPage />,
      },
      {
        path: "/list-discharge-due",
        element: <DischargeDueListPage />,
      },
      {
        path: "/list-tuesday",
        element: <TuesdayListPage />,
      },
      {
        path: "/list-icu-study",
        element: <ICUStudyListPage />,
      },
      {
        path: "/patient-detail",
        element: <PatientDetailPage />,
      },
      {
        path: "/patient-coding",
        element: <PatientCodingPage />,
      },
      {
        path: "/coding-list",
        element: <CodingList />,
      },
      {
        path: "/coding-detail",
        element: <CodingDetail />,
      },
      {
        path: "/accreditation",
        element: <Accreditation />,
      },
      {
        path: "/gp-view",
        element: <GPPage />,
      },
      {
        path: "/patient-view",
        element: <PatientViewPage />,
      },
      {
        path: "/gantt",
        element: <GanttTestPage />,
      },
      {
        path: "/management",
        element: <Management />,
      },
      {
        path: "/discharge-report",
        element: <Reports />,
      },
      {
        path: "/APItest",
        element: <APITest />,
      },
    ],
  },
  {
    path: "svg",
    element: <DashboardLayoutSVG />,
    children: [
      {
        path: "gantt",
        element: <GanttTestPage />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "invoices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <InvoiceList />,
      },
      {
        path: "detail",
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: "orders",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Orders />,
      },
    ],
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },

  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
