declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    ml: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    ml: 1120,
    lg: 1280,
    xl: 1500,
    xxl: 2000,
  },
};

export default breakpoints;
