import React, { useState, useEffect, ReactNode } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { MessageCircle } from "react-feather";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { faker } from "@faker-js/faker";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { orange, green, red } from "@mui/material/colors";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

const MessageCircleIcon = styled(MessageCircle)`
  color: ${(props) => props.theme.palette.grey[500]};
  vertical-align: middle;
`;

const TaskBadge = styled.div`
  background: ${(props) => props.color};
  width: 40px;
  height: 6px;
  border-radius: 6px;
  display: inline-block;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const TaskNotifications = styled.div`
  display: flex;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(4)};
  right: ${(props) => props.theme.spacing(4)};
`;

const TaskNotificationsAmount = styled.div`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 600;
  margin-right: ${(props) => props.theme.spacing(1)};
  line-height: 1.75;
`;

const Typography = styled(MuiTypography)(spacing);

const TaskTitle = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
`;
interface LaneProps {
  column: {
    title: string;
    description: string;
  };
  children: ReactNode;
}

interface TaskProps {
  item: {
    badges: any;
    name: string;
    notifications: any;
    mrn: string;
    dob: string;
  };
}

const mockItems1 = [
  {
    id: faker.datatype.uuid(),
    name: "MARCOTTE, Ethan",
    badges: [green[600]],
    notifications: 0,
    mrn: "1435748",
    dob: "06/07/1966",
  },
  {
    id: faker.datatype.uuid(),
    name: "YANG, Chan-Yeol",
    badges: [green[600]],
    notifications: 0,
    mrn: "1494841",
    dob: "12/01/1956",
  },
  {
    id: faker.datatype.uuid(),
    name: "SANTILIAN, Jessica",
    badges: [orange[600]],
    notifications: 0,
    mrn: "1475758",
    dob: "04/05/1972",
  },
];

const mockItems2 = [
  {
    id: faker.datatype.uuid(),
    name: "HOUGHTON, James",
    badges: [orange[600]],
    notifications: 0,
    mrn: "1493268",
    dob: "11/023/1972",
  },
];

const mockItems3 = [
  {
    id: faker.datatype.uuid(),
    name: "ANDREWS, Nancy",
    badges: [green[600]],
    notifications: 0,
    mrn: "1309884",
    dob: "01/05/1975",
  },
];
const mockItems4 = [
  {
    id: faker.datatype.uuid(),
    name: "CHURCH, Donald",
    badges: [red[600]],
    notifications: 0,
    mrn: "1402947",
    dob: "09/08/1994",
  },
  {
    id: faker.datatype.uuid(),
    name: "SMITH, John",
    badges: [green[600]],
    notifications: 0,
    mrn: "1402947",
    dob: "09/08/2004",
  },
];
const mockItems5 = [
  {
    id: faker.datatype.uuid(),
    name: "HILL, Tony",
    badges: [green[600]],
    notifications: 0,
    mrn: "1402947",
    dob: "03/16/1969",
  },
];
const mockItems6 = [
  {
    id: faker.datatype.uuid(),
    name: "KOTI, Manjunath",
    badges: [green[600]],
    notifications: 0,
    mrn: "1402947",
    dob: "04/28/1971",
  },
];

const mockColumns = {
  [faker.datatype.uuid()]: {
    title: "Review",
    description: "",
    items: mockItems1,
  },
  [faker.datatype.uuid()]: {
    title: "Discharge approved",
    description: "",
    items: mockItems2,
  },
  [faker.datatype.uuid()]: {
    title: "Wed - Aug 16th",
    description: "",
    items: mockItems3,
  },
  [faker.datatype.uuid()]: {
    title: "Thu - Aug 17th",
    description: "",
    items: mockItems4,
  },
  [faker.datatype.uuid()]: {
    title: "Fri - Aug 18th",
    description: "",
    items: mockItems5,
  },
  [faker.datatype.uuid()]: {
    title: "Sat - Aug 19th",
    description: "",
    items: mockItems6,
  },
};

const onDragEnd = (result: DropResult, columns: any, setColumns: any) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

const Lane = ({ column, children }: LaneProps) => {
  return (
    <Grid item xs={12} sm={6} md={2}>
      <Card mb={6}>
        <CardContent pb={0}>
          <Typography variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};
const ReviewLane = ({ column, children }: LaneProps) => {
  return (
    <Grid item xs={12} sm={6} md={2}>
      <Card
        style={{
          backgroundColor: orange[50],
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: orange[100],
        }}
        mb={6}
      >
        <CardContent pb={0}>
          <Typography sx={{ color: orange[900] }} variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};
const ApprovedLane = ({ column, children }: LaneProps) => {
  return (
    <Grid item xs={12} sm={6} md={2}>
      <Card
        style={{
          backgroundColor: green[50],
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: green[100],
        }}
        mb={6}
      >
        <CardContent pb={0}>
          <Typography sx={{ color: green[900] }} variant="h6" gutterBottom>
            {column.title}
          </Typography>
          <Typography variant="body2" mb={4}>
            {column.description}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
};

const Task = ({ item }: TaskProps) => {
  return (
    <TaskWrapper mb={4}>
      <TaskWrapperContent>
        {item.badges &&
          item.badges.map((color: any, i: number) => (
            <TaskBadge color={color} key={i} />
          ))}

        <TaskTitle variant="body1" gutterBottom>
          {item.name}
        </TaskTitle>
        <Typography variant="body2">MRN: {item.mrn}</Typography>
        <Typography variant="body2" gutterBottom>
          DOB: {item.dob}
        </Typography>

        {!!item.notifications && item.notifications > 0 && (
          <TaskNotifications>
            <TaskNotificationsAmount>
              {item.notifications}
            </TaskNotificationsAmount>
            <MessageCircleIcon />
          </TaskNotifications>
        )}
      </TaskWrapperContent>
    </TaskWrapper>
  );
};

function Management() {
  const [columns, setColumns] = useState(mockColumns);
  const [documentReady, setDocumentReady] = useState(false);

  useEffect(() => {
    setDocumentReady(true);
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Discharge Planning
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Management</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Typography variant="h4" gutterBottom>
        Tue - August 15th
      </Typography>

      <Grid container spacing={6}>
        {!!documentReady && (
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            {Object.entries(columns)
              .slice(0, 1)
              .map(([columnId, column]) => (
                <ReviewLane key={columnId} column={column}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            minHeight: 50,
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Task item={item} />
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </ReviewLane>
              ))}
            {Object.entries(columns)
              .slice(1, 2)
              .map(([columnId, column]) => (
                <ApprovedLane key={columnId} column={column}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            minHeight: 50,
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Task item={item} />
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </ApprovedLane>
              ))}
            {Object.entries(columns)
              .slice(2)
              .map(([columnId, column]) => (
                <Lane key={columnId} column={column}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            minHeight: 50,
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Task item={item} />
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </Lane>
              ))}
          </DragDropContext>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default Management;
