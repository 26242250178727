import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { useDispatch } from "react-redux";
import { changeSnapshot } from "../../redux/slices/snapshot";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { TimelineDetails } from "./TimelineDetails";
import { Tooltip } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface MarkerLabelProps {
  color?: string;
  bgColor?: string;
  icon?: React.ReactNode;
  position: number;
  height: string;
}

const MarkerLabel: React.FC<MarkerLabelProps> = ({
  color,
  bgColor,
  icon,
  position,
  height,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    dispatch(changeSnapshot(position));
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(changeSnapshot(position));
  };
  const thisPosition = position - 1;

  const MarkerLabel = styled("button")`
    position: absolute;
    bottom: ${height};
    transform: translateX(-50%);
    font-size: 12px;
    border: 1px solid ${color};
    color: ${color};
    background-color: ${bgColor};
    font-weight: 500;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease-in-out;
    z-index: 2;
  `;

  return (
    <React.Fragment>
      <Tooltip title={TimelineDetails[thisPosition].title} placement="top">
        <MarkerLabel onClick={handleClick}>{icon}</MarkerLabel>
      </Tooltip>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            bgcolor: bgColor,
            color: color,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {icon} {TimelineDetails[thisPosition].title}
        </DialogTitle>
        <DialogContent dividers>
          {TimelineDetails[thisPosition].jsx}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MarkerLabel;
