import { useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import { Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import ReactQuill from "react-quill";
import AiApproval from "./AiApproval";
import "react-quill/dist/quill.snow.css";
import "./aiText.css";

interface AiApprovalProps {
  approved: boolean;
  approvedBy: string;
  content: JSX.Element;
}

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 50px;
    position: relative;
  }
`;

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ list: "ordered" }, { list: "bullet" }],

  [{ header: [1, 2, 3, 4, 5, 6, false] }],
];

const AiApprovalBox = ({ approved, approvedBy, content }: AiApprovalProps) => {
  function Quill({ approved }: any) {
    const quillContent = renderToStaticMarkup(content);
    const [value, setValue] = useState(quillContent);
    const [thisApproved, setThisApproved] = useState(approved);

    function handleClick() {
      setThisApproved(true);
    }
    if (!thisApproved) {
      return (
        <div>
          <QuillWrapper>
            <ReactQuill
              modules={{
                toolbar: toolbarOptions,
              }}
              theme="snow"
              value={value}
              onChange={setValue}
              placeholder="Type something.."
            />
          </QuillWrapper>
          <Typography sx={{ pt: 2 }} align="right">
            <Button onClick={handleClick} variant="contained" color="secondary">
              Approve
            </Button>
          </Typography>
        </div>
      );
    } else {
      return (
        <AiApproval
          approved={approved}
          approvedBy={approvedBy}
          content={content}
        />
      );
    }
  }
  return <Quill approved={approved} />;
};

export default AiApprovalBox;
