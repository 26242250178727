import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import AiApprovalBox from "../aiBox/AiApprovalBox";
import { patientStories } from "../../assets/fakeData/patientStories";

import "../../assets/custom-quill.css";

const PatientStorySummary = () => {
  const dayNum = useSelector((state: any) => state.day.value);

  const thisPatient = patientStories[0].story;
  const thisDay = thisPatient.days[dayNum];

  return (
    <Box sx={{ mb: 4, px: 2 }}>
      <Typography variant="h4" sx={{ pl: 2, mt: 4, mb: 1 }}>
        Summary
      </Typography>

      <AiApprovalBox
        approved={thisDay.daySummary.approved}
        approvedBy={thisDay.daySummary.approvedBy}
        content={thisDay.daySummary.content}
      />
    </Box>
  );
};

export default PatientStorySummary;
