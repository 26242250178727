import React from "react";
import styled from "@emotion/styled";
import { indigo } from "@mui/material/colors";
import { ReactComponent as Logo } from "../../vendor/cross-blue.svg";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 38px;
  height: 38px;
`;

const BrandContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.typography.h1.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightLight};
  letter-spacing: -2.5px;
  color: ${indigo[900]};
  margin-bottom: 32px;
  gap: ${(props) => props.theme.spacing(1)};
`;

function ThinLayerBrand() {
  return (
    <BrandContainer>
      <Brand />
      <span>ThinLayer</span>
    </BrandContainer>
  );
}

export default ThinLayerBrand;
