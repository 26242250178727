import React from "react";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import Vitruvian from "../../components/vitruvian/Vitruvian";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CardContent,
  Chip,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Button,
  Stack,
  Typography,
} from "@mui/material";

import { ExpandMore } from "@mui/icons-material";

import PatientCharts from "../../components/patientChart/PatientCharts";

const demoPatient = {
  id: "1",
  firstName: "Susan",
  lastName: "Calvin",
  mrn: "1434534",
  age: "71",
  hospitalDuration: "8",
  location: "SICU",
  dob: "1953-03-24",
  height: "152",
  weight: "72",
  conditions: [
    "Congestive Heart Failure",
    "COPD",
    "Gastroesophageal Reflux Disease",
    "Hypothyroidism",
    "Lung Cancer",
    "Pneumonia",
    "Pulmonary Hypertension",
    "Respiratory Failure",
    "Tracheomalacia",
    "UTI",
  ],
  allergies: ["Penicillin", "Codeine"],
  admission: "2023-03-24",
  discharge: "2023-03-31",
  destination: "Home",
};

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function GPPage() {
  return (
    <React.Fragment>
      <Helmet title="GP View" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="h3" display="inline">
            {demoPatient.firstName} {demoPatient.lastName} - Discharge Summary
          </Typography>
        </Box>
        <Button variant="contained" color="primary">
          Export
        </Button>
      </Box>
      <Divider my={6} />
      {/* Container */}
      <Grid
        container
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={6}
      >
        {/* Patient Details */}
        <Grid item xs={12} sm={6} md={5} lg={4} xl={4} xxl={4}>
          <Card>
            <CardContent>
              <Typography variant="h4" mb={6} mt={1}>
                Patient Details
              </Typography>
              <Grid container spacing={6}>
                <Grid item xs={5}>
                  <Vitruvian width="100%" />
                </Grid>
                <Grid item xs={7}>
                  <Stack spacing={1}>
                    <Typography variant="body1" gutterBottom>
                      <strong>MRN:</strong> {demoPatient.mrn}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>Age:</strong> {demoPatient.age}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>Location:</strong> {demoPatient.location}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>DOB:</strong>{" "}
                      {new Date(demoPatient.dob).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>Height:</strong> {demoPatient.height}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>Weight:</strong> {demoPatient.weight}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>Admission Date:</strong>{" "}
                      {new Date(demoPatient.admission).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>Discharge Date:</strong>{" "}
                      {new Date(demoPatient.discharge).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body1" gutterBottom pb={4}>
                      <strong>Destination:</strong> {demoPatient.destination}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>Address:</strong>
                      <br />
                      23 Kenilworth Rd,
                      <br />
                      Crystal Palace,
                      <br />
                      London SE20 7QG
                    </Typography>
                    <Typography variant="body1" gutterBottom pb={4}>
                      <strong>Phone:</strong> 020 8659 0000
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>Emergency Contact:</strong>
                      <br />
                      George Calvin
                      <br />
                      23 Kenilworth Rd,
                      <br />
                      Crystal Palace,
                      <br />
                      London SE20 7QG
                      <br />
                      020 8659 0000
                    </Typography>

                    {/* <Typography variant="body1" gutterBottom>
                      <strong>Conditions:</strong>
                      <br />
                      {demoPatient.conditions.map((condition) => (
                        <Chip
                          key={condition}
                          label={condition}
                          color="primary"
                          size="small"
                        />
                      ))}
                    </Typography> */}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ mb: 3 }}>
                    Conditions
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    mb={2}
                    useFlexGap
                    flexWrap="wrap"
                  >
                    {demoPatient.conditions.map((condition: string) => (
                      <Chip
                        color="primary"
                        size="small"
                        label={condition}
                        key={condition}
                      />
                    ))}
                  </Stack>
                  <Typography variant="h4" sx={{ mt: 4, mb: 3 }}>
                    Allergies
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={2}
                    mb={2}
                    useFlexGap
                    flexWrap="wrap"
                  >
                    {demoPatient.allergies.map((allergy: string) => (
                      <Chip
                        color="info"
                        size="small"
                        label={allergy}
                        key={allergy}
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Discharge Report */}
        <Grid
          item
          spacing={6}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          xs={12}
          sm={6}
          md={7}
          lg={8}
          xl={8}
          xxl={8}
        >
          <Card>
            <CardContent>
              <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={6}
              >
                {/* Patient Chart */}
                <Grid item xs={12}>
                  <PatientCharts />
                </Grid>
                {/* Discharge Instructions */}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={5}>
                  <Typography variant="h3" mb={4} mt={1}>
                    Discharge Instructions & Follow-up
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <ol>
                      <li>
                        Daily weights every morning, and call GP if weight gain
                        is &gt; 3 lbs from dischage weight of XXXlbs
                      </li>
                      <li>Adhere to 2 gm sodium/day diet</li>
                      <li>
                        Complete course of ampicillin as per d/c summary; 8 more
                        days
                      </li>
                      <li>
                        {" "}
                        Draw surveillance blood cultures 1 week after ampicillin
                        course complete
                      </li>
                      <li>
                        Check blood pressures on thigh and wean down midodrine
                        as tolerated
                      </li>
                      <li>
                        Follow up pathology from bronchoscopy of RML
                        endobronchial lesion: call xxx-yyy-zzzz on Friday
                      </li>
                      <li>
                        Ventilator settings: SIMV + PS 10/5. Tidal Vital 450, RR
                        14, PEEP 5, FiO2 50%
                      </li>
                      <li>
                        Seek immediate medical attention for T &gt; 100.4, any
                        change in quality of secretions, any change in urine
                        consistency, hypoxia, chest pain, dyspnea or other
                        concerning symptoms.
                      </li>
                      <li> Take all meds as directed</li>
                      <li> Followup with GP in 1-3 weeks</li>
                    </ol>
                  </Typography>
                </Grid>
                {/* Medications */}
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={5}>
                  <Typography variant="h3" mb={4} mt={1}>
                    Medications on Discharge
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <ul>
                      <li>
                        Fluticasone 220mcg MDI twice daily -&gt; Continue (for
                        COPD, breathing)
                      </li>
                      <li>
                        Combivent inh 4puffs q6h -&gt; Continue (for COPD,
                        breathing)
                      </li>
                      <li>
                        Atorvastatin 10mg daily -&gt; Continue (for
                        hyperlipidemia)
                      </li>
                      <li>
                        Spironoalactone 25mg -&gt; Continue (for fluid
                        retention)
                      </li>
                      <li>
                        asix 40mg po q12hr -&gt; Continue (for CHF and water
                        retention)
                      </li>
                      <li>Aranesp 0.06mg qFri -&gt; Continue (for anemia)</li>
                      <li>
                        Esomeprazole 40mg daily -&gt; Continue (for
                        gastroesophageal reflux disease)
                      </li>
                      <li>
                        Iron Sulfate 300mg daily -&gt; Continue (for
                        iron-deficiency anemia)
                      </li>
                      <li>
                        Seroquel 50 mg qhs -&gt; Continue (for depression)
                      </li>
                      <li>
                        Seroquel 25 mg po tid -&gt; Continue (for depression)
                      </li>
                      <li>
                        NPH 6u sc qAM -&gt; Continue (for Type II Diabetes
                        Mellitus)
                      </li>
                      <li>
                        Midodrine 10mg po q8h -&gt; Wean off of medication as
                        tolerated (for orthostatic hypotension)
                      </li>
                      <li>
                        Paroxetine 20mg daily -&gt; Continue (for depression and
                        anxiety)
                      </li>
                      <li>
                        Trazadone 50mg prn insomnia -&gt; Continue as needed
                        (for sleeping)
                      </li>
                      <li>
                        Levothyroxine 50 mcg qd -&gt; Continue (for
                        hypothyroidism)
                      </li>
                    </ul>
                    <br />
                    <h3>DISCONTINUE: Zofran 4 mg IV q6 (Course completed)</h3>
                  </Typography>
                </Grid>

                {/* Discharge Report */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} xxl={10}>
                  <Typography variant="h3" mb={4}>
                    Discharge Report
                  </Typography>
                  {/* HPI */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        backgroundColor: "#F7F9FC",
                        border: "1px solid #E3E6F0",
                      }}
                    >
                      <Typography variant="h5" pl={2}>
                        History of present illness (HPI)
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <p>
                          70 y/o obese Caucasian female with a history of
                          severe, oxygen-dependent chronic obstructive pulmonary
                          disease (COPD) presenting with blood in her tracheal
                          tube and mild shortness of breath. Her medical history
                          includes non-small cell lung cancer (NSCLC), status
                          post Left Upper Lobe lobectomy (2021-5-15) and
                          radiation therapy for same, who has been
                          ventilator-dependent since her tracheostomy
                          (2021-6-1). Since then she has had a Y-stent placed
                          (2022-1-15) with subsequent removal (2023-2-10) as a
                          result of tracheal ulceration with bleeding and
                          tracheal tube blockage. Bronchoscopy during this
                          recent hospital admission showed patent airways and a
                          possible Pseudomonas pneumonia for which she was
                          discharged on Zosyn. She presents to the Emergency
                          Department (ED) today from a respiratory
                          rehabilitation facility where she was found
                          diaphoretic. Her trach tube was suction, resulting in
                          removal of a mucus plug followed by persistent blood
                          in the trach tube.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  {/* Arrival History */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                      sx={{
                        backgroundColor: "#F7F9FC",
                        border: "1px solid #E3E6F0",
                      }}
                    >
                      <Typography variant="h5" pl={2}>
                        Arrival History and Physical Examination
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <p>
                          In the ED, she was mildly short of breath, but no
                          blood was noted in or around the trach tube. Patient
                          was febrile (101F), HR in the 90s, BP 120/50 with vent
                          set at respiratory rate (RR) 14, tidal volume (TV)
                          450, positive end expiratory pressure (PEEP) 5 psi.
                          FiO2 50%. She was given Combivent bronchodilator with
                          improvement in her respiratory comfort. Her labs
                          showed an elevated white blood cell count (11.6) with
                          a left shift (81% neutrophils) and a lactate of 1.0.
                          Given her pulmonary history, diffuse infiltates on
                          chest xray and lab findings, she was started on
                          Vancomycin and Azithromycin in the ER and transferred
                          to the ICU.
                        </p>
                        <h3>REVIEW OF SYSTEMS (ROS)</h3>
                        <p>
                          Negative for fever, chills, cough, night sweats,
                          abdominal pain, chest pain, hemetemesis or weight
                          loss.
                        </p>
                        <h3>PAST MEDICAL AND SURGICAL HISTORY</h3>
                        <p>
                          <ol>
                            <li>
                              O2-dependent COPD s/p tracheostomy and multiple
                              complications (blockage, ulcerations)
                            </li>
                            <li>
                              {" "}
                              CHF with Ejection Fraction (EF) &gt; 50%, severe
                              diastolic dysfunction
                            </li>
                            <li>
                              NSCLC s/p LUL lobectomy s/p XRT, chemo (Iressa)
                              and pleurodesis - 2021
                            </li>
                            <li>
                              Type II Diabetes Mellitus (T2DM) well controlled
                              on insulin
                            </li>
                            <li>
                              {" "}
                              Iron-deficiency anemia and thrombocytopenia of
                              chronic disease
                            </li>
                            <li>
                              History of pulmonary fungal infection and fungemia
                            </li>
                            <li>s/p L Total Hip Replacement (THR) - 2019</li>
                            <li>s/p Total Abdominal Hysterectomy - 1989</li>
                            <li>
                              Paroxysmal Atrial Fibrillation (PAF), not on
                              anticoagulant
                            </li>
                            <li>History of serratia sepsis</li>
                            <li>
                              History of pseudomonas and acinetobacter pneumonia
                            </li>
                            <li>Hypothyroidism</li>
                            <li>Gastroesophageal reflux (GERD)</li>
                            <li>Hyperlipidemia</li>
                            <li>Insomnia</li>
                            <li>Depression</li>
                          </ol>
                        </p>
                        <br />
                        <h3>SOCIAL HISTORY</h3>
                        <p>
                          Patient in long term respiratory rehabilitation
                          facility with limited mobility. 60 pack-years smoking
                          history, but does not consume EtOH. Has never used
                          illicit drugs. Has a very involved, supportive family
                          who visit her often.
                        </p>
                        <br />
                        <h3>FAMILY HISTORY</h3>
                        <p>Unremarkable</p>
                        <h3>PHYSICAL EXAM ON ADMISSION</h3>
                        <ul>
                          <li>
                            VS: T 99; HR 90 in normal sinus rhythm; BP 122/46;
                            RR 16 on vent; Sat 100%
                          </li>
                          <li>
                            Vent: SIMV 500x14 PS 12 PEEP 5 (Tidal volume 500,
                            rate 14, PEEP 5)
                          </li>
                          <li>
                            General: Pleasant, obese woman with trach and
                            port-a-cath
                          </li>
                          <li>
                            HEENT: Oropharynx clear; moist mucus membranes;
                            pupils equal, round and reactive to light;, sclerae
                            anicteric
                          </li>
                          <li>CV: normal S1 and S2; regular rate and rhthym</li>
                          <li>PUL: Diffuse rhonchi</li>
                          <li>
                            ABD: Obese, soft, non-tender, non-distended; normal
                            bowel sounds present
                          </li>
                          <li>
                            XT: 2+ lower extremity oedema to thighs; 2+ L upper
                            extremity oedema; 1+ R upper extremity oedema; PICC
                            in the R antecubital vein
                          </li>
                          <li>NEURO: Awake, alert.</li>
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  {/* Arrival Diagnostic Laboratories and Imaging */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                      sx={{
                        backgroundColor: "#F7F9FC",
                        border: "1px solid #E3E6F0",
                      }}
                    >
                      <Typography variant="h5" pl={2}>
                        Arrival Diagnostic Laboratories and Imaging
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <p>
                          <h3>LABS (ON ADMISSION</h3>
                          <ul>
                            <li>
                              BLOOD: WBC-11.6* Hgb-9.5* Hct-28.6* Plt Ct-217#
                            </li>
                            <li>
                              BLOOD DIFFERENTIAL: Neuts-81* Bands-5 Lymphs-4*
                              Monos-6 Eos-2 Baso-1 Atyps-0 Metas-1*
                            </li>
                            <li>
                              CHEMISTRIES: Glucose-165* UreaN-30* Creat-1.1
                              Na-135 K-4.3 Cl-94* HCO3-29 AnGap-16
                            </li>
                            <li>
                              BLOOD CK(CPK)-32; cTropnT-0.36*; proBNP-8849*
                            </li>
                            <li>BLOOD Calcium-8.7; Phos-3.1; Mg-1.9</li>
                          </ul>
                          <br />
                          <h3>IMAGING</h3>
                          <p>
                            <strong>ECHO:</strong> Mild L atrial dilation; RV
                            size/motion within normal limits; aortic valve
                            leaflets mildly thickened without stenosis w/o
                            vegetations; trace aortic regurgitation; mitral
                            valve leaflets mildly thickened w/o vegetations; 1+
                            mitral regurgitation; moderate pulmonary artery
                            systolic hypertension; pulmonic valve within normal
                            limits; no pericardial effusion or suggestion of
                            endocarditis.
                          </p>
                          <br />
                          <p>
                            <strong>BRONCHOSCOPY (x2):</strong> no obstructions,
                            although friable, bleeding epithelia was noted;
                            second bronchoscopy prior to discharge identified a
                            Right Middle Lobe endobronchial lesion, biopsy
                            pending at time of discharge.
                          </p>
                          <br />
                          <h3>MICROBIOLOGY</h3>
                          <ul>
                            <li>
                              Blood Culture x4: + Enterococcus faecalis,
                              sensitive to Ampicillin, Vancomycin & Penicillin
                            </li>
                            <li>
                              Blood Culture (PICC Line): + Pseudomonas
                              aeroginosa, pan-sensitive
                            </li>
                            <li>
                              Urine Culture: + Proteus mirabilis, resistant to
                              Ampicillin, Amoxacillin, Gentamycin & Bactrim;
                              sensitive to Ciprofloxacin & Cefalosporins
                              (chronic Foley catheter use)
                            </li>
                            <li>
                              Brochoalveolar lavage (BAL): + Pseudomonas
                              aeroginosa, pan-sensitive
                            </li>
                          </ul>
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  {/* Brief Hospital Course */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel4-content"
                      id="panel4-header"
                      sx={{
                        backgroundColor: "#F7F9FC",
                        border: "1px solid #E3E6F0",
                      }}
                    >
                      <Typography variant="h5" pl={2}>
                        Brief Hospital Course
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <p>
                          70 y/o woman with COPD, CHF, tracheomalacia and
                          respiratory failure presenting from rehab with concern
                          of blood needing to be frequently suctioned from
                          trach, associated with low grade fever and possible
                          hypotension.
                        </p>
                        <h3>1. RESPIRATORY COMPROMISE</h3>
                        <p>
                          Concern for mechanical problem with tube or airway
                          obstructing lesion; bronchoscopy done showing no
                          obstruction and friable, bleeding epithelia. Treated
                          for presumptive pneumonia (initially c Zosyn and then
                          Meropenem on ID service recs to account for
                          development of possible pneumonia). 3/30 is the last
                          day of Meropenem; she does not need to continue this
                          on return to rehab. Also briefly treated with
                          tobramycin nebs and fluconazole but stopped after
                          bronchoalveolar lavage (BAL) culture grew out
                          pansensitive Pseudomonas. Had repeat bronchoscopy
                          prior to discharge and noted to have RML endobronchial
                          lesion. Thought granulation tissue but pathology sent
                          and this needs to be followed up in the next several
                          days for possible recurrence of malignancy.
                        </p>
                        <h3>2. BACTERMIA WITH INDWELLING PICC LINE</h3>
                        <p>
                          Blood cultures were positive for ampicillin-sensitive
                          enterococcus; case discussed with surgery and ID,
                          particularly with regard to potential removal of the
                          patient's PICC line. Decision was made to treat the
                          infection through the line with a 2 week course of
                          ampicillin. At the time of discharge, she needs to
                          finish 8 more days of ampicillin (through 4/8/2023)
                          and then have surveillance cultures drawn 1 week
                          thereafter. ECHO was performed and was negative for
                          endocarditis. Suspect that the PICC PORT is the source
                          of the infection, and ampicillin should continue to be
                          given via PICC. There is no need for removal unless
                          she has recurrent + blood cultures.
                        </p>
                        <h3>3. INTERMITTENT HYPOTENSION</h3>
                        <p>
                          The patient has a history of intermittent hypotension
                          of unclear etiology. She was treated briefly with a
                          norepinephrine drip, and then it was established that
                          blood pressures when measured on the leg are within
                          normal limits. Levophed was thus weaned off and her
                          midodrine dose was also decreased with maintenance of
                          normal blood pressures. Midodrine should continue to
                          be weaned off as tolerated, while having her BPs
                          measured on the leg.{" "}
                        </p>
                        <h3>
                          4. URINARY TRACT INFECTION WITH INDWELLING FOLEY
                          CATHETER
                        </h3>
                        <p>
                          Found to have resistant Proteus in urine; treated with
                          a course of meropenem for this and finished a course
                          on 3/30/2023. Her urine also grew out yeast and her
                          Foley catheter was therefore replaced.
                        </p>
                        <h3>5. NUMEROUS OTHER CHRONIC DISEASES</h3>
                        <p>
                          Patient is ventilator-dependent and bedridden, and
                          predisposed to further lung, bladder and line
                          infections, as well as stasis ulcers.
                        </p>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  {/* Medications on Discharge */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel5-content"
                      id="panel5-header"
                      sx={{
                        backgroundColor: "#F7F9FC",
                        border: "1px solid #E3E6F0",
                      }}
                    >
                      <Typography variant="h5" pl={2}>
                        Medications on Discharge
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <ul>
                          <li>
                            Fluticasone 220mcg MDI twice daily -&gt; Continue
                            (for COPD, breathing)
                          </li>
                          <li>
                            Combivent inh 4puffs q6h -&gt; Continue (for COPD,
                            breathing)
                          </li>
                          <li>
                            Atorvastatin 10mg daily -&gt; Continue (for
                            hyperlipidemia)
                          </li>
                          <li>
                            Spironoalactone 25mg -&gt; Continue (for fluid
                            retention)
                          </li>
                          <li>
                            asix 40mg po q12hr -&gt; Continue (for CHF and water
                            retention)
                          </li>
                          <li>
                            Aranesp 0.06mg qFri -&gt; Continue (for anemia)
                          </li>
                          <li>
                            Esomeprazole 40mg daily -&gt; Continue (for
                            gastroesophageal reflux disease)
                          </li>
                          <li>
                            Iron Sulfate 300mg daily -&gt; Continue (for
                            iron-deficiency anemia)
                          </li>
                          <li>
                            Seroquel 50 mg qhs -&gt; Continue (for depression)
                          </li>
                          <li>
                            Seroquel 25 mg po tid -&gt; Continue (for
                            depression)
                          </li>
                          <li>
                            NPH 6u sc qAM -&gt; Continue (for Type II Diabetes
                            Mellitus)
                          </li>
                          <li>
                            Midodrine 10mg po q8h -&gt; Wean off of medication
                            as tolerated (for orthostatic hypotension)
                          </li>
                          <li>
                            Paroxetine 20mg daily -&gt; Continue (for depression
                            and anxiety)
                          </li>
                          <li>
                            Trazadone 50mg prn insomnia -&gt; Continue as needed
                            (for sleeping)
                          </li>
                          <li>
                            Levothyroxine 50 mcg qd -&gt; Continue (for
                            hypothyroidism)
                          </li>
                        </ul>
                        <br />
                        <h3>
                          DISCONTINUE: Zofran 4 mg IV q6 (Course completed)
                        </h3>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  {/* Discharge Status */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel6-content"
                      id="panel6-header"
                      sx={{
                        backgroundColor: "#F7F9FC",
                        border: "1px solid #E3E6F0",
                      }}
                    >
                      <Typography variant="h5" pl={2}>
                        Discharge Status
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <ul>
                          <li>Discharge Disposition: Extended Care</li>
                          <li>
                            Facility: London Respiratory Rehabilitation Hospital
                          </li>
                          <li>Discharge Condition: Fair</li>
                        </ul>
                        <br />
                        <h3>Primary Discharge Diagnoses:</h3>
                        <ol>
                          <li>
                            Enterococcus bacteremia (PICC line port source, but
                            treated in place; follow-on cultures req'd)
                          </li>
                          <li>Ventilator-associated pneumonia</li>
                          <li>
                            Proteus urinary tract infection with chronic Foley
                            catheter use
                          </li>
                          <li>Tracheomalacia with intermittent bleeding</li>
                          <li>
                            Right middle lobe (RML) endobronchial lesion:
                            granulation vs. malignancy? (biopsy pending)
                          </li>
                        </ol>
                        <h3>Secondary Diagnoses:</h3>
                        <ol>
                          <li>
                            O2-dependent COPD s/p tracheostomy and multiple
                            complications (blockage, ulcerations)
                          </li>
                          <li>
                            CHF with Ejection Fraction (EF) &gt; 50%, severe
                            diastolic dysfunction
                          </li>
                          <li>
                            NSCLC s/p LUL lobectomy s/p XRT, chemo (Iressa) and
                            pleurodesis - 2021
                          </li>
                          <li>
                            Type II Diabetes Mellitus (T2DM) well controlled on
                            insulin
                          </li>
                          <li>
                            Iron-deficiency anemia and thrombocytopenia of
                            chronic disease
                          </li>
                          <li>
                            History of pulmonary fungal infection and fungemia
                          </li>
                          <li>s/p L Total Hip Replacement (THR) - 2019</li>
                          <li>s/p Total Abdominal Hysterectomy - 1989</li>
                          <li>
                            Paroxysmal Atrial Fibrillation (PAF), not on
                            anticoagulant
                          </li>
                          <li>History of serratia sepsis</li>
                          <li>
                            History of pseudomonas and acinetobacter pneumonia
                          </li>
                          <li>Hypothyroidism</li>
                          <li>Gastroesophageal reflux (GERD)</li>
                          <li>Hyperlipidemia</li>
                          <li>Insomnia</li>
                          <li>Depression</li>
                        </ol>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  {/* Discharge Instructions and Follow Up */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel7-content"
                      id="panel7-header"
                      sx={{
                        backgroundColor: "#F7F9FC",
                        border: "1px solid #E3E6F0",
                      }}
                    >
                      <Typography variant="h5" pl={2}>
                        Discharge Instructions and Follow Up
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <ol>
                          <li>
                            Daily weights every morning, and call GP if weight
                            gain is &gt; 3 lbs from dischage weight of XXXlbs
                          </li>
                          <li>Adhere to 2 gm sodium/day diet</li>
                          <li>
                            Complete course of ampicillin as per d/c summary; 8
                            more days
                          </li>
                          <li>
                            {" "}
                            Draw surveillance blood cultures 1 week after
                            ampicillin course complete
                          </li>
                          <li>
                            Check blood pressures on thigh and wean down
                            midodrine as tolerated
                          </li>
                          <li>
                            Follow up pathology from bronchoscopy of RML
                            endobronchial lesion: call xxx-yyy-zzzz on Friday
                          </li>
                          <li>
                            Ventilator settings: SIMV + PS 10/5. Tidal Vital
                            450, RR 14, PEEP 5, FiO2 50%
                          </li>
                          <li>
                            Seek immediate medical attention for T &gt; 100.4,
                            any change in quality of secretions, any change in
                            urine consistency, hypoxia, chest pain, dyspnea or
                            other concerning symptoms.
                          </li>
                          <li> Take all meds as directed</li>
                          <li> Followup with GP in 1-3 weeks</li>
                        </ol>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default GPPage;
