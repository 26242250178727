import React from "react";
// import { useTheme } from "@mui/material/styles";
// import { useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { changeDay } from "../../../redux/slices/day";
import PatientStoryCharts from "../../patientChart/PatientStoryCharts";
import TimelineMarkerSlot from "../apiTestFiles/TimelineMarkerSlot";

const maxSlotWidth = 58;

const View = styled(Box)`
  width: 100%;
  overflow-x: auto;
`;

const Timeline = ({ reports }: any) => {
  const numberDays = reports.length;
  const slotWidth = 100 / reports.length;
  const dispatch = useDispatch();
  const dayValue = useSelector((state: any) => state.day.value);

  const handleClick = (newValue: number) => {
    dispatch(changeDay(newValue));
  };

  const ChartBox = styled(Box)`
    grid-column: 1 / span ${numberDays};
  `;
  const ControlBox = styled(Box)(({ theme }) => ({
    display: "grid",
    margin: "8px 32px",
    gridTemplateColumns: `repeat(${numberDays}, max(${slotWidth}%, ${maxSlotWidth}px))`,
    [theme.breakpoints.down("sm")]: {
      margin: "8px 16px",
    },
  }));

  const ReportsBox = styled(Box)(({ theme }) => ({
    display: "grid",
    margin: "-8px 32px 0 32px",
    gridTemplateColumns: `repeat(${numberDays}, max(${slotWidth}%, ${maxSlotWidth}px))`,
    [theme.breakpoints.down("sm")]: {
      margin: "-8px 16px 0 16px",
    },
  }));

  const DaySelector = (props: any) => {
    const thisVariant = props.value === dayValue ? "contained" : "text";

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Button
          variant={thisVariant}
          color="primary"
          sx={{ px: 0, width: "100%" }}
          onClick={() => handleClick(props.value)}
        >
          {props.label}
        </Button>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <View>
        <ControlBox>
          <ChartBox>
            <PatientStoryCharts />
          </ChartBox>
          {reports.map((report: any) => {
            return (
              <DaySelector
                label={"DAY " + report.hospitalDay}
                value={report.hospitalDay - 1}
                key={report.hospitalDay}
              />
            );
          })}
        </ControlBox>
        <ReportsBox>
          {reports.map((report: any) => {
            return (
              <TimelineMarkerSlot
                reports={report.reports}
                value={report.hospitalDay - 1}
                key={report.hospitalDay}
              />
            );
          })}
        </ReportsBox>
      </View>
    </React.Fragment>
  );
};

export default Timeline;
