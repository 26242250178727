import React from "react";
import HysterectomyWarningPng from "./assets/hysterectomy.png";
import styled from "@emotion/styled";
import { VitruvianAnimationForMedium } from "./assets/VitruvianAnimationForMedium";
import { Popover, Typography } from "@mui/material";

const HysterectomyStyle = styled("img")`
  top: 44.2%;
  left: 44%;
  width: 13.5%;
  animation-delay: 1200ms;
  ${VitruvianAnimationForMedium}
`;

function HysterectomyLayer() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <HysterectomyStyle
        src={HysterectomyWarningPng}
        aria-owns={open ? "Hysterectomy-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="Hysterectomy-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>Total abdominal hysterectomy.</Typography>
      </Popover>
    </React.Fragment>
  );
}

export default HysterectomyLayer;
