import React from "react";
import { Helmet } from "react-helmet-async";
import AccreditationImage from "../../assets/images/Accreditation.png";

function Accreditation() {
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <img
        src={AccreditationImage}
        alt="Accreditation"
        style={{ width: "100%" }}
      />
    </React.Fragment>
  );
}

export default Accreditation;
