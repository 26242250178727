import { Typography, Stack, Box, Chip } from "@mui/material";
import { useSelector } from "react-redux";

// import PatientScoresApiTest from "./PatientScoresApiTest";
import PatientScores from "../PatientScores";

function PatientBasicDetailsApiTest({ patient }: { patient: any }) {
  const showScoring = useSelector((state: any) => state.showScoring.value);
  let allergyContent = null;
  if (patient.allergies && patient.allergies.length > 0) {
    allergyContent = (
      <div>
        <Stack direction="row" spacing={2} mb={2} useFlexGap flexWrap="wrap">
          {patient.allergies.map((allergy: any) => (
            <Chip color="info" size="small" label={allergy} key={allergy} />
          ))}
        </Stack>
      </div>
    );
  } else {
    allergyContent = <Chip color="info" size="small" label="None" />;
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="h4" sx={{ mt: 4, mb: 3 }}>
        {patient.firstName} {patient.lastName}
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>MRN:</strong> {patient.mrn}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Age:</strong> {patient.age}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Hospital Duration:</strong> Day {patient.hospitalDuration}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Location:</strong> {patient.location}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>DOB:</strong> {new Date(patient.dob).toLocaleDateString()}
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Height:</strong> {patient.height}cm
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Weight:</strong> {patient.weight}kg
      </Typography>
      <Typography variant="h4" sx={{ mt: 4, mb: 3 }}>
        Conditions
      </Typography>
      <Stack direction="row" spacing={2} mb={2} useFlexGap flexWrap="wrap">
        {patient.conditions.map((condition: string) => (
          <Chip
            color="primary"
            size="small"
            label={condition}
            key={condition}
          />
        ))}
      </Stack>
      {showScoring && <PatientScores />}

      {/* {showScoring && <PatientScoresApiTest patientId={patient.id} />} */}
      <Typography variant="h4" sx={{ mt: 4, mb: 3 }}>
        Allergies
      </Typography>
      {allergyContent}
    </Box>
  );
}

export default PatientBasicDetailsApiTest;
