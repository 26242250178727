import styled from "@emotion/styled";
import MarkerLabel from "./TimelineMarkerLabel";

interface TimelineMarkerProps {
  placementX: string;
  color?: string;
  bgColor?: string;
  icon?: React.ReactNode;
  position: number;
  height: string;
}

const TimelineMarker: React.FC<TimelineMarkerProps> = ({
  placementX,
  color,
  bgColor,
  icon,
  position,
  height,
}) => {
  const Placement = styled("div")`
    position: absolute;
    left: ${placementX};
    bottom: 11px;
  `;
  const MarkerDot = styled("div")`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${color};
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 1;
  `;
  const MarkerLine = styled("div")`
    width: 0px;
    height: ${height};
    border-right: 1px solid ${color};
    position: absolute;
    bottom: 5px;
    transform: translateX(-50%);
  `;

  return (
    <Placement>
      <MarkerLine />
      <MarkerDot />
      <MarkerLabel
        color={color}
        bgColor={bgColor}
        icon={icon}
        position={position}
        height={height}
      />
    </Placement>
  );
};

export default TimelineMarker;
