import { Task } from "./types/public-types";

export function initTasks() {
  const daySeconds = 24 * 60 * 60 * 1000;
  const admissionDate = new Date(2023, 2, 23, 18, 0, 0, 0);
  const tasks: Task[] = [
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      name: "Hospital Prescriptions",
      id: "HospitalRx",
      progress: 100,
      type: "project",
      hideChildren: false,
      displayOrder: 1,
    },
    {
      start: new Date(2023, 2, 23, 21, 0, 0, 0),
      end: new Date(2023, 2, 30, 6, 0, 0, 0),
      name: "Zosyn: 3.375gm IV every 6 hours",
      id: "Zosyn",
      grouping: "Antibiotic",
      indication: "Pneumonia, Enterococcus",
      discontinuation: "7 day course complete",
      progress: 100,
      type: "task",
      project: "HospitalRx",
      displayOrder: 2,
    },
    {
      start: new Date(2023, 2, 24, 15, 0, 0, 0),
      end: new Date(2023, 2, 29, 7, 0, 0, 0),
      name: "Meropenem: 500mg IV every 8 hours",
      id: "Meropenem",
      grouping: "Antibiotic",
      indication: "Additional coverage, presumption of UTI & line infection",
      discontinuation: "5 day course complete",
      progress: 100,
      type: "task",
      project: "HospitalRx",
      displayOrder: 3,
    },
    {
      start: new Date(2023, 2, 24, 7, 0, 0, 0),
      end: new Date(2023, 2, 26, 7, 0, 0, 0),
      name: "Tobramycin: 300mg nebulizer twice a day",
      id: "Tobramycin",
      grouping: "Antibiotic",
      indication: "Pseudomonas aeruginosa pneumonia",
      discontinuation:
        "Reduced to IV antibiotic coverage after acute period of respiratory distress",
      progress: 100,
      type: "task",
      project: "HospitalRx",
      displayOrder: 4,
    },
    {
      start: new Date(2023, 2, 24, 7, 0, 0, 0),
      end: new Date(2023, 2, 26, 7, 0, 0, 0),
      name: "Fluconazole: 300mg nebulizer twice a day",
      id: "Fluconazole",
      grouping: "Antibiotic",
      indication: "Respiratory distress",
      discontinuation:
        "Reduction in respiratory distress, reduction in ventilator settings",
      progress: 100,
      type: "task",
      project: "HospitalRx",
      displayOrder: 5,
    },
    {
      start: new Date(2023, 2, 24, 15, 0, 0, 0),
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      name: "Ampicillin: 1gm IV every 4 hours",
      id: "Ampicillin",
      grouping: "Antibiotic",
      indication: "Presumed PIC line infection",
      discontinuation: "7 day course complete",
      progress: 100,
      type: "task",
      project: "HospitalRx",
      displayOrder: 6,
    },
    {
      start: new Date(2023, 2, 23, 21, 0, 0, 0),
      end: new Date(2023, 2, 25, 18, 0, 0, 0),
      name: "Levophed: 8mcg/min IV drip",
      id: "Levophed",
      grouping: "Vasoconstrictor",
      indication: "Hypotension",
      discontinuation: "Hypotension resolved",
      progress: 100,
      type: "task",
      project: "HospitalRx",
      displayOrder: 7,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      name: "Pre-Admission Prescriptions",
      grouping: "Prescriptions",
      id: "PreRx",
      progress: 100,
      type: "project",
      hideChildren: false,
      displayOrder: 8,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      name: "Fluticasone: 220mcg MDI twice daily",
      id: "Fluticasone",
      grouping: "Corticosteroid",
      indication: "COPD",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 9,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Combivent inhaler",
      grouping: "Bronchodilator",
      name: "Combivent inhaler: 4 puffs every 6 hours",
      indication: "COPD",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 10,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Atorvastatin",
      grouping: "Statin",
      name: "Atorvastatin: 10mg po daily",
      indication: "Hyperlipidemia",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 11,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Spironolactone",
      grouping: "Antihypertensive",
      name: "Spironolactone: 25mg po daily",
      indication: "Hypertension, Congestive Heart Failure, Fluid retention",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 12,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Lasix",
      grouping: "Diuretic",
      name: "Lasix: 40mg po every 12 hours",
      indication: "Congestive Heart Failure (CHF)",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 13,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Aranesp",
      grouping: "Erythropoesis-stimulating agent",
      name: "Aranesp: 0.06mg every Friday",
      indication: "Anemia of Chronic Kidney Disease",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 14,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Esomeprazole",
      grouping: "Proton pump inhibitor",
      name: "Esomeprazole: 40mg po daily",
      indication: "Gastroesophageal Reflux Disease (GERD)",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 15,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Iron Sulfate",
      grouping: "Iron supplement",
      name: "Iron Sulfate: 300mg po daily",
      indication: "Iron-deficiency anemia",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 16,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Seroquel",
      grouping: "Antipsychotic",
      name: "Seroquel: 50mg po at bedtime",
      indication: "Depression, Insomnia",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 17,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Seroquel2",
      grouping: "Antipsychotic",
      name: "Seroquel: 25mg po 3x daily",
      indication: "Depression, Insomnia",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 18,
    },
    {
      start: admissionDate,
      end: new Date(2023, 2, 27, 12, 0, 0, 0),
      id: "Zofran",
      grouping: "Antiemetic",
      name: "Zofran: 4mg IV q6 hours",
      indication: "Nausea",
      discontinuation: "Nausea resolved",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 19,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "NPH Insulin",
      grouping: "Hormone",
      name: "NPH Insulin: 6u subcutaneoously qAM",
      indication: "Type II Diabetes mellitus",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 20,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Midodrine",
      grouping: "Alpha-adrenergic agonist",
      name: "Midodrine: 20mg po daily",
      indication: "Hypotension",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 21,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Trazadone",
      grouping: "Selective serotonin-reuptake inhibitor (SSRI)",
      name: "Trazadone: 50mg prn (optionally given)",
      indication: "Depression,Insomnia",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 22,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Paroxetine",
      grouping: "Selective serotonin-reuptake inhibitor (SSRI)",
      name: "Paroxetine: 20mg po daily",
      indication: "Depression, Anxiety",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 23,
    },
    {
      start: admissionDate,
      end: new Date(admissionDate.getTime() + 8 * daySeconds),
      id: "Levothyroxine",
      grouping: "Hormone",
      name: "Levothyroxine: 50mcg po daily",
      indication: "Hypothyroidism",
      progress: 100,
      type: "task",
      project: "PreRx",
      displayOrder: 24,
    },
  ];
  return tasks;
}

export function getStartEndDateForProject(tasks: Task[], projectId: string) {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}
