import styled from "@emotion/styled";
import {
  Avatar,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

interface PatientListNoteProps {
  content: string;
  by: string;
  avatar: string;
  id: string;
  date: string;
}

const PatientListNote = ({
  content,
  by,
  avatar,
  date,
  id,
}: PatientListNoteProps) => {
  return (
    <Grid
      component={Card}
      mr={2}
      mb={4}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      item
      xs={5}
      sm={3}
      md={2}
      lg={2}
      xl={2}
      xxl={2}
    >
      <CardHeader avatar={<Avatar alt={by} src={avatar} />} title={by} />
      <Divider mt={0} mb={4} />
      <CardContent>
        <Typography variant="body1" gutterBottom>
          {content}
        </Typography>
      </CardContent>
    </Grid>
  );
};

export default PatientListNote;
