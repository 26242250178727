import { createSlice } from "@reduxjs/toolkit";

export interface DayState {
  value: number;
}

const initialState: DayState = {
  value: 0,
};

const daySlice = createSlice({
  name: "day",
  initialState,
  reducers: {
    changeDay: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeDay } = daySlice.actions;

export default daySlice.reducer;
