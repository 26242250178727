import * as React from "react";
import { Box, IconButton, Popover } from "@mui/material";
import { SpatialAudioOff } from "@mui/icons-material";

const audioFile = require("../../../assets/audio/speech.mp3");
function PatientAudio() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <IconButton aria-label="play audio" onClick={handleClick}>
        <SpatialAudioOff />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            p: 2,
          }}
        >
          <audio controls autoPlay>
            <source src={audioFile} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </Box>
      </Popover>
    </React.Fragment>
  );
}

export default PatientAudio;
