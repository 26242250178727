import React from "react";
import BladderWarningPng from "./assets/bladder.png";
import styled from "@emotion/styled";
import { VitruvianAnimationForMedium } from "./assets/VitruvianAnimationForMedium";
import { Popover, Typography } from "@mui/material";

const BladderStyle = styled("img")`
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.5));
  top: 45.7%;
  left: 44.5%;
  width: 13%;
  animation-delay: 350ms;
  ${VitruvianAnimationForMedium}
`;

function BladderLayer() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <BladderStyle
        src={BladderWarningPng}
        aria-owns={open ? "Bladder-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="Bladder-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>Urinary tract infection (UTI).</Typography>
      </Popover>
    </React.Fragment>
  );
}

export default BladderLayer;
