const reportsData = {
  reports: [
    {
      hospitalDay: 1,
      date: "2023-03-24",
      reports: [
        {
          id: 1,
          title: "Admission Note",
          icon: "add_home",
          date: "2023-03-24",
          time: "07:00",
          color: "#299863",
          bgColor: "#D7F5E6",
          content:
            "70 y/o obese Caucasian female with a history of severe, oxygen-dependent chronic obstructive pulmonary disease (COPD) presenting with blood in her tracheal tube and mild shortness of breath. Her medical history includes non-small cell lung cancer (NSCLC). She presents to the Emergency Department (ED) today from a respiratory rehabilitation facility where she was found diaphoretic. Her trach tube was suction, resulting in removal of a mucus plug followed by persistent blood in the trach tube.\n\n ### 1. RESPIRATORY COMPROMISE\n\n Concern for mechanical problem with tube or airway obstructing lesion; bronchoscopy done showing no obstruction and friable, bleeding epithelia. Presumptive diagnosis of pneumonia, begun on Zosyn in ER.\n\n ### 2. BACTERMIA WITH INDWELLING PICC LINE\n\n  Blood cultures were collected from PICC line; case discussed with surgery and ID consultants, particularly with regard to potential removal of the patient's PICC line. \n\n ### 3. URINARY TRACT INFECTION WITH INDWELLING FOLEY CATHETER \n\n Urinanalysis consistent with UTI, culture pending. Consider adjusting antibiotics pending culture results, as well as changing out Foley catheter in MICU. \n\n ### 4. NUMEROUS OTHER CHRONIC DISEASES\n\n Patient is ventilator-dependent and bedridden, and predisposed to further lung, bladder and line infections, as well as stasis ulcers. Pressure ulcer precautions in MICU.",
          by: "Dr. Eric Ries",
        },
        {
          id: 101,
          title: "Radiology report",
          icon: "monitor_heart",
          date: "2023-03-24",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "### ECHO: Mild Latrial dilation; \n\n RV size/motion within normal limits; aortic valve leaflets mildly thickened without stenosis w/o vegetations; trace aortic regurgitation; mitral valve leaflets mildly thickened w/o vegetations; 1+ mitral regurgitation; moderate pulmonary artery systolic hypertension; pulmonic valve within normal limits; no pericardial effusion or suggestion of endocarditis.",
          by: "Dr. Eric Ries",
        },
        {
          id: 102,
          title: "Nursing Report",
          icon: "NurseIcon",
          date: "2023-03-24",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "### DNR Status: Full Code \n\n Neuro: Alert, follows commands. Slow to respond at times. MAE. Mouthing words occ., nodding head to questions. No indications of pain. \n\n Resp: No vent changes made overnight. LS coarse/diminished. Sxned infrequently for small amounts of tan/yellow, thick secretions. Sats 95-100%. RR15-20. No cough or SOB noted.",
          by: "Florence Nightingale",
        },
      ],
    },
    {
      hospitalDay: 2,
      date: "2023-03-25",
      reports: [
        {
          id: 201,
          title: "Temperature spike",
          icon: "device_thermostat",
          date: "2023-03-25",
          time: "07:00",
          color: "#E57108",
          bgColor: "#FBEADD",
          content:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum eaque qui nam amet mollitia omnis labore quos dolore voluptas reiciendis dicta iure voluptate officiis.",
          by: "ThinLayer Alert",
        },
        {
          id: 2,
          title: "Bronchoscopy report",
          icon: "LungIcon",
          date: "2023-03-25",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "No obstructions, although friable, bleeding epithelia was noted",
          by: "Dr. Eric Ries",
        },
        {
          id: 202,
          title: "Nursing Report",
          icon: "NurseIcon",
          date: "2023-03-25",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "### DNR Status: Full Code \n\n Neuro: Alert, follows commands. Slow to respond at times. MAE. Mouthing words occ., nodding head to questions. No indications of pain. \n\n Resp: No vent changes made overnight. LS coarse/diminished. Sxned infrequently for small amounts of tan/yellow, thick secretions. Sats 95-100%. RR15-20. No cough or SOB noted.",
          by: "Florence Nightingale",
        },
      ],
    },
    {
      hospitalDay: 3,
      date: "2023-03-26",
      reports: [
        {
          id: 3,
          title: "Microbiology report",
          icon: "MicrobiologyIcon",
          date: "2023-03-26",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "Blood Culture x4: + Enterococcus faecalis, sensitive to Ampicillin, Vancomycin & Penicillin\n\nBlood Culture (PICC Line): + Pseudomonas aeroginosa, pan-sensitive\n\nUrine Culture: + Proteus mirabilis, resistant to Ampicillin, Amoxacillin, Gentamycin & Bactrim; sensitive to Ciprofloxacin & Cefalosporins (chronic Foley catheter use)\n\nBrochoalveolar lavage (BAL): + Pseudomonas aeroginosa, pan-sensitive",
          by: "Dr. Eric Ries",
        },
        {
          id: 301,
          title: "X-Ray report",
          icon: "XrayIcon",
          date: "2023-03-26",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste unde excepturi, neque voluptates autem minima alias aspernatur. Sint ea dolor itaque dicta deleniti minima sit, odit, corrupti praesentium non ipsam.",
          by: "Dr. Eric Ries",
        },
        {
          id: 302,
          title: "Nursing Report",
          icon: "NurseIcon",
          date: "2023-03-26",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "### DNR Status: Full Code \n\n Neuro: Alert, follows commands. Slow to respond at times. MAE. Mouthing words occ., nodding head to questions. No indications of pain. \n\n Resp: No vent changes made overnight. LS coarse/diminished. Sxned infrequently for small amounts of tan/yellow, thick secretions. Sats 95-100%. RR15-20. No cough or SOB noted.",
          by: "Florence Nightingale",
        },
      ],
    },
    {
      hospitalDay: 4,
      date: "2023-03-27",
      reports: [
        {
          id: 4,
          title: "Temperature spike",
          icon: "device_thermostat",
          date: "2023-03-27",
          time: "07:00",
          color: "#E57108",
          bgColor: "#FBEADD",
          content:
            "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum eaque qui nam amet mollitia omnis labore quos dolore voluptas reiciendis dicta iure voluptate officiis.",
          by: "ThinLayer Alert",
        },
        {
          id: 402,
          title: "Nursing Report",
          icon: "NurseIcon",
          date: "2023-03-27",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "### DNR Status: Full Code \n\n Neuro: Alert, follows commands. Slow to respond at times. MAE. Mouthing words occ., nodding head to questions. No indications of pain. \n\n Resp: No vent changes made overnight. LS coarse/diminished. Sxned infrequently for small amounts of tan/yellow, thick secretions. Sats 95-100%. RR15-20. No cough or SOB noted.",
          by: "Florence Nightingale",
        },
      ],
    },
    {
      hospitalDay: 5,
      date: "2023-03-28",
      reports: [
        {
          id: 5,
          title: "X-Ray report",
          icon: "XrayIcon",
          date: "2023-03-28",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste unde excepturi, neque voluptates autem minima alias aspernatur. Sint ea dolor itaque dicta deleniti minima sit, odit, corrupti praesentium non ipsam.",
          by: "Dr. Eric Ries",
        },
        {
          id: 502,
          title: "Nursing Report",
          icon: "NurseIcon",
          date: "2023-03-28",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "### DNR Status: Full Code \n\n Neuro: Alert, follows commands. Slow to respond at times. MAE. Mouthing words occ., nodding head to questions. No indications of pain. \n\n Resp: No vent changes made overnight. LS coarse/diminished. Sxned infrequently for small amounts of tan/yellow, thick secretions. Sats 95-100%. RR15-20. No cough or SOB noted.",
          by: "Florence Nightingale",
        },
      ],
    },
    {
      hospitalDay: 6,
      date: "2023-03-29",
      reports: [
        {
          id: 6,
          title: "Bronchoscopy report",
          icon: "LungIcon",
          date: "2023-03-29",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content: "Bronchoscopy report",
          by: "Dr. Eric Ries",
        },
        {
          id: 601,
          title: "X-Ray report",
          icon: "XrayIcon",
          date: "2023-03-29",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste unde excepturi, neque voluptates autem minima alias aspernatur. Sint ea dolor itaque dicta deleniti minima sit, odit, corrupti praesentium non ipsam.",
          by: "Dr. Eric Ries",
        },
        {
          id: 602,
          title: "Nursing Report",
          icon: "NurseIcon",
          date: "2023-03-29",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "### DNR Status: Full Code \n\n Neuro: Alert, follows commands. Slow to respond at times. MAE. Mouthing words occ., nodding head to questions. No indications of pain. \n\n Resp: No vent changes made overnight. LS coarse/diminished. Sxned infrequently for small amounts of tan/yellow, thick secretions. Sats 95-100%. RR15-20. No cough or SOB noted.",
          by: "Florence Nightingale",
        },
      ],
    },
    {
      hospitalDay: 7,
      date: "2023-03-30",
      reports: [
        {
          id: 7,
          title: "Nursing Report",
          icon: "NurseIcon",
          date: "2023-03-30",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "### DNR Status: Full Code \n\n Neuro: Alert, follows commands. Slow to respond at times. MAE. Mouthing words occ., nodding head to questions. No indications of pain. \n\n Resp: No vent changes made overnight. LS coarse/diminished. Sxned infrequently for small amounts of tan/yellow, thick secretions. Sats 95-100%. RR15-20. No cough or SOB noted.",
          by: "Florence Nightingale",
        },
      ],
    },
    {
      hospitalDay: 8,
      date: "2023-03-31",
      reports: [
        {
          id: 8,
          title: "Microbiology report",
          icon: "MicrobiologyIcon",
          date: "2023-03-30",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "Blood Culture x4: + Enterococcus faecalis, sensitive to Ampicillin, Vancomycin & Penicillin\n\nBlood Culture (PICC Line): + Pseudomonas aeroginosa, pan-sensitive\n\nUrine Culture: + Proteus mirabilis, resistant to Ampicillin, Amoxacillin, Gentamycin & Bactrim; sensitive to Ciprofloxacin & Cefalosporins (chronic Foley catheter use)\n\nBrochoalveolar lavage (BAL): + Pseudomonas aeroginosa, pan-sensitive",
          by: "Dr. Eric Ries",
        },
        {
          id: 801,
          title: "X-Ray report",
          icon: "XrayIcon",
          date: "2023-03-30",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste unde excepturi, neque voluptates autem minima alias aspernatur. Sint ea dolor itaque dicta deleniti minima sit, odit, corrupti praesentium non ipsam.",
          by: "Dr. Eric Ries",
        },
        {
          id: 802,
          title: "Nursing Report",
          icon: "NurseIcon",
          date: "2023-03-30",
          time: "07:00",
          color: "#777777",
          bgColor: "#F8F9FC",
          content:
            "### DNR Status: Full Code \n\n Neuro: Alert, follows commands. Slow to respond at times. MAE. Mouthing words occ., nodding head to questions. No indications of pain. \n\n Resp: No vent changes made overnight. LS coarse/diminished. Sxned infrequently for small amounts of tan/yellow, thick secretions. Sats 95-100%. RR15-20. No cough or SOB noted.",
          by: "Florence Nightingale",
        },
      ],
    },
  ],
};

export default reportsData;
