import vitruvianFemale from "../../assets/vitruvian-lung-thumbnail.png";
import vitruvianMale from "../../assets/vitruvian-sepsis-thumbnail.png";
import { orange, green, red, yellow } from "@mui/material/colors";
import eric from "../images/avatars/eric.jpg";
import joanne from "../images/avatars/joanne.jpg";

const patientCardData = [
  {
    patient: {
      id: "1",
      title: "CALVIN, Susan",
      mrn: "1434534",
      dob: "1953-03-24",

      height: "152cm",
      weight: "72kg",
      location: "Medical ICU",
      star: true,
      status: orange[600],
      vitruvian: vitruvianFemale,
      acuity: 3,
      summary:
        "Ventilator-dependent woman with COPD, CHF, tracheomalacia and respiratory failure presenting from rehab with concern of blood needing to be frequently suctioned from trach, associated with low grade fever and possible hypotension. ",
      conditions: [
        "COPD",
        "Lung Cancer",
        "Tracheomalacia",
        "Respiratory Failure",
      ],
      notes: [
        {
          id: "1",
          date: "2021-10-07",
          content: "Respiratory Therapy: wean PEEP over next 24 hours",
          by: "Dr. Eric Ries",
          avatar: eric,
        },
      ],
    },
  },
  {
    patient: {
      id: "2",
      title: "TURNER, William",
      mrn: "093275345",
      dob: "1958-04-12",
      height: "180cm",
      weight: "79kg",
      location: "Surgical Ward",
      star: true,
      vitruvian: vitruvianMale,
      status: green[600],
      acuity: 0,
      summary:
        "65-year old Caucasian male with a 50-pack-year smoking history, atrial fibrillation and well-controlled CHF admitted preoperatively for cardiac ablation in the morning.",
      conditions: [
        "Atrial Fibrillation",
        "Congestive Heart Failure",
        "Smoking",
      ],
      notes: [
        {
          id: "1",
          date: "2021-10-07",
          content: "NPO after midnight",
          by: "Dr. Eric Ries",
          avatar: eric,
        },
      ],
    },
  },
  {
    patient: {
      id: "3",
      title: "MARCOTTE, Ethan",
      mrn: "1435748",
      dob: "1967-07-07",
      height: "188cm",
      weight: "78kg",
      location: "Neurological ICU",
      star: true,
      status: red[600],
      acuity: 3,
      vitruvian: vitruvianMale,
      summary:
        "56-year old white male was admitted to the hospital following a dense hemorrhagic stroke, with loss of speech and L-sided motor function, determined to be as a result of a large aneurysmal bleed at the base of the brain.",
      conditions: ["Stroke", "Aneurysm", "Pneumonia", "Sepsis"],
      notes: [
        {
          id: "1",
          date: "2021-10-07",
          content: "Hospice consultation requested by family",
          by: "Dr. Joanne Smith",
          avatar: joanne,
        },
      ],
    },
  },
  {
    patient: {
      id: "4",
      title: "LEE, David",
      mrn: "555111222",
      dob: "1992-10-07",
      height: "178cm",
      location: "Medical Ward",
      weight: "79kg",
      star: true,
      vitruvian: vitruvianMale,
      status: yellow[700],
      acuity: 1,
      summary:
        "31-year old man of Chinese descent with a longstanding history of Crohn's disease, as well as a history of bowel obstruction and anal fissures, presenting with diffuse abdominal discomfort, watery diarrhea for 3 days, fatigue and weight loss.]",
      conditions: ["Crohn's Disease", "Weight Loss"],
      notes: [
        {
          id: "1",
          date: "2021-10-07",
          content: "GI consult pending today",
          by: "Dr. Eric Ries",
          avatar: eric,
        },
      ],
    },
  },
  {
    patient: {
      id: "5",
      title: "JOHNSON, Sarah",
      mrn: "987654321",
      dob: "1980-05-03",
      height: "162cm",
      weight: "65kg",
      location: "A&E",
      star: true,
      vitruvian: vitruvianFemale,
      status: yellow[700],
      acuity: 1,
      summary:
        "43-year old woman of North African descent with a longstanding history of poorly controlled asthma, presenting with severe shortness of breath at rest, refractory to ventolin inhaler treatments at home. 2 packs per day cigarette usage.",
      conditions: ["Asthma", "Smoking"],
      notes: [
        {
          id: "1",
          date: "2021-10-07",
          content: "Medical Ward: bed on 4 East expected to clear by 7 PM",
          by: "Dr. Joanne Smith",
          avatar: joanne,
        },
      ],
    },
  },
  {
    patient: {
      id: "6",
      title: "RODRIGUEZ, Maria",
      mrn: "14564566",
      dob: "1975-11-20",
      height: "165cm",
      weight: "73kg",
      location: "Medical ICU",
      star: true,
      vitruvian: vitruvianFemale,
      status: orange[600],
      acuity: 2,
      summary:
        "47-year old Hispanic female with longstanding hyperthyroidism presents in thyroid storm, with a temperature of 103 deg F, HR 120 and delirium.",
      conditions: ["Hyperthyroidism", "Graves Disease", "Thyroid Storm"],
      notes: [
        {
          id: "1",
          date: "2021-10-07",
          content: "Endocrinology consult pending today",
          by: "Dr. Eric Ries",
          avatar: eric,
        },
        {
          id: "2",
          date: "2021-10-07",
          content: "Thionamide requested from Pharmacy",
          by: "Dr. Joanne Smith",
          avatar: joanne,
        },
      ],
    },
  },
  {
    patient: {
      id: "7",
      title: "BROWN, James",
      mrn: "039458490",
      dob: "1965-02-15",
      height: "173cm",
      weight: "86kg",
      location: "Medical ICU",
      star: true,
      vitruvian: vitruvianMale,
      status: orange[600],
      acuity: 2,
      summary:
        "60-year old African American male with poorly controlled HTN, T2DM and a history of 3-vessel coronary artery bypass surgery (May 2022) presenting with BP 180/110 and a retroorbital headache.",
      conditions: [
        "Hypertensive Crisis",
        "Hypertension",
        "Diabetes",
        "Coronary Artery Disease",
      ],
      notes: [
        {
          id: "1",
          date: "2021-10-07",
          content: "Responding Well to IV Labetalol",
          by: "Dr. Joanne Smith",
          avatar: joanne,
        },
        {
          id: "2",
          date: "2021-10-07",
          content: "Taper Labetalol drip, targeting SBP 140 mmHg",
          by: "Dr. Eric Ries",
          avatar: eric,
        },
      ],
    },
  },
  {
    patient: {
      id: "8",
      title: "WILSON, Emily",
      mrn: "103948573",
      dob: "1995-06-30",
      height: "163cm",
      weight: "61kg",
      location: "Surgical Ward",
      star: true,
      vitruvian: vitruvianFemale,
      status: yellow[700],
      acuity: 1,
      summary:
        "28-year old Caucasian female with BMI>40 and documented cholelithiasis, status post laparoscopic cholecystectomy 3 days prior to admission, who presents to A&E with redness, swelling and a small amount of purulent drainage from a periumbilical surgical site, associated with a temperature of 101.5 deg F.",
      conditions: [
        "Cholecystitus",
        "Laporoscopic Cholecystectomy",
        "Syrgical Would Infection",
        "Obesity",
      ],
      notes: [
        {
          id: "1",
          date: "2021-10-07",
          content: "Infectious Disease consultation requested",
          by: "Dr. Eric Ries",
          avatar: eric,
        },
      ],
    },
  },
  {
    patient: {
      id: "9",
      title: "CHANG, Michael",
      mrn: "3948574758",
      dob: "1988-09-08",
      height: "175cm",
      weight: "73kg",
      location: "",
      star: true,
      vitruvian: vitruvianMale,
      status: yellow[700],
      acuity: 1,
      summary:
        "35 year-old male with longstanding history of migraine headaches presents with a severe, atypical occipital headache and bilateral visual findings; MRI reveals a 3 cm R occipital mass without shift.",
      conditions: ["Migraine", "Intercranial Mass"],
      notes: [
        {
          id: "1",
          date: "2021-10-07",
          content: "PET scan ordered; Neurosurgery consult underwayy",
          by: "Dr. Joanne Smith",
          avatar: joanne,
        },
      ],
    },
  },
  {
    patient: {
      id: "10",
      title: "PATEL, Lisa",
      mrn: "234645456",
      dob: "1972-05-12",
      height: "168cm",
      weight: "70kg",
      location: "Surgical Ward",
      star: true,
      vitruvian: vitruvianFemale,
      status: green[600],
      acuity: 0,
      summary:
        "51-year old South Asian woman with longstanding osteoporosis and osteoarthritis, as well as NSAID-induced gastric ulcers, presenting for R total hip replacement surgery tomorrow.",
      conditions: ["Osteoporosis", "Osteoarthritis", "NSAID-induced Ulcer"],
      notes: [
        {
          id: "1",
          date: "2021-10-07",
          content:
            "NPO after midnight; Physical Therapy consultation requested post-op",
          by: "Dr. Eric Ries",
          avatar: eric,
        },
      ],
    },
  },
];

export default patientCardData;
