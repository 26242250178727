import { Typography, Stack, Box, Chip, Grid } from "@mui/material";

// import PatientScoresApiTest from "./PatientScoresApiTest";

function PatientBasicDetailsMDT({ patient }: { patient: any }) {
  let allergyContent = null;
  if (patient.allergies && patient.allergies.length > 0) {
    allergyContent = (
      <div>
        <Stack direction="row" spacing={2} mb={0} useFlexGap flexWrap="wrap">
          {patient.allergies.map((allergy: any) => (
            <Chip color="info" size="small" label={allergy} key={allergy} />
          ))}
        </Stack>
      </div>
    );
  } else {
    allergyContent = <Chip color="info" size="small" label="None" />;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={5}>
        <Box sx={{ mb: 1 }}>
          <Typography variant="h4" sx={{ mt: 4, mb: 1 }}>
            Details
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>Name:</strong> {patient.firstName} {patient.lastName}
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>MRN:</strong> {patient.mrn}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Age:</strong> {patient.age}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>DOB:</strong> {patient.dob}
          </Typography>
          {/* <Typography variant="body1" gutterBottom>
            <strong>Hospital Duration: </strong>
            {patient.hospitalDuration}
          </Typography> */}
          <Typography variant="body1" gutterBottom>
            <strong>Location:</strong> {patient.location}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>BMI:</strong> {patient.BMI}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Height:</strong> {patient.height}cm
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Weight:</strong> {patient.weight}kg
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="h4" sx={{ mt: 4, mb: 1 }}>
          Conditions
        </Typography>
        <Stack direction="row" spacing={2} mb={3} useFlexGap flexWrap="wrap">
          {patient.conditions.map((condition: string) => (
            <Chip
              color="primary"
              size="small"
              label={condition}
              key={condition}
            />
          ))}
        </Stack>
        <Typography variant="h4" sx={{ mt: 4, mb: 1 }}>
          Medications
        </Typography>
        <Stack direction="row" spacing={2} mb={3} useFlexGap flexWrap="wrap">
          <Chip
            variant="outlined"
            size="small"
            label="Ibuprofen"
            key="Ibuprofen"
            sx={{ borderRadius: "32px" }}
          />
        </Stack>

        {/* {showScoring && <PatientScoresApiTest patientId={patient.id} />} */}
        <Typography variant="h4" sx={{ mt: 4, mb: 1 }}>
          Allergies
        </Typography>
        {allergyContent}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ mt: 4, mb: 3 }}>
          Summary
        </Typography>
        <Typography variant="body1" gutterBottom>
          Mr. Abara is a 72-year-old, moderately obese (BMI = 36) male of South
          African descent being considered for a Right Total Hip Replacement
          secondary to osteoarthritis. Patient’s noteworthy past medical history
          includes: obesity, 50 pack-years of ongoing tobacco use, and a
          non-ST-elevation myocardial infarction (NSTEMI) 5 years prior,
          resulting in a successful stenting of his left anterior descending
          (LAD) coronary artery. He has no cardiac symptoms, no shortness of
          breath with ambulation or signs of congestive heart failure (no
          peripheral oedema, pulmonary rales or orthopnea). Patient lives alone
          and independently but is having considerable difficulty climbing the
          stairs to his 2nd story flat. Pre-operative MDT evaluation is
          requested given his cardiac history, obesity and sedentary nature, as
          well as concern for post-operative support for activities of daily
          living.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PatientBasicDetailsMDT;
