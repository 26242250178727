import { createSlice } from "@reduxjs/toolkit";

export interface NewListState {
  name: string;
  desc: string;
  set: boolean;
}

const initialState: NewListState = {
  name: "",
  desc: "",
  set: false,
};

const newListSlice = createSlice({
  name: "newList",
  initialState,
  reducers: {
    changeNewList: (state, action) => {
      state.name = action.payload.name;
      state.desc = action.payload.desc;
      state.set = true;
    },
  },
});

export const { changeNewList } = newListSlice.actions;

export default newListSlice.reducer;
