import * as tlIcons from "./TLSvgIcons";
import { Icon } from "@mui/material";

function TLIcon(props: { name: string }) {
  if (props.name in tlIcons) {
    const ThisIcon = tlIcons.stringMap[props.name];
    return <ThisIcon />;
  } else {
    return <Icon>{props.name}</Icon>;
  }
}

export default TLIcon;
