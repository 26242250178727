import React from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useGetPatientQuery } from "../../redux/store";

import PatientTimeline from "../../components/timeline/patientTimeline";
import PatientCharts from "../../components/patientChart/PatientCharts";

import DischargeReport from "../../components/patient/DischargeReport";
import PatientStoryApiTest from "../../components/patient/apiTestFiles/PatientStoryApiTest";
import GanttPanel from "../../components/gantt/GantPanel";
// import { Editor } from "../../components/textEdit/LexicalEditor";
// import Editor from "../../components/textEdit/Editor";
import Glyph from "../../components/textEdit/Glyph";
import {
  Box,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Popover,
  Button,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";

import { AttachFile, Send } from "@mui/icons-material";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function PanelDischarge() {
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={5} pr={8}>
          {/* Discharge  */}
          <DischargeReport />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={7}>
          {/* Timeline and charts  */}
          <PatientCharts />
          <PatientTimeline />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
function LoadingCard() {
  return (
    <Card mb={6}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </CardContent>
    </Card>
  );
}

function PatientCard({ patient }: { patient: any }) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Card mb={6}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, mt: 4, mx: 8, borderColor: "divider" }}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab label="OVERVIEW" value="1" />
            <Tab label="MEDICATIONS" value="7" />
            <Tab label="LABORATORY" value="2" />
            <Tab label="MICROBIOLOGY" value="3" />
            <Tab label="IMAGING" value="4" />
            <Tab label="CARE TEAM" value="5" />
            <Tab label="DISCHARGE SUMMARY" value="6" />
          </TabList>
        </Box>
        <TabPanel sx={{ p: 0 }} value="1">
          <PatientStoryApiTest patient={patient} />
        </TabPanel>
        <TabPanel sx={{ p: 8 }} value="7">
          <GanttPanel />
        </TabPanel>
        <TabPanel sx={{ p: 8 }} value="2">
          <Box sx={{ position: "relative" }}>
            <Glyph />
          </Box>
          {/* <Editor /> */}
        </TabPanel>
        <TabPanel sx={{ p: 8 }} value="6">
          <PanelDischarge />
        </TabPanel>
      </TabContext>
    </Card>
  );
}

function TLAssistant() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        TL Assistant
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            bgcolor: "background.paper",
            borderRadius: 1,
            p: 4,
            width: 300,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              borderRadius: 2,
              p: 3,
            }}
          >
            Are the nursing notes missing any relevant documentation of the
            patient's hospital course?
          </Box>
          <Box
            sx={{
              borderRadius: 2,
              p: 3,
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              mt: 4,
            }}
          >
            It appears that several of the nursing notes are missing
            documentation of the patient's vital signs, pain levels, any changes
            in the patient's condition, and nursing interventions performed.
            Additionally, there is limited documentation of the patient's
            physical and mental state.
          </Box>
          <TextField sx={{ mt: 6 }} label="Query" color="secondary" focused />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <IconButton aria-label="attach">
              <AttachFile />
            </IconButton>
            <IconButton color="primary" aria-label="send">
              <Send />
            </IconButton>
          </Stack>
        </Box>
      </Popover>
    </div>
  );
}

function PatientApiTest() {
  const { patientId } = useParams<{ patientId: string }>();

  const { data, error, isLoading } = useGetPatientQuery(patientId);

  let content = null;
  let patientName = "";

  if (isLoading) {
    content = <LoadingCard />;
    patientName = "Loading...";
  } else if (error) {
    content = <div>Error</div>;
    patientName = "Error";
  } else if (data) {
    content = <PatientCard patient={data} />;
    patientName = `${data.firstName} ${data.lastName}`;
  }

  return (
    <React.Fragment>
      <Helmet title="Susan Calvin" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="h3" display="inline">
            {patientName}
          </Typography>
        </Box>
        <TLAssistant />
      </Box>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Home
        </Link>
        <Link component={NavLink} to="/list-starred">
          Starred
        </Link>
        <Typography>{patientName}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PatientApiTest;
