import React, { useState } from "react";
import styled from "@emotion/styled";
import { Star, AttachFile, GroupAdd, Send } from "@mui/icons-material";
import { orange, grey } from "@mui/material/colors";
import PatientListNote from "./PatientListNote";
import PatientAudio from "../forms/formComponents/patientAudio";
import { NavLink } from "react-router-dom";

import {
  Avatar,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Divider as MuiDivider,
  Chip,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { NEWS } from "../../constants";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContentStyled = styled(CardContent)`
  padding: 16px 32px 4px 24px;
  &:last-child {
    padding-bottom: 0;
  }
}
`;

interface PatientCardDraggableProps {
  patient: {
    id: string;
    title: string;
    mrn: string;
    location: string;
    acuity: number;
    dob: string;
    height: string;
    weight: string;
    conditions: string[];
    notes: {
      id: string;
      content: string;
      by: string;
      avatar: string;
      date: string;
    }[];
    summary: string;
    vitruvian: string;
    star: boolean;
    status?: string;
  };
}

const PatientCardDraggable = ({ patient }: PatientCardDraggableProps) => {
  const [thisStar, setThisStar] = useState(patient.star);
  const handleClick = () => {
    setThisStar(!thisStar);
  };
  let starColor = thisStar ? orange[500] : grey[300];
  const linkUrl = "/patient/" + patient.id;
  let statusBgColor = NEWS[patient.acuity];

  return (
    <Grid container>
      <Grid
        component={Card}
        mb={4}
        mr={2}
        item
        xs={12}
        sm={11}
        md={6}
        lg={6}
        xl={5}
        xxl={4}
      >
        <CardHeader
          action={
            <Stack direction="row" spacing={2} mt={2}>
              <PatientAudio />

              <IconButton
                sx={{ color: starColor }}
                onClick={handleClick}
                aria-label="starred"
              >
                <Star />
              </IconButton>
            </Stack>
          }
          avatar={
            <Link
              color="inherit"
              component={NavLink}
              to={linkUrl}
              underline="none"
            >
              <Avatar
                aria-label="recipe"
                sx={{
                  bgcolor: statusBgColor,
                  color: "black",
                  border: "1px solid #0000001A",
                  fontWeight: "900",
                }}
              >
                {patient.acuity}
              </Avatar>
            </Link>
          }
          title={
            <Link
              color="inherit"
              component={NavLink}
              to={linkUrl}
              underline="none"
            >
              {patient.title}
            </Link>
          }
          subheader={
            <Link
              color="inherit"
              component={NavLink}
              to={linkUrl}
              underline="none"
            >
              {patient.location}
            </Link>
          }
        />
        <Divider mt={0} mb={2} />

        <Link color="inherit" component={NavLink} to={linkUrl} underline="none">
          <CardContentStyled>
            <Grid container spacing={2}>
              <Grid item xs={9} sm={3}>
                <Typography variant="body2" gutterBottom>
                  MRN: {patient.mrn}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  DOB: {new Date(patient.dob).toLocaleDateString()}
                </Typography>

                <Typography variant="body2" gutterBottom>
                  Height: {patient.height}
                </Typography>

                <Typography variant="body2" gutterBottom>
                  Weight: {patient.weight}
                </Typography>
              </Grid>

              <Grid item xs={3} sm={2}>
                <img
                  style={{ maxWidth: 60 }}
                  width="100%"
                  src={patient.vitruvian}
                  alt="vitruvion"
                />
              </Grid>
              <Grid item xs={12} sm={7} mb={4}>
                <Stack
                  direction="row"
                  spacing={2}
                  mb={2}
                  useFlexGap
                  flexWrap="wrap"
                >
                  {patient.conditions.map((condition) => (
                    <Chip
                      key={condition}
                      label={condition}
                      color="primary"
                      size="small"
                    />
                  ))}
                </Stack>
                <Typography variant="body1" gutterBottom>
                  {patient.summary}
                </Typography>
              </Grid>
            </Grid>
          </CardContentStyled>
        </Link>
      </Grid>
      {patient.notes.map((note) => (
        <PatientListNote
          key={note.id}
          id={note.id}
          content={note.content}
          by={note.by}
          avatar={note.avatar}
          date={note.date}
        />
      ))}
      <Grid
        component={Card}
        mr={2}
        mb={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        item
        xs={5}
        sm={3}
        md={2}
        lg={2}
        xl={2}
        xxl={2}
      >
        <div>
          <CardHeader sx={{ height: "72px" }} title="Add note..." />
          <Divider mt={0} mb={2} />
        </div>
        <CardContent sx={{ pb: 0 }}>
          <TextField
            id="outlined-multiline-static"
            multiline
            variant="filled"
            rows={5}
            fullWidth
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={0}
          >
            <IconButton aria-label="add person">
              <GroupAdd />
            </IconButton>
            <IconButton aria-label="add attachment">
              <AttachFile />
            </IconButton>
            <IconButton color="primary" aria-label="add attachment">
              <Send />
            </IconButton>
          </Stack>
        </CardContent>
        {/* </Card> */}
      </Grid>
    </Grid>
  );
};

export default PatientCardDraggable;
