import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

interface PictureSwitchProps {
  picture: any;
  checked: boolean;
}

const PictureSwitch = ({ picture, checked }: PictureSwitchProps) => {
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 75,
    height: 40,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(4px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(32px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: picture,
          border: "1px solid #2F7D31",
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#2F7D31",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      width: 38,
      height: 38,
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: `url('${picture}')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "#d9d9d9",
      borderRadius: 100,
    },
  }));
  return <MaterialUISwitch sx={{ m: 1 }} color="success" />;
};

export default PictureSwitch;
