export const patientStories = [
  {
    story: {
      id: 1,
      mrn: "14334534",
      firstName: "Susan",
      lastName: "Calvin",
      age: 71,
      duration: 8,
      location: "MICU Bed 12",
      dob: "03/24/1953",
      height: 152,
      weight: 72,
      conditions: [
        "Congestive Heart Failure",
        "COPD",
        "Gastroesophageal Reflux Disease",
        "Hypothyroidism",
        "Lung Cancer",
        "Pneumonia",
        "Pulmonary Hypertension",
        "Respiratory Failure",
        "Tracheomalacia",
        "UTI",
      ],
      medications: [
        "Albuterol",
        "Ativan",
        "Fentanyl",
        "Morphine",
        "Oxycodone",
        "Zofran",
      ],
      allergies: ["Penicillin", "Codeine"],
      days: [
        {
          dayNo: 1,
          date: "03/24/2023",
          daySummary: {
            content: (
              <div>
                <p>
                  71 y/o obese Caucasian female with a history of severe,
                  oxygen-dependent COPD presenting with blood in her tracheal
                  tube and mild shortness of breath. PMH includes non-small cell
                  lung cancer (NSCLC). She presents to the Emergency Department
                  (ED) today from a respiratory rehabilitation facility where
                  she was found diaphoretic. Her trach tube was suctioned,
                  resulting in removal of a mucus plug followed by persistent
                  blood in the trach tube.
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          dayTrend: {
            content: (
              <div>
                <p>N/A (just admitted to hospital)</p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          carePlan: {
            content: (
              <div>
                <p>
                  <li>Admit to Medical ICU</li>
                  <li>
                    Begin Zosyn for broad spectrum microbial coverage, with
                    pneumonia as likely primary source
                  </li>
                  <li>Perform Bronchoscopy to assess airway bleeding</li>
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
        },
        {
          dayNo: 2,
          date: "03/25/2023",
          daySummary: {
            content: (
              <div>
                <p>
                  71 y/o F with h/o severe, O2-dependent COPD who presented with
                  blood in tracheal tube and mild shortness of breath,
                  presumptive diagnosis of pneumonia and possible line
                  infection. PMH notable for non-small cell lung cancer (NSCLC)
                  and L upper lobectomy.
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          dayTrend: {
            content: (
              <div>
                <p>
                  Mucus plug suctioned out, with persistent blood in trach tube.
                  Low-grade fever; blood/urine/PICC/bronchoalveolar lavage
                  cultures sent. Started on Zosyn, low grade fever and elevated
                  white cell count persists.
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          carePlan: {
            content: (
              <div>
                <p>
                  <li>Monitor in ICU</li>
                  <li>IV Antibiotics</li>
                  <li>Supportive Respiratory Therapy on Vent</li>
                  <li>Infectious Disease</li>
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
        },
        {
          dayNo: 3,
          date: "03/26/2023",
          daySummary: {
            content: (
              <div>
                <p>
                  71 y/o F with h/o severe, O2-dependent COPD who presented with
                  blood in tracheal tube and mild shortness of breath,
                  Pseudomonas Pneumonia and possible line infection. PMH notable
                  for non-small cell lung cancer (NSCLC) and L upper lobectomy.
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          dayTrend: {
            content: (
              <div>
                <p>
                  Hemodynamically stable but low grade fevers persist.
                  Infectious Disease consult recommended adding Ampicillin
                  through PICC line for 2 week course.{" "}
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          carePlan: {
            content: (
              <div>
                <p>
                  <li>Add Meropenem</li>
                  <li>Repeat bronchoscopy </li>
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
        },
        {
          dayNo: 4,
          date: "03/27/2023",
          daySummary: {
            content: (
              <div>
                <p>
                  71 y/o F with h/o severe, O2-dependent COPD who presented with
                  blood in tracheal tube and mild shortness of breath,
                  Pseudomonas Pneumonia and possible line infection. PMH notable
                  for non-small cell lung cancer (NSCLC) and L upper lobectomy.
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          dayTrend: {
            content: (
              <div>
                <p>
                  Urine culture positive for resistant Proteus urinary tract
                  infection; yeast also grew out in culture. Low grade fevers
                  continue. Short course of Meropenem added to cover the UTI.
                  Bronch showed RML endobronchial lesion: granulation tissue vs.
                  relapse of cancer?
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          carePlan: {
            content: (
              <div>
                <p>
                  <li>Change Foley catheter</li>
                  <li>Meropenem added to antibiotic regimen </li>
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
        },
        {
          dayNo: 5,
          date: "03/28/2023",
          daySummary: {
            content: (
              <div>
                <p>
                  71 y/o F with h/o severe, O2-dependent COPD who presented with
                  blood in tracheal tube and mild shortness of breath,
                  Pseudomonas Pneumonia, Proteus UTI and possible line infection
                  under active treatment. PMH notable for non-small cell lung
                  cancer (NSCLC) and L upper lobectomy; bronchoscopy suggests
                  possible RML recurrence (pathology pending).
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          dayTrend: {
            content: (
              <div>
                <p>
                  Hemodynamically stable with fever and white cell count
                  dropping. Oxygen requirements somewhat less, and tracheal tube
                  bleeding has abated.
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          carePlan: {
            content: (
              <div>
                <p>
                  <li>
                    Continued antibiotic treatment as directed by Infectious
                    Disease consultant
                  </li>
                  <li>Wean ventilator FiO2 as tolerated</li>
                  <li>Physical Therapy: sit in chair today</li>
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
        },
        {
          dayNo: 6,
          date: "03/29/2023",
          daySummary: {
            content: (
              <div>
                <p>
                  71 y/o F with h/o severe, O2-dependent COPD who presented with
                  blood in tracheal tube and mild shortness of breath,
                  Pseudomonas Pneumonia, Proteus UTI and possible line infection
                  under active treatment. PMH notable for non-small cell lung
                  cancer (NSCLC) and L upper lobectomy; bronchoscopy suggests
                  possible RML recurrence (pathology pending).
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          dayTrend: {
            content: (
              <div>
                <p>
                  Hemodynamically stable with fever and white cell count
                  dropping. Oxygen requirements somewhat less, and tracheal tube
                  bleeding has abated. Tolerated limited activity yesterday (got
                  up into chair briefly). Some sacral skin breakdown - nursing
                  alerted.
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          carePlan: {
            content: (
              <div>
                <p>
                  <li>
                    Continued antibiotic treatment as directed by Infectious
                    Disease consultant
                  </li>
                  <li>Wean ventilator FiO2 as tolerated</li>
                  <li>Decubitus ulcer precautions and skin care</li>
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
        },
        {
          dayNo: 7,
          date: "03/30/2023",
          daySummary: {
            content: (
              <div>
                <p>
                  71 y/o F with h/o severe, O2-dependent COPD who presented with
                  blood in tracheal tube and mild shortness of breath,
                  Pseudomonas Pneumonia, Proteus UTI and possible line infection
                  under active treatment. Early signs of decubitus ulcer? PMH
                  notable for non-small cell lung cancer (NSCLC) and L upper
                  lobectomy; bronchoscopy suggests possible RML recurrence
                  (pathology pending).
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          dayTrend: {
            content: (
              <div>
                <p>
                  Hemodynamically stable with normal temperature and white cell
                  count. Oxygen requirements have returned to her baseline
                  (pre-hospitalization), and she is tolerating some limited
                  physical activity (transfers to commode and chair). No
                  interval change in sacral region (mild redness only).
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
          carePlan: {
            content: (
              <div>
                <p>
                  <li>
                    Continued antibiotic treatment as directed by Infectious
                    Disease consultant
                  </li>
                  <li>Decubitus ulcer precautions and skin care</li>
                  <li>
                    Discharge planning (return to respiratory rehabilitation
                    facility tomorrow?)
                  </li>
                </p>
              </div>
            ),
            approved: true,
            approvedBy: "Dr. Ries",
          },
        },
        {
          dayNo: 8,
          date: "03/31/2023",
          daySummary: {
            content: (
              <div>
                <p>
                  71 y/o F with h/o severe, O2-dependent COPD who presented with
                  blood in tracheal tube and mild shortness of breath,
                  Pseudomonas Pneumonia, Proteus UTI and possible line infection
                  under active treatment. PMH notable for non-small cell lung
                  cancer (NSCLC) and L upper lobectomy; bronchoscopy suggests
                  possible RML recurrence (pathology pending).
                </p>
              </div>
            ),
            approved: false,
            approvedBy: "",
          },
          dayTrend: {
            content: (
              <div>
                <p>
                  Stable and comfortable overnight, looking forward to return to
                  rehab facility.
                </p>
              </div>
            ),
            approved: false,
            approvedBy: "Dr. Ries",
          },
          carePlan: {
            content: (
              <div>
                <p>
                  <li>Discharge Summary; </li>
                  <li>Discharge to Respiratory Rehab Facility</li>
                </p>
              </div>
            ),
            approved: false,
            approvedBy: "Dr. Ries",
          },
        },
      ],
    },
  },
];
