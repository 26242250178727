import { createSlice } from "@reduxjs/toolkit";

export interface GraphSizeState {
  value: number;
}

const initialState: GraphSizeState = {
  value: 160,
};

const graphSizeSlice = createSlice({
  name: "graphSize",
  initialState,
  reducers: {
    changeGraphSize: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeGraphSize } = graphSizeSlice.actions;

export default graphSizeSlice.reducer;
