import { createSlice } from "@reduxjs/toolkit";

export interface ShowCodesState {
  value: boolean;
}

const storedCodes = localStorage.getItem("storedCodes");
const initialState: ShowCodesState = {
  value: storedCodes ? JSON.parse(storedCodes) : false,
};

const showCodesSlice = createSlice({
  name: "showCodes",
  initialState,
  reducers: {
    changeShowCodes: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeShowCodes } = showCodesSlice.actions;

export default showCodesSlice.reducer;
