import { $getRoot, $createTextNode } from "lexical";
import * as React from "react";
import "./styles.css";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
// import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, $createHeadingNode } from "@lexical/rich-text";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Box } from "@mui/material";

interface Props {}

const theme = {
  text: {
    bold: "editor-bold",
    italic: "editor-italic",
    underline: "editor-underline",
  },
};

function onError(error: Error): void {
  console.error(error);
}

function MyHeadingPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const handleClick = (e: React.MouseEvent) => {
    editor.update(() => {
      const root = $getRoot();
      root.append(
        $createHeadingNode("h1").append($createTextNode("Hello World!"))
      );
    });
  };

  return <button onClick={handleClick}>Heading</button>;
}

export default function Glyph(props: Props): JSX.Element {
  const initialConfig = {
    namespace: "MyEditor",
    theme,
    onError,
    nodes: [HeadingNode],
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Box sx={{ border: 1 }}>
        <MyHeadingPlugin />
        <RichTextPlugin
          contentEditable={<ContentEditable className="contentEditable" />}
          placeholder={<div className="placeHolder">Enter some text...</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
      </Box>
    </LexicalComposer>
  );
}
