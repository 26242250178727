import { Groups, Star, Warning as DischargeDue } from "@mui/icons-material";

const now = new Date();
const week = 7;

const plusOne = new Date();
plusOne.setDate(plusOne.getDate() + week * 1);

const homeList = [
  {
    href: "/list-starred",
    icon: Star,
    title: "Starred",
    amount: 2,
    description: "Patients added to your quick list.",
  },
  {
    href: "/list-discharge-due",
    icon: DischargeDue,
    title: "Discharge Due",
    amount: 6,
    description: "Patients who are ready for discharge",
  },
  {
    href: "/mdt/3",
    icon: Groups,
    title: "MDT Meeting",
    amount: 12,
    category: "Personal",
    description: now.toDateString(),
  },
  {
    href: "/mdt/2",
    icon: Groups,
    title: "MDT meeting",
    amount: 9,
    category: "Ward",
    description: plusOne.toDateString(),
  },
];

export default homeList;
