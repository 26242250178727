import React from "react";
import { Grid } from "@mui/material";
import VitruvianBenign from "../../vitruvian/VitruvianBenign";
import MDTReports from "./MDTReports";

import styled from "@emotion/styled";
import PatientStoryBasicDetailsMDT from "../mdt/PatientStoryBasicDetailsMDT";
import PatientScoresMDT from "../mdt/PatientScoresMDT";
import CarePlan from "./CarePlan";
import CommentTimeline from "./CommentTimeline";

const GridBasicDetails = styled(Grid)(({ theme }) => ({
  paddingRight: 24,
  paddingLeft: 16,
}));
const SecondColumn = styled(Grid)(({ theme }) => ({
  paddingRight: 24,
  paddingLeft: 24,
  paddingBottom: 16,
  paddingTop: 16,
  [theme.breakpoints.up("sm")]: {
    paddingRight: 24,
  },
  backgroundColor: "#F7F9FC",
  [theme.breakpoints.up("md")]: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingRight: 24,
  },
}));
const ThirdColumn = styled(Grid)(({ theme }) => ({
  paddingRight: 16,
  paddingLeft: 16,
  paddingTop: 16,
  [theme.breakpoints.up("sm")]: {
    paddingRight: 32,
    paddingLeft: 32,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 24,
    borderTop: "none",
  },
}));

function MDTPanel({ patient }: { patient: any }) {
  return (
    <React.Fragment>
      <Grid container pb={4} columns={24} sx={{ alignItems: "flex-start" }}>
        <Grid
          item
          container
          xs={24}
          sm={24}
          md={12}
          lg={10}
          xl={10}
          xxl={10}
          columns={24}
          sx={{ alignItems: "flex-start" }}
        >
          <Grid
            item
            pt={4}
            pr={2}
            pl={5}
            pb={4}
            xs={10}
            sm={6}
            md={8}
            lg={8}
            xl={6}
            xxl={5}
          >
            <VitruvianBenign width="100%" />
          </Grid>
          <GridBasicDetails
            item
            xs={14}
            sm={18}
            md={16}
            lg={16}
            xl={18}
            xxl={19}
            pb={0}
            pt={4}
          >
            {/* Basics  */}
            <PatientStoryBasicDetailsMDT patient={patient} />
          </GridBasicDetails>

          <GridBasicDetails pt={0} sx={{ alignItems: "flex-start" }}>
            {/* Scores */}
            <PatientScoresMDT />
            <CarePlan />
          </GridBasicDetails>
          {/* <DischargeReport /> */}
        </Grid>

        <SecondColumn item xs={24} sm={24} md={12} lg={7} xl={7} xxl={7}>
          <CommentTimeline />
        </SecondColumn>

        <ThirdColumn item xs={24} sm={24} md={24} lg={7} xl={7} xxl={7}>
          <MDTReports />
        </ThirdColumn>
      </Grid>
    </React.Fragment>
  );
}

export default MDTPanel;
