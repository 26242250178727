import React from "react";
import LungRemovedPng from "./assets/lungs-removed-lobe.png";
import styled from "@emotion/styled";
import { VitruvianAnimation } from "./assets/VitruvianAnimation";
import { Popover, Typography } from "@mui/material";

const LungRemovalStyle = styled("img")`
  top: 16.5%;
  left: 51%;
  width: 20.2%;
  ${VitruvianAnimation}
  animation-delay: 1150ms;
  clip-path: polygon(
    31% 100%,
    0% 57%,
    1% 30%,
    25% 0%,
    39% 0%,
    100% 49%,
    100% 74%,
    73% 88%,
    56% 96%
  );
`;

function LungRemovalLayer() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <LungRemovalStyle
        src={LungRemovedPng}
        aria-owns={open ? "lobectomy-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="lobectomy-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>Left upper lobectomy.</Typography>
      </Popover>
    </React.Fragment>
  );
}

export default LungRemovalLayer;
