import { Theme } from "@mui/material";

const tempUp = (ctx: any, value: any) => {
  const difference = ctx.p0.parsed.y - ctx.p1.parsed.y;
  const baseline = ctx.p1.parsed.y > 37.2;
  if (difference < -0.2 && baseline) {
    return value;
  }
  return undefined;
};

const chartDataThemed = (theme: Theme) => {
  return [
    {
      chartName: "HR",
      data: {
        labels: [
          "Day 1 7am",
          "Day 1 3pm",
          "Day 1 11pm",
          "Day 2 7am",
          "Day 2 3pm",
          "Day 2 11pm",
          "Day 3 7am",
          "Day 3 3pm",
          "Day 3 11pm",
          "Day 4 7am",
          "Day 4 3pm",
          "Day 4 11pm",
          "Day 5 7am",
          "Day 5 3pm",
          "Day 5 11pm",
          "Day 6 7am",
          "Day 6 3pm",
          "Day 6 11pm",
          "Day 7 7am",
          "Day 7 3pm",
          "Day 7 11pm",
          "Day 8 7am",
          "Day 8 3pm",
          "Day 8 11pm",
        ],
        datasets: [
          {
            label: "HR",
            fill: true,
            backgroundColor: "transparent",
            borderColor: theme.palette.primary.main,
            tension: 0,
            data: [
              null,
              98,
              100,
              89,
              94,
              85,
              85,
              87,
              92,
              98,
              104,
              89,
              84,
              82,
              82,
              84,
              82,
              80,
              81,
              79,
              82,
              83,
              80,
            ],
          },
        ],
      },
    },
    {
      chartName: "Temp",
      data: {
        labels: [
          "Day 1 7am",
          "Day 1 3pm",
          "Day 1 11pm",
          "Day 2 7am",
          "Day 2 3pm",
          "Day 2 11pm",
          "Day 3 7am",
          "Day 3 3pm",
          "Day 3 11pm",
          "Day 4 7am",
          "Day 4 3pm",
          "Day 4 11pm",
          "Day 5 7am",
          "Day 5 3pm",
          "Day 5 11pm",
          "Day 6 7am",
          "Day 6 3pm",
          "Day 6 11pm",
          "Day 7 7am",
          "Day 7 3pm",
          "Day 7 11pm",
          "Day 8 7am",
          "Day 8 3pm",
          "Day 8 11pm",
        ],
        datasets: [
          {
            label: "Temp",
            fill: true,
            backgroundColor: "transparent",
            borderColor: theme.palette.primary.main,
            tension: 0,
            data: [
              null,
              37.8,
              39.0,
              37.5,
              38.9,
              37.4,
              37.5,
              37.6,
              38.4,
              38.8,
              38.8,
              37.4,
              37.2,
              37.1,
              37.1,
              37.0,
              37.0,
              37.0,
              37.0,
              37.0,
              37.1,
              37.1,
              37.1,
            ],
            spanGaps: true,
            segment: {
              borderColor: (ctx: any) =>
                tempUp(ctx, "#F44336") || theme.palette.primary.main,
              backgroundColor: (ctx: any) =>
                tempUp(ctx, "#F4433633") || "transparent",
            },
          },
        ],
      },
    },
    {
      chartName: "RR",
      data: {
        labels: [
          "Day 1 7am",
          "Day 1 3pm",
          "Day 1 11pm",
          "Day 2 7am",
          "Day 2 3pm",
          "Day 2 11pm",
          "Day 3 7am",
          "Day 3 3pm",
          "Day 3 11pm",
          "Day 4 7am",
          "Day 4 3pm",
          "Day 4 11pm",
          "Day 5 7am",
          "Day 5 3pm",
          "Day 5 11pm",
          "Day 6 7am",
          "Day 6 3pm",
          "Day 6 11pm",
          "Day 7 7am",
          "Day 7 3pm",
          "Day 7 11pm",
          "Day 8 7am",
          "Day 8 3pm",
          "Day 8 11pm",
        ],
        datasets: [
          {
            label: "RR",
            fill: true,
            backgroundColor: "transparent",
            borderColor: theme.palette.primary.main,
            tension: 0,
            data: [
              null,
              16,
              24,
              24,
              20,
              20,
              20,
              16,
              16,
              16,
              16,
              16,
              16,
              12,
              12,
              12,
              12,
              12,
              12,
              12,
              12,
              12,
              12,
            ],
          },
        ],
      },
    },
    {
      chartName: "BP",
      data: {
        labels: [
          "Day 1 7am",
          "Day 1 3pm",
          "Day 1 11pm",
          "Day 2 7am",
          "Day 2 3pm",
          "Day 2 11pm",
          "Day 3 7am",
          "Day 3 3pm",
          "Day 3 11pm",
          "Day 4 7am",
          "Day 4 3pm",
          "Day 4 11pm",
          "Day 5 7am",
          "Day 5 3pm",
          "Day 5 11pm",
          "Day 6 7am",
          "Day 6 3pm",
          "Day 6 11pm",
          "Day 7 7am",
          "Day 7 3pm",
          "Day 7 11pm",
          "Day 8 7am",
          "Day 8 3pm",
          "Day 8 11pm",
        ],
        datasets: [
          {
            label: "SBP",
            fill: true,
            backgroundColor: "transparent",
            borderColor: theme.palette.primary.main,
            borderDash: [4, 4],
            tension: 0,
            data: [
              null,
              92,
              88,
              98,
              92,
              100,
              104,
              102,
              96,
              92,
              90,
              100,
              104,
              106,
              108,
              110,
              110,
              112,
              110,
              112,
              110,
              110,
              114,
            ],
          },
          {
            label: "DBP",
            fill: true,
            backgroundColor: "transparent",
            borderColor: "#FF932E",
            tension: 0,
            data: [
              null,
              64,
              62,
              68,
              62,
              68,
              70,
              68,
              62,
              60,
              60,
              62,
              65,
              66,
              69,
              70,
              72,
              73,
              74,
              76,
              74,
              74,
              76,
            ],
          },
        ],
      },
    },
    {
      chartName: "SpO2",
      data: {
        labels: [
          "Day 1 7am",
          "Day 1 3pm",
          "Day 1 11pm",
          "Day 2 7am",
          "Day 2 3pm",
          "Day 2 11pm",
          "Day 3 7am",
          "Day 3 3pm",
          "Day 3 11pm",
          "Day 4 7am",
          "Day 4 3pm",
          "Day 4 11pm",
          "Day 5 7am",
          "Day 5 3pm",
          "Day 5 11pm",
          "Day 6 7am",
          "Day 6 3pm",
          "Day 6 11pm",
          "Day 7 7am",
          "Day 7 3pm",
          "Day 7 11pm",
          "Day 8 7am",
          "Day 8 3pm",
          "Day 8 11pm",
        ],
        datasets: [
          {
            label: "SPo2",
            fill: true,
            backgroundColor: "transparent",
            borderColor: theme.palette.primary.main,
            tension: 0,
            data: [
              null,
              86,
              85,
              87,
              88,
              89,
              91,
              91,
              92,
              93,
              93,
              92,
              93,
              94,
              94,
              93,
              94,
              93,
              94,
              94,
              94,
              94,
              94,
            ],
          },
        ],
      },
    },
    {
      chartName: "Hct",
      data: {
        labels: [
          "Day 1 3pm",
          "Day 2 7am",
          "Day 3 7am",
          "Day 4 7am",
          "Day 5 7am",
          "Day 6 7am",
          "Day 7 7am",
          "Day 8 7am",
        ],
        datasets: [
          {
            label: "Hct",
            fill: true,
            backgroundColor: "transparent",
            borderColor: theme.palette.primary.main,
            borderDash: [4, 4],
            tension: 0,
            data: [
              30.1,

              30.5,

              30.4,

              31.0,

              31.4,

              32.2,

              32.1,

              33.0,
            ],
          },
        ],
      },
    },
    {
      chartName: "Hgb",
      data: {
        labels: [
          "Day 1 3pm",
          "Day 2 7am",
          "Day 3 7am",
          "Day 4 7am",
          "Day 5 7am",
          "Day 6 7am",
          "Day 7 7am",
          "Day 8 7am",
        ],
        datasets: [
          {
            label: "Hgb",
            fill: true,
            backgroundColor: "transparent",
            borderColor: theme.palette.primary.main,
            borderDash: [4, 4],
            tension: 0,
            data: [
              11.2,

              11.1,

              11.5,

              11.6,

              11.6,

              11.6,

              11.5,

              11.8,
            ],
          },
        ],
      },
    },
  ];
};

export default chartDataThemed;
