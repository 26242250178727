import { createSlice } from "@reduxjs/toolkit";

export interface GraphAxisState {
  value: boolean;
}

const initialState: GraphAxisState = {
  value: false,
};

const graphAxisSlice = createSlice({
  name: "graphAxis",
  initialState,
  reducers: {
    changeGraphAxis: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeGraphAxis } = graphAxisSlice.actions;

export default graphAxisSlice.reducer;
