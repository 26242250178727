import { ReactComponent as ThinLayer } from "../vendor/ThinLayer-White.svg";
import { ReactComponent as UCLH } from "../vendor/uclh-logo-sidebar.svg";
import { ReactComponent as NHS } from "../vendor/NHS-logo-sidebar.svg";
import { ReactComponent as Ramsay } from "../vendor/ramsay-white.svg";
import { ReactComponent as Northwell } from "../vendor/Northwell_Sidebar.svg";
import { ReactComponent as Phoenix } from "../vendor/phoenix-home-logo-sidebar.svg";

import styled from "@emotion/styled";

const ThinLayerLogo = styled(ThinLayer)`
  height: 40px;
`;
const UCLHLogo = styled(UCLH)`
  height: 40px;
`;

const NHSLogo = styled(NHS)`
  height: 40px;
  margin-top: 5px;
`;

const PhoenixLogo = styled(Phoenix)`
  height: 40px;
`;

const RamsayLogo = styled(Ramsay)`
  height: 40px;
`;

const NorthwellLogo = styled(Northwell)`
  height: 40px;
`;

function SidebarLogo() {
  const storedTheme = localStorage.getItem("theme");

  switch (storedTheme) {
    case '"GREEN"':
      return <UCLHLogo />;
    case '"BLUE"':
      return <NHSLogo />;
    case '"PHOENIX"':
      return <PhoenixLogo />;
    case '"THINLAYER"':
      return <ThinLayerLogo />;
    case '"RAMSAY"':
      return <RamsayLogo />;
    case '"NORTHWELL"':
      return <NorthwellLogo />;
    default:
      return <ThinLayerLogo />;
  }
}

export default SidebarLogo;
