import { styled } from "@mui/material/styles";
import { useState } from "react";
import Markdown from "react-markdown";
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  Divider,
  Typography,
} from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TLIcon from "../icons/TLIcon";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ReportCardApiProps {
  title: string;
  color?: string;
  bgColor?: string;
  icon?: string;
  content: string;
  speciality?: string;
  padding?: number;
  date?: string;
  time?: string;
  by?: string;
  open: boolean;
}

const ReportCardApi = ({
  title,
  content,
  speciality,
  color,
  bgColor,
  icon,
  open,
  padding,
  date,
  time,
  by,
}: ReportCardApiProps) => {
  const [expanded, setExpanded] = useState(open);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  if (!icon) {
    icon = "";
  }
  if (!color) {
    color = "#777777";
  }
  if (!bgColor) {
    bgColor = "#F8F9FC";
  }

  const comboHeader = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 3,
      }}
    >
      {icon !== "" && <TLIcon name={icon} />}

      {title}
    </Box>
  );

  let dateObj = "";
  if (date) {
    dateObj = new Date(date).toLocaleDateString();
  }

  return (
    <Card sx={{ mb: 2, border: 1, borderColor: color + "3A" }}>
      <CardHeader
        sx={{
          backgroundColor: bgColor,
          color: color,
          p: padding,
        }}
        title={comboHeader}
        onClick={handleExpandClick}
        action={
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <Box px={4} pt={3}>
          <Typography variant="h5">{by}</Typography>
          <Typography>{speciality && speciality}</Typography>
          <Typography>
            {time} / {dateObj}
          </Typography>
          <Markdown>{content}</Markdown>
        </Box>
      </Collapse>
    </Card>
  );
};

export default ReportCardApi;
