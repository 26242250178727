import ReportCard from "../patientCards/ReportCard";
import { Typography } from "@mui/material";

const CCI = () => {
  const content = (
    <div>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Charlson Comorbidity Index (CCI): 8 points
      </Typography>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Estimated 10-year mortality: 0%
      </Typography>
      <p>
        INPUTS: Age —{`>`} 3 = 70–79 years
        <br />
        Myocardial infarction —{`>`} 0 = No
        <br />
        CHF —{`>`} 1 = Yes
        <br />
        Peripheral vascular disease —{`>`} 0 = No
        <br />
        CVA or TIA —{`>`} 0 = No
        <br />
        Dementia —{`>`} 0 = No
        <br />
        COPD —{`>`} 1 = Yes
        <br />
        Connective tissue disease —{`>`} 0 = No
        <br />
        Peptic ulcer disease —{`>`} 0 = No
        <br />
        Liver disease —{`>`} 0 = None
        <br />
        Diabetes mellitus —{`>`} 1 = Uncomplicated
        <br />
        Hemiplegia —{`>`} 0 = No
        <br />
        Moderate to severe CKD —{`>`} 0 = No
        <br />
        Solid tumor —{`>`} 2 = Localized
        <br />
        Leukemia —{`>`} 0 = No
        <br />
        Lymphoma —{`>`} 0 = No
        <br />
        AIDS —{`>`} 0 = No
        <br />
      </p>
    </div>
  );
  return (
    <ReportCard
      title="CCI: 8"
      content={content}
      color="#D61C1C"
      bgColor="#FBDDDD"
      open={false}
      padding={2}
    />
  );
};

// const CFS = () => {
//   const content = (
//     <div>
//       <Typography variant="h5" sx={{ mb: 3 }}>
//         CSHA Clinical Frailty Scale: 7 points
//       </Typography>
//       <Typography variant="h5" sx={{ mb: 1 }}>
//         Severely frail
//       </Typography>
//       <p>
//         66 %<br />
//         Approximate probability of avoidance of institutional care at 30 months
//         <br />
//         <br />
//         Approximate probability of mortality at 70 months: 50%
//         <br />
//         <br />
//         60 %<br />
//         Approximate probability of survival at 30 months
//         <br />
//         <br />
//         Approximate probability of survival at 70 months: 38%
//         <br />
//         <br />
//         INPUTS:
//         <br />
//         Canadian Triage Acuity Scale (5 level ED triage) available? &gt; 0 = No
//         <br />
//         CFS Score &gt; 7 = Completely dependent on others for the activities of
//         daily living, or terminally ill
//         <br />
//       </p>
//     </div>
//   );
//   return (
//     <ReportCard
//       title="CFS: 7"
//       content={content}
//       color="#D61C1C"
//       bgColor="#FBDDDD"
//       open={false}
//       padding={2}
//     />
//   );
// };

// const GCS = () => {
//   const content = (
//     <div>
//       <Typography variant="h5" sx={{ mb: 3 }}>
//         Glasgow Coma Scale: undetermined
//       </Typography>
//       <Typography variant="h5" sx={{ mb: 1 }}>
//         E(3) V(NT) M(6)
//       </Typography>
//       <p>
//         RESULT SUMMARY:
//         <br />
//         <br />
//         Glasgow Coma Score cannot be calculated (one or more components not
//         testable)
//         <br />
//         <br />
//         E(3) V(NT) M(6) <br />
//         Glasgow Coma Scale
//         <br />
//         <br />
//         INPUTS:
//         <br />
//         Best eye response —&gt; 3 = To verbal command (+3)
//         <br />
//         Best verbal response —&gt; 0 = Not testable/intubated (NT)
//         <br />
//         Best motor response —&gt; 6 = Obeys commands (+6)
//         <br />
//       </p>
//     </div>
//   );
//   return (
//     <ReportCard
//       title="GCS: -"
//       content={content}
//       color="#777777"
//       bgColor="#F8F9FC"
//       open={false}
//       padding={2}
//     />
//   );
// };

const Apache = () => {
  const content = (
    <div>
      <Typography variant="h5" sx={{ mb: 3 }}>
        APACHE II: 15 points
      </Typography>
      <Typography variant="h5" sx={{ mb: 1 }}>
        25% estimated nonoperative mortality
      </Typography>
      <p>
        INPUTS:
        <br />
        History of severe organ failure or immunocompromise -&gt; 1 = Yes
        <br />
        Type of surgery -&gt; 2 = Nonoperative
        <br />
        Age -&gt; 70 years
        <br />
        Temperature -&gt; 100 F<br />
        Mean arterial pressure -&gt; 75 mm Hg
        <br />
        pH -&gt; 7.38
        <br />
        Heart rate/pulse -&gt; 90 beats/min
        <br />
        Respiratory rate -&gt; 14 breaths/min
        <br />
        Sodium -&gt; 134 mEq/L
        <br />
        Potassium -&gt; 3.5 mEq/L
        <br />
        Creatinine -&gt; 1.7 mg/dL
        <br />
        Acute renal failure -&gt; 0 = No
        <br />
        Hematocrit -&gt; 48%
        <br />
        White blood cell count -&gt; 12 × 10³ cells/µL
        <br />
        Glasgow Coma Scale -&gt; 15 points
        <br />
        FiO₂ -&gt; 1 = ≥50%
        <br />
        A-a gradient -&gt; 2 = 200-349
        <br />
      </p>
    </div>
  );
  return (
    <ReportCard
      title="APACHE-II: 15"
      content={content}
      color="#299863"
      bgColor="#D7F5E6"
      open={false}
      padding={2}
    />
  );
};

// const SIRS = () => {
//   const content = (
//     <div>
//       <Typography variant="h5" sx={{ mb: 3 }}>
//         SIRS, Sepsis, and Septic Shock Criteria: 5 points
//       </Typography>
//       <Typography variant="h5" sx={{ mb: 1 }}>
//         This patient meets multiple organ dysfunction syndrome.
//       </Typography>
//       <p>
//         RESULT SUMMARY:
//         <br />
//         <br />
//         This patient meets multiple organ dysfunction syndrome. Follow your
//         guidelines for sepsis, which typically include aggressive fluid
//         resuscitation, early, broad-spectrum antibiotics, ICU consultation, CVP
//         evaluation, and occasionally pressors and transfusion.
//         <br />
//         <br />
//         INPUTS:
//         <br />
//         Temp &gt;38°C (100.4°F) or &lt;36°C (96.8°F) —&gt; 1 = Yes
//         <br />
//         Heart rate &gt;90 —&gt; 1 = Yes
//         <br />
//         Respiratory rate &gt;20 or PaCO₂ &lt;32 mm Hg —&gt; 0 = No
//         <br />
//         WBC &gt;12,000/mm³, &lt;4,000/mm³, or &gt;10% bands —&gt; 1 = Yes
//         <br />
//         Suspected or present source of infection —&gt; 1 = Yes
//         <br />
//         Lactic acidosis, SBP &lt;90 or SBP drop ≥40 mm Hg of normal —&gt; 0 = No
//         <br />
//         Severe sepsis with hypotension, despite adequate fluid resuscitation
//         —&gt; 0 = No
//         <br />
//         Evidence of ≥2 organs failing —&gt; 1 = Yes
//         <br />
//       </p>
//     </div>
//   );
//   return (
//     <ReportCard
//       title="SIRS: 5"
//       content={content}
//       color="#D61C1C"
//       bgColor="#FBDDDD"
//       open={false}
//       padding={2}
//     />
//   );
// };

const PatientScores = () => {
  return (
    <div>
      <Typography variant="h4" sx={{ mt: 4, mb: 3 }}>
        Scores
      </Typography>
      <CCI />

      <Apache />
    </div>
  );
};

export default PatientScores;
