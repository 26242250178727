import { Box, Tooltip } from "@mui/material";
import styled from "@emotion/styled";

interface MarkerProps {
  color: string;
  bgColor: string;
  icon: React.ReactNode;
  label: string;
}

const SimpleTimelineMarker: React.FC<MarkerProps> = ({
  color,
  bgColor,
  icon,
  label,
}) => {
  const Marker = styled(Box)({
    display: "flex",
    justifyContent: "center",
    height: "32px",
    width: "32px",
    alignItems: "center",
    color: color,
    backgroundColor: bgColor,
    borderRadius: "4px",
    border: `1px solid ${color}`,
  });
  return (
    <Tooltip title={label} placement="bottom">
      <Marker>{icon}</Marker>
    </Tooltip>
  );
};

export default SimpleTimelineMarker;
