import React from "react";
import HeartWarningPng from "./assets/heart-danger.png";
import styled from "@emotion/styled";
import { VitruvianAnimationForMedium } from "./assets/VitruvianAnimationForMedium";
import { Popover, Typography } from "@mui/material";

const HeartStyle = styled("img")`
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));
  top: 19.6%;
  left: 39%;
  width: 28%;
  animation-delay: 300ms;
  ${VitruvianAnimationForMedium}
  clip-path: polygon(86% 87%, 95% 65%, 98% 36%, 89% 8%, 65% 0%, 33% 0%, 11% 11%, 5% 30%, 6% 56%, 9% 78%, 36% 100%, 72% 100%);
`;

function HeartLayerDanger() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <HeartStyle
        src={HeartWarningPng}
        aria-owns={open ? "Heart-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="Heart-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>NSTEMI-LAD, Stent-LAD</Typography>
      </Popover>
    </React.Fragment>
  );
}

export default HeartLayerDanger;
