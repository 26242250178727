import React from "react";
import { Typography, Stack, Box, Chip, Tooltip } from "@mui/material";

import { useSelector } from "react-redux";
import { PatientStoryTimelineDetails } from "./PatientStoryTimelineDetails";

function PatientCodes() {
  const dayNum = useSelector((state: any) => state.day.value);

  return (
    <Box sx={{ mb: 1, px: 2 }}>
      <Typography variant="h4" sx={{ pl: 2, mt: 4, mb: 2 }}>
        Suggested Codes (ICD-10)
      </Typography>

      <Stack
        direction="row"
        sx={{ px: 2 }}
        spacing={2}
        mb={2}
        useFlexGap
        flexWrap="wrap"
      >
        {PatientStoryTimelineDetails[dayNum].codes.map((thisCode) => (
          <React.Fragment key={thisCode.code}>
            <Tooltip title={thisCode.label} placement="bottom">
              <Chip
                color="secondary"
                size="small"
                label={thisCode.code}
                key={thisCode.code}
              />
            </Tooltip>
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );
}

export default PatientCodes;
