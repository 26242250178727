import * as React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuList,
  MenuItem,
  Paper as MuiPaper,
  Stack,
  Typography,
} from "@mui/material";
import { Delete, Check, IosShare } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { spacing } from "@mui/system";

const Paper = styled(MuiPaper)(spacing);

const columns: GridColDef[] = [
  {
    field: "day",
    headerName: "Day",

    editable: true,
  },
  {
    field: "code",
    headerName: "ICD-10",

    editable: true,
  },
  {
    field: "label",
    headerName: "Description",
    flex: 1,
    editable: true,
  },
  {
    field: "notes",
    headerName: "Notes",
    flex: 1,
    editable: true,
  },
];

const rows = [
  { id: 1, day: 1, code: "E66.9", label: "Obesity, unspecified" },
  {
    id: 2,
    day: 1,
    code: "J44.1",
    label: "Chronic obstructive pulmonary disease with (acute) exacerbation",
  },
  { id: 3, day: 1, code: "R04.2", label: "Hemoptysis" },
  {
    id: 4,
    day: 1,
    code: "C34.90",
    label:
      "Malignant neoplasm of unspecified part of unspecified bronchus or lung",
  },
  {
    id: 5,
    day: 2,
    code: "J96.11",
    label: "Chronic respiratory failure with hypoxia, acute on chronic",
  },
  {
    id: 6,
    day: 2,
    code: "J44.1",
    label: "Chronic obstructive pulmonary disease with (acute) exacerbation",
  },
  {
    id: 7,
    day: 2,
    code: "R04.2",
    label: "Hemoptysis",
  },
  {
    id: 8,
    day: 2,
    code: "J18.9",
    label: "Pneumonia, unspecified organism",
  },
  {
    id: 9,
    day: 2,
    code: "T80.2",
    label: "Anaphylactic reaction due to adverse food reaction",
  },
  {
    id: 10,
    day: 2,
    code: "C34.90",
    label:
      "Malignant neoplasm of unspecified part of unspecified bronchus or lung",
  },
  {
    id: 11,
    day: 2,
    code: "R50.9",
    label: "Fever, unspecified",
  },
  {
    id: 12,
    day: 3,
    code: "J44.1",
    label: "Chronic obstructive pulmonary disease with (acute) exacerbation",
  },
  {
    id: 13,
    day: 3,
    code: "R04.2",
    label: "Hemoptysis",
  },
  {
    id: 14,
    day: 3,
    code: "Z99.81",
    label: "Dependence on respirator [ventilator] status",
  },
  {
    id: 15,
    day: 3,
    code: "R06.02",
    label: "Shortness of breath",
  },
  {
    id: 16,
    day: 3,
    code: "J15.6",
    label: "Pneumonia due to other Gram-negative bacteria",
  },
  {
    id: 17,
    day: 3,
    code: "T80.219D",
    label:
      "Unspecified infection due to central venous catheter, subsequent encounter",
  },
  {
    id: 18,
    day: 3,
    code: "Z85.118",
    label: "Personal history of malignant neoplasm of bronchus and lung",
  },
  {
    id: 19,
    day: 3,
    code: "Z90.2",
    label: "Acquired absence of lung (partial)",
  },
  {
    id: 20,
    day: 3,
    code: "E04.3",
    label: "Nontoxic single thyroid nodule",
  },
  {
    id: 21,
    day: 4,
    code: "J44.1",
    label: "Chronic obstructive pulmonary disease with (acute) exacerbation",
  },
  {
    id: 22,
    day: 4,
    code: "R04.2",
    label: "Hemoptysis",
  },
  {
    id: 23,
    day: 4,
    code: "J15.1",
    label: "Pneumonia due to Pseudomonas",
  },
  {
    id: 24,
    day: 4,
    code: "T82.7",
    label:
      "Infection and inflammatory reaction due to other cardiac and vascular devices, implants and grafts",
  },
  {
    id: 25,
    day: 4,
    code: "C34.90",
    label:
      "Malignant neoplasm of unspecified part of unspecified bronchus or lung",
  },
  {
    id: 26,
    day: 4,
    code: "E12.3",
    label:
      "Malnutrition-related diabetes mellitus with ophthalmic complications",
  },
  {
    id: 27,
    day: 4,
    code: "N39.0",
    label: "Urinary tract infection, site not specified",
  },
  {
    id: 28,
    day: 4,
    code: "B37.41",
    label: "Pulmonary candidiasis",
  },
  {
    id: 29,
    day: 4,
    code: "R50.9",
    label: "Fever, unspecified",
  },
  {
    id: 30,
    day: 5,
    code: "J44.1",
    label: "Chronic obstructive pulmonary disease with (acute) exacerbation",
  },
  {
    id: 31,
    day: 5,
    code: "R04.2",
    label: "Hemoptysis",
  },
  {
    id: 32,
    day: 5,
    code: "R06.02",
    label: "Shortness of breath",
  },
  {
    id: 33,
    day: 5,
    code: "J15.1",
    label: "Pneumonia due to Pseudomonas",
  },

  {
    id: 34,
    day: 5,
    code: "C34.90",
    label:
      "Malignant neoplasm of unspecified part of unspecified bronchus or lung",
  },

  {
    id: 35,
    day: 5,
    code: "N39.0",
    label: "Urinary tract infection, site not specified",
  },

  {
    id: 36,
    day: 5,
    code: "R50.9",
    label: "Fever, unspecified",
  },
  {
    id: 37,
    day: 6,
    code: "J44.1",
    label: "Chronic obstructive pulmonary disease with (acute) exacerbation",
  },
  {
    id: 38,
    day: 6,
    code: "R04.2",
    label: "Hemoptysis",
  },
  {
    id: 39,
    day: 6,
    code: "J15.1",
    label: "Pneumonia due to Pseudomonas",
  },
  {
    id: 40,
    day: 6,
    code: "R06.02",
    label: "Shortness of breath",
  },
  {
    id: 41,
    day: 6,
    code: "C34.90",
    label:
      "Malignant neoplasm of unspecified part of unspecified bronchus or lung",
  },
  {
    id: 42,
    day: 6,
    code: "N39.0",
    label: "Urinary tract infection, site not specified",
  },
  {
    id: 43,
    day: 6,
    code: "R50.9",
    label: "Fever, unspecified",
  },
  {
    id: 44,
    day: 6,
    code: "T80.219D",
    label:
      "Unspecified infection due to central venous catheter, subsequent encounter",
  },
  {
    id: 45,
    day: 6,
    code: "L89",
    label: "Pressure ulcer",
  },
  {
    id: 46,
    day: 7,
    code: "J44.1",
    label: "Chronic obstructive pulmonary disease with (acute) exacerbation",
  },
  {
    id: 47,
    day: 7,
    code: "R04.2",
    label: "Hemoptysis",
  },
  {
    id: 48,
    day: 7,
    code: "J15.1",
    label: "Pneumonia due to Pseudomonas",
  },
  {
    id: 49,
    day: 7,
    code: "N39.0",
    label: "Urinary tract infection, site not specified",
  },
  {
    id: 50,
    day: 7,
    code: "T80.219D",
    label:
      "Unspecified infection due to central venous catheter, subsequent encounter",
  },
  {
    id: 51,
    day: 7,
    code: "L89",
    label: "Pressure ulcer",
  },
  {
    id: 52,
    day: 7,
    code: "C34.90",
    label:
      "Malignant neoplasm of unspecified part of unspecified bronchus or lung",
  },
  {
    id: 53,
    day: 8,
    code: "J44.1",
    label: "Chronic obstructive pulmonary disease with (acute) exacerbation",
  },
  {
    id: 54,
    day: 8,
    code: "R04.2",
    label: "Hemoptysis",
  },
  {
    id: 55,
    day: 8,
    code: "J15.1",
    label: "Pneumonia due to Pseudomonas",
  },
  {
    id: 56,
    day: 8,
    code: "R06.02",
    label: "Shortness of breath",
  },
  {
    id: 57,
    day: 8,
    code: "C34.90",
    label:
      "Malignant neoplasm of unspecified part of unspecified bronchus or lung",
  },
  {
    id: 58,
    day: 8,
    code: "N39.0",
    label: "Urinary tract infection, site not specified",
  },
  {
    id: 59,
    day: 8,
    code: "R50.9",
    label: "Fever, unspecified",
  },
  {
    id: 60,
    day: 8,
    code: "T80.219D",
    label:
      "Unspecified infection due to central venous catheter, subsequent encounter",
  },
  {
    id: 61,
    day: 8,
    code: "Z12.2",
    label:
      "Encounter for screening for malignant neoplasm of respiratory organs",
  },
];

const DiagnosisTable = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ mt: 4, mb: 3 }}>
          Diagnoses
        </Typography>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Actions
          </Button>
          <Button variant="contained">Add Code</Button>
        </Stack>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuList>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Check fontSize="small" />
              </ListItemIcon>
              <ListItemText>Approve</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <IosShare fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <DataGrid
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: 50 } },
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        rows={rows}
        columns={columns}
        density="compact"
        checkboxSelection
      />
    </Paper>
  );
};

export default DiagnosisTable;
