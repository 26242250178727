import { createSlice } from "@reduxjs/toolkit";

export interface ShowScoringState {
  value: boolean;
}

const storedScores = localStorage.getItem("storedScores");

const initialState: ShowScoringState = {
  value: storedScores ? JSON.parse(storedScores) : false,
};

const showScoringSlice = createSlice({
  name: "showScoring",
  initialState,
  reducers: {
    changeShowScoring: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeShowScoring } = showScoringSlice.actions;

export default showScoringSlice.reducer;
