import styled from "@emotion/styled";

import VitruvianBase from "./assets/vitruvian-base.png";

import LungLayer from "./LungLayer";
import LungRemovalLayer from "./LungRemovalLayer";
import EsophagusLayer from "./EsophagusLayer";
import ThyroidLayer from "./ThyroidLayer";
import TracheaLayer from "./TracheaLayer";
import HeartLayer from "./HeartLayer";
import PiccLayer from "./PiccLayer";
import BladderLayer from "./BladderLayer";
import HipLayer from "./HipLayer";
import CatheterLayer from "./CatheterLayer";
import HysterectomyLayer from "./HysterectomyLayer";
import TrachTubeLayer from "./TrachTubeLayer";

import "./assets/animation.css";

interface VitruvianProps {
  width?: string;
}

const Vitruvian: React.FC<VitruvianProps> = ({ width }) => {
  const BaseLayer = styled("div")`
    position: relative;
    width: ${width};
  `;
  return (
    <BaseLayer>
      <img src={VitruvianBase} alt="Vitruvian" width="100%" />
      <EsophagusLayer />
      <ThyroidLayer />
      <LungLayer />
      <PiccLayer />
      <LungRemovalLayer />
      <TracheaLayer />
      <HeartLayer />
      <HysterectomyLayer />
      <BladderLayer />
      <TrachTubeLayer />
      <HipLayer />
      <CatheterLayer />
    </BaseLayer>
  );
};

export default Vitruvian;
