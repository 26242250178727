import React from "react";
import CatheterWarningPng from "./assets/catheter.png";
import styled from "@emotion/styled";
import { VitruvianAnimation } from "./assets/VitruvianAnimation";
import { Popover, Typography } from "@mui/material";

const CatheterStyle = styled("img")`
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.3));
  top: 47.5%;
  left: 25.5%;
  width: 27%;
  animation-delay: 800ms;
  clip-path: polygon(
    10% 96%,
    0% 85%,
    2% 46%,
    28% 43%,
    31% 33%,
    84% 19%,
    83% 0%,
    99% 1%,
    100% 35%,
    77% 51%,
    71% 92%,
    53% 100%,
    29% 100%
  );
  ${VitruvianAnimation}
`;

function CatheterLayer() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <CatheterStyle
        src={CatheterWarningPng}
        aria-owns={open ? "Catheter-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="Catheter-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>Indwelling Foley Catheter.</Typography>
      </Popover>
    </React.Fragment>
  );
}

export default CatheterLayer;
