let id = 0;
function createData(
  mdt: Date,
  name: string,
  complaint: string,
  news: number,
  request: number,
  review: boolean,
  owner: string
) {
  id += 1;
  return { id, mdt, name, complaint, news, request, review, owner };
}
const addWeeksToDate = (dateObj: Date, numberOfWeeks: number) => {
  dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7);
  return dateObj;
};

const mdtListData = [
  createData(
    addWeeksToDate(new Date(), 0),
    "Sekani Abara",
    "Osteoarthritis Right Hip",
    0,
    5,
    true,
    "Dr Scott Parazynski"
  ),
  createData(
    addWeeksToDate(new Date(), 0),
    "Susan Calvin",
    "COPD, Respiratory Failure",
    3,
    2,
    true,
    "Dr Scott Parazynski"
  ),
  createData(
    addWeeksToDate(new Date(), 1),
    "William Turner",
    "Atrial Fibrillation",
    0,
    3,
    false,
    "Dr Scott Parazynski"
  ),
  createData(
    addWeeksToDate(new Date(), 1),
    "Ethan Marcotte",
    "Traumatic Brain Injury",
    3,
    14,
    true,
    "Dr Scott Parazynski"
  ),
  createData(
    addWeeksToDate(new Date(), 1),
    "David Lee",
    "Chrohn's Disease",
    2,
    4,
    false,
    "Dr Scott Parazynski"
  ),
  createData(
    addWeeksToDate(new Date(), 2),
    "Sarah Johnson",
    "Asthma",
    1,
    1,
    false,
    "Dr Scott Parazynski"
  ),
  createData(
    addWeeksToDate(new Date(), 2),
    "Maria Rodriguez",
    "Pneumonia",
    2,
    7,
    true,
    "Dr Scott Parazynski"
  ),
  createData(
    addWeeksToDate(new Date(), 2),
    "James Brown",
    "Left Hip Fracture",
    2,
    4,
    false,
    "Dr Scott Parazynski"
  ),

  createData(
    addWeeksToDate(new Date(), 3),
    "Emily Wilson",
    "Cholecystitis",
    1,
    11,
    true,
    "Dr Scott Parazynski"
  ),
  createData(
    addWeeksToDate(new Date(), 3),
    "Michael Chang",
    "Intercranial Mass",
    0,
    1,
    true,
    "Dr Scott Parazynski"
  ),

  createData(
    addWeeksToDate(new Date(), 4),
    "Lisa Patel",
    "NSAID-induced Ulcer",
    0,
    4,
    false,
    "Dr Scott Parazynski"
  ),
];

export default mdtListData;
