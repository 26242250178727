import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseApi } from "../../config";

const scoresApi = createApi({
  reducerPath: "scoresApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseApi.url,
  }),
  endpoints: (builder) => ({
    getScores: builder.query({
      query: (id) => {
        return {
          url: `/patient/${id}/scores`,
          method: "GET",
          headers: {
            "x-api-key":
              "PMAK-6564d8090d4c2f36a8aa257b-3030dde9b280ee9393e56a5cee917b7bb0",
          },
        };
      },
    }),
  }),
});

export const { useGetScoresQuery } = scoresApi;
export { scoresApi };
