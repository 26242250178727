import * as React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuList,
  MenuItem,
  Paper as MuiPaper,
  Typography,
} from "@mui/material";
import { Delete, Check, IosShare } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { spacing } from "@mui/system";

const Paper = styled(MuiPaper)(spacing);

const columns: GridColDef[] = [
  {
    field: "day",
    headerName: "Day",

    editable: true,
  },
  {
    field: "code",
    headerName: "OPCS-4",

    editable: true,
  },
  {
    field: "label",
    headerName: "Description",
    flex: 1,
    editable: true,
  },
  {
    field: "hrg",
    headerName: "HRG Group",

    editable: true,
  },
  {
    field: "price",
    headerName: "Price",

    editable: true,
  },
  {
    field: "notes",
    headerName: "Notes",
    flex: 1,
    editable: true,
  },
];

// {
//     field: "notes",
//     headerName: "Notes",
//     width: 150,
//     renderCell: () => (
//       <Button variant="outlined" color="primary">
//         View
//       </Button>
//     ),
//   },

const rows = [
  {
    id: 1,
    day: 1,
    code: "E10.2",
    label: "Operation on nose",
    hrg: "CA25A",
    price: "£143.00",
  },
  {
    id: 2,
    day: 2,
    code: "E05.3",
    label: "",
    hrg: "CA12Z",
    price: "£966.00",
  },
  {
    id: 3,
    day: 2,
    code: "E12.1",
    label: "",
    hrg: "CA28Z",
    price: "£2,055.00",
  },
  {
    id: 4,
    day: 2,
    code: "A11.1",
    label: "",
    hrg: "AA83Z",
    price: "£0.00",
  },
  {
    id: 5,
    day: 3,
    code: "E12.9",
    label: "",
    hrg: "CA28Z",
    price: "£2,055.00",
  },
  {
    id: 6,
    day: 4,
    code: "E12.9",
    label: "",
    hrg: "YL11Z",
    price: "£1,286.00",
  },
  {
    id: 7,
    day: 5,
    code: "E04.1",
    label: "",
    hrg: "CA25A",
    price: "£143.00",
  },
  {
    id: 8,
    day: 5,
    code: "E34.1",
    label: "",
    hrg: "CA267A",
    price: "£1,836.00",
  },
  {
    id: 9,
    day: 6,
    code: "E14.1",
    label: "",
    hrg: "CA26Z",
    price: "£2,801.00",
  },
  {
    id: 10,
    day: 7,
    code: "E02.3",
    label: "",
    hrg: "CA10A",
    price: "£3,119.00",
  },
  {
    id: 11,
    day: 8,
    code: "E02.1",
    label: "",
    hrg: "CA20Z",
    price: "£3,733.00",
  },
  {
    id: 12,
    day: 8,
    code: "E33.1",
    label: "",
    hrg: "CA20Z",
    price: "£2,482.00",
  },
];

const ProcedureTable = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ mt: 4, mb: 3 }}>
          Procedures
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
          <Typography variant="h4" sx={{ mt: 4, mb: 3 }}>
            Total from NHS: £20,619.00
          </Typography>

          <Button
            variant="outlined"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Actions
          </Button>
          <Button variant="contained">Add Code</Button>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuList>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Check fontSize="small" />
              </ListItemIcon>
              <ListItemText>Approve</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <IosShare fontSize="small" />
              </ListItemIcon>
              <ListItemText>Export</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <DataGrid
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: 25 } },
        }}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        rows={rows}
        columns={columns}
        density="compact"
        checkboxSelection
      />
    </Paper>
  );
};

export default ProcedureTable;
