import { useDispatch, useSelector } from "react-redux";
import { changeSnapshot } from "../../redux/slices/snapshot";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import TimelineMarker from "./TimelineMarker";
import { SvgIcon } from "@mui/material";
import { MonitorHeart } from "@mui/icons-material";

const TransferIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 640 512"
      >
        <path d="M0 48C0 21.5 21.5 0 48 0H368c26.5 0 48 21.5 48 48V96h50.7c17 0 33.3 6.7 45.3 18.7L589.3 192c12 12 18.7 28.3 18.7 45.3V256v32 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H576c0 53-43 96-96 96s-96-43-96-96H256c0 53-43 96-96 96s-96-43-96-96H48c-26.5 0-48-21.5-48-48V48zM416 256H544V237.3L466.7 160H416v96zM160 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm368-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM176 80v48l-48 0c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V192h48c8.8 0 16-7.2 16-16V144c0-8.8-7.2-16-16-16H240V80c0-8.8-7.2-16-16-16H192c-8.8 0-16 7.2-16 16z" />
      </svg>
    </SvgIcon>
  );
};
const NurseIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 448 512"
      >
        <path d="M96 128V70.2c0-13.3 8.3-25.3 20.8-30l96-36c7.2-2.7 15.2-2.7 22.5 0l96 36c12.5 4.7 20.8 16.6 20.8 30V128h-.3c.2 2.6 .3 5.3 .3 8v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V136c0-2.7 .1-5.4 .3-8H96zm48 48c0 44.2 35.8 80 80 80s80-35.8 80-80V160H144v16zM111.9 327.7c10.5-3.4 21.8 .4 29.4 8.5l71 75.5c6.3 6.7 17 6.7 23.3 0l71-75.5c7.6-8.1 18.9-11.9 29.4-8.5C401 348.6 448 409.4 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-71.9 47-132.7 111.9-153.6zM208 48V64H192c-4.4 0-8 3.6-8 8V88c0 4.4 3.6 8 8 8h16v16c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V96h16c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8H240V48c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8z" />
      </svg>
    </SvgIcon>
  );
};
const LungIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 640 512"
      >
        <path d="M320 0c17.7 0 32 14.3 32 32V164.1c0 16.4 8.4 31.7 22.2 40.5l9.8 6.2V165.3C384 127 415 96 453.3 96c21.7 0 42.8 10.2 55.8 28.8c15.4 22.1 44.3 65.4 71 116.9c26.5 50.9 52.4 112.5 59.6 170.3c.2 1.3 .2 2.6 .2 4v7c0 49.1-39.8 89-89 89c-7.3 0-14.5-.9-21.6-2.7l-72.7-18.2C414 480.5 384 442.1 384 398V325l90.5 57.6c7.5 4.7 17.3 2.5 22.1-4.9s2.5-17.3-4.9-22.1L384 287.1v-.4l-44.1-28.1c-7.3-4.6-13.9-10.1-19.9-16.1c-5.9 6-12.6 11.5-19.9 16.1L256 286.7 161.2 347l-13.5 8.6c0 0 0 0-.1 0c-7.4 4.8-9.6 14.6-4.8 22.1c4.7 7.5 14.6 9.7 22.1 4.9l91.1-58V398c0 44.1-30 82.5-72.7 93.1l-72.7 18.2c-7.1 1.8-14.3 2.7-21.6 2.7c-49.1 0-89-39.8-89-89v-7c0-1.3 .1-2.7 .2-4c7.2-57.9 33.1-119.4 59.6-170.3c26.8-51.5 55.6-94.8 71-116.9c13-18.6 34-28.8 55.8-28.8C225 96 256 127 256 165.3v45.5l9.8-6.2c13.8-8.8 22.2-24.1 22.2-40.5V32c0-17.7 14.3-32 32-32z" />
      </svg>
    </SvgIcon>
  );
};
const XrayIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path d="M0 64C0 46.3 14.3 32 32 32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V96C14.3 96 0 81.7 0 64zM256 96c-8.8 0-16 7.2-16 16v32H160c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v48H128c-8.8 0-16 7.2-16 16s7.2 16 16 16H240v70.6L189.1 307c-5.2-2-10.6-3-16.2-3h-2.1c-23.6 0-42.8 19.2-42.8 42.8c0 9.6 3.2 18.9 9.1 26.4l18.2 23.2c9.7 12.4 24.6 19.6 40.3 19.6H316.4c15.7 0 30.6-7.2 40.3-19.6l18.2-23.2c5.9-7.5 9.1-16.8 9.1-26.4c0-23.6-19.2-42.8-42.8-42.8H339c-5.5 0-11 1-16.2 3L272 326.6V256H384c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V176h80c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V112c0-8.8-7.2-16-16-16zM208 352a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm80 16a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z" />
      </svg>
    </SvgIcon>
  );
};
const MicrobiologyIcon = (props: any) => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 512 512"
      >
        <path d="M32 32C14.3 32 0 46.3 0 64S14.3 96 32 96V384c0 53 43 96 96 96c28.6 0 54.2-12.5 71.8-32.3c.1-14.2 5.6-28.3 16.4-39.1c.2-.2 .1-.6-.2-.6c-30.9 0-56-25.1-56-56s25.1-56 56-56c.3 0 .4-.4 .2-.6c-21.9-21.9-21.9-57.3 0-79.2c2.4-2.4 5-4.6 7.8-6.5V96c17.7 0 32-14.3 32-32s-14.3-32-32-32H160 96 32zM96 192V96h64v96H96zM216 376c28.8 0 43.2 34.8 22.9 55.2c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0c20.4-20.4 55.2-5.9 55.2 22.9c0 13.3 10.7 24 24 24s24-10.7 24-24c0-28.8 34.8-43.2 55.2-22.9c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9C444.8 410.8 459.2 376 488 376c13.3 0 24-10.7 24-24s-10.7-24-24-24c-28.8 0-43.2-34.8-22.9-55.2c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0C410.8 259.2 376 244.8 376 216c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 28.8-34.8 43.2-55.2 22.9c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9c20.4 20.4 5.9 55.2-22.9 55.2c-13.3 0-24 10.7-24 24s10.7 24 24 24zm104-88a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm40 96a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z" />
      </svg>
    </SvgIcon>
  );
};

const marks = [
  {
    value: 1,
    label: "03/24",
  },
  {
    value: 2,
    label: "03/25",
  },
  {
    value: 3,
    label: "03/26",
  },
  {
    value: 4,
    label: "03/27",
  },
  {
    value: 5,
    label: "03/28",
  },
  {
    value: 6,
    label: "03/29",
  },
  {
    value: 7,
    label: "03/30",
  },
  {
    value: 8,
    label: "03/31",
  },
];

const renderedMarks = marks.map((mark) => {
  return (
    <Box
      key={mark.value}
      sx={{
        position: "absolute",
        bottom: "-40px",
        left: `${(mark.value - 1) * 13.2}%`,
      }}
    >
      {mark.label}
      <br />
      Day #{mark.value}
    </Box>
  );
});

function valuetext(value: number) {
  return `Day #${value}`;
}

function PatientTimeline() {
  const dispatch = useDispatch();
  const snapshot = useSelector((state: any) => state.snapshot.value);
  const handleChange = (event: any) => {
    dispatch(changeSnapshot(event.target.value));
  };

  return (
    <Box
      sx={{
        border: 1,
        borderColor: "grey.300",
        borderRadius: 3,
        px: 14,
        py: 14,
        height: 270,
        mb: 4,
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <TimelineMarker
          placementX="3.6%"
          color="#289862"
          bgColor="#D7F5E6"
          icon={<TransferIcon />}
          position={2}
          height="135px"
        />
        <TimelineMarker
          placementX="7.2%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={3}
          height="20px"
        />
        <TimelineMarker
          placementX="10.7%"
          color="#E57108"
          bgColor="#FBEADD"
          icon={<MonitorHeart />}
          position={4}
          height="100px"
        />
        <TimelineMarker
          placementX="14.2%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={5}
          height="20px"
        />
        <TimelineMarker
          placementX="17.8%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={6}
          height="55px"
        />
        <TimelineMarker
          placementX="21.4%"
          color="#E57108"
          bgColor="#FBEADD"
          icon={<LungIcon />}
          position={7}
          height="100px"
        />
        <TimelineMarker
          placementX="25%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={8}
          height="20px"
        />
        <TimelineMarker
          placementX="28.5%"
          color="#E57108"
          bgColor="#FBEADD"
          icon={<XrayIcon />}
          position={9}
          height="100px"
        />
        <TimelineMarker
          placementX="32.2%"
          color="#E57108"
          bgColor="#FBEADD"
          icon={<MicrobiologyIcon />}
          position={10}
          height="135px"
        />
        <TimelineMarker
          placementX="35.7%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={11}
          height="20px"
        />
        <TimelineMarker
          placementX="39.3%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={12}
          height="55px"
        />
        <TimelineMarker
          placementX="42.9%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={13}
          height="20px"
        />
        <TimelineMarker
          placementX="46.4%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={14}
          height="55px"
        />
        <TimelineMarker
          placementX="50%"
          color="#E57108"
          bgColor="#FBEADD"
          icon={<XrayIcon />}
          position={15}
          height="100px"
        />
        <TimelineMarker
          placementX="53.6%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={16}
          height="20px"
        />
        <TimelineMarker
          placementX="57.1%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={17}
          height="55px"
        />
        <TimelineMarker
          placementX="60.7%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={18}
          height="20px"
        />
        <TimelineMarker
          placementX="64.3%"
          color="#E57108"
          bgColor="#FBEADD"
          icon={<LungIcon />}
          position={19}
          height="100px"
        />
        <TimelineMarker
          placementX="67.8%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={20}
          height="20px"
        />
        <TimelineMarker
          placementX="71.4%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={21}
          height="55px"
        />
        <TimelineMarker
          placementX="75%"
          color="#E57108"
          bgColor="#FBEADD"
          icon={<XrayIcon />}
          position={22}
          height="100px"
        />
        <TimelineMarker
          placementX="78.6%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={23}
          height="20px"
        />
        <TimelineMarker
          placementX="82.1%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={24}
          height="55px"
        />
        <TimelineMarker
          placementX="85.7%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={25}
          height="20px"
        />
        <TimelineMarker
          placementX="89.3%"
          color="#E57108"
          bgColor="#FBEADD"
          icon={<XrayIcon />}
          position={26}
          height="100px"
        />
        <TimelineMarker
          placementX="92.8%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={27}
          height="20px"
        />
        <TimelineMarker
          placementX="96.4%"
          color="#707070"
          bgColor="#F8F9FC"
          icon={<NurseIcon />}
          position={28}
          height="55px"
        />
        <TimelineMarker
          placementX="100%"
          color="#E57108"
          bgColor="#FBEADD"
          icon={<MicrobiologyIcon />}
          position={29}
          height="100px"
        />
        {renderedMarks}
        <Slider
          track={false}
          aria-labelledby="track-false-slider"
          getAriaValueText={valuetext}
          value={snapshot}
          valueLabelDisplay="off"
          min={1}
          max={29}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
}

export default PatientTimeline;
