import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import vitruvion from "../../assets/patient-details-2.png";

import ReactQuill from "react-quill";
import "../../assets/custom-quill.css";

import { indigo } from "@mui/material/colors";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 100px;
    position: relative;
  }
`;

function Quill() {
  const [value, setValue] = useState("");

  return (
    <QuillWrapper>
      <ReactQuill
        modules={{
          toolbar: toolbarOptions,
        }}
        theme="snow"
        value={value}
        onChange={setValue}
        placeholder="Type something.."
      />
    </QuillWrapper>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons

  [{ list: "ordered" }, { list: "bullet" }],

  [{ header: [1, 2, 3, 4, 5, 6, false] }],
];

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : indigo[50],
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function Reports() {
  const [expanded, setExpanded] = React.useState<string | false>("panel2");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <React.Fragment>
      <Helmet title="Reports" />
      <Typography variant="h3" gutterBottom display="inline">
        Reports
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Home
        </Link>
        <Link component={NavLink} to="/list-view">
          List name
        </Link>
        <Link component={NavLink} to="/patient-detail">
          Patient name
        </Link>
        <Typography>Discharge report</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h2" gutterBottom>
                YANG, Chen-Yeol
              </Typography>
              <Divider my={6} />
              <img src={vitruvion} height="400px" alt="vitruvion" />
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography variant="h4">G.P. Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Quill />
                  <Button mt={2} variant="contained" color="success">
                    Approve Section
                  </Button>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography variant="h4">Presentation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Quill />
                  <Button mt={2} variant="contained" color="success">
                    Approve Section
                  </Button>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography variant="h4">Investigations</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Quill />
                  <Button mt={2} variant="contained" color="success">
                    Approve Section
                  </Button>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel4d-content"
                  id="panel4d-header"
                >
                  <Typography variant="h4">Diagnoses</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Quill />
                  <Button mt={2} variant="contained" color="success">
                    Approve Section
                  </Button>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  aria-controls="panel5d-content"
                  id="panel5d-header"
                >
                  <Typography variant="h4">Management</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Quill />
                  <Button mt={2} variant="contained" color="success">
                    Approve Section
                  </Button>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                >
                  <Typography variant="h4">Complications</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Quill />
                  <Button mt={2} variant="contained" color="success">
                    Approve Section
                  </Button>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  aria-controls="panel7d-content"
                  id="panel7d-header"
                >
                  <Typography variant="h4">Procedures</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Quill />
                  <Button mt={2} variant="contained" color="success">
                    Approve Section
                  </Button>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
              >
                <AccordionSummary
                  aria-controls="panel8d-content"
                  id="panel8d-header"
                >
                  <Typography variant="h4">Future Management</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Quill />
                  <Button mt={2} variant="contained" color="success">
                    Approve Section
                  </Button>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel9"}
                onChange={handleChange("panel9")}
              >
                <AccordionSummary
                  aria-controls="panel9d-content"
                  id="panel9d-header"
                >
                  <Typography variant="h4">Medications</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Quill />
                  <Button mt={2} variant="contained" color="success">
                    Approve Section
                  </Button>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel10"}
                onChange={handleChange("panel10")}
              >
                <AccordionSummary
                  aria-controls="panel10d-content"
                  id="panel10d-header"
                >
                  <Typography variant="h4">Information for Patient</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Quill />
                  <Button mt={2} variant="contained" color="success">
                    Approve Section
                  </Button>
                </AccordionDetails>
              </Accordion>
              <Button my={2} variant="contained" size="large" color="primary">
                Submit Discharge Summary
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Reports;
