import * as React from "react";
import { useState } from "react";
import patientData from "../../../assets/fakeData/patientData";
import { DataGrid, GridEventListener, GridToolbar } from "@mui/x-data-grid";
import { Box, Chip, Collapse, IconButton, Stack } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";

import { NEWS } from "../../../constants";

function PatientPicker() {
  const [patientList, setPatientList] = useState<string[]>([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    const upDatedPatientList = [...patientList, params.row.title];

    setPatientList(upDatedPatientList);
  };

  let patientsPicked = (
    <Stack
      direction="row"
      spacing={1}
      my={4}
      sx={{ flexWrap: "wrap", alignItems: "center" }}
    >
      <Chip sx={{ minWidth: 180 }} label="No Patients Selected" />
      <IconButton sx={{ mb: 1 }} onClick={handleExpandClick}>
        <PersonAdd />
      </IconButton>
    </Stack>
  );
  if (patientList.length > 0) {
    patientsPicked = (
      <Stack
        direction="row"
        my={4}
        sx={{ flexWrap: "wrap", alignItems: "center" }}
      >
        {patientList.map((patient: string, index: number) => (
          <Chip
            sx={{ mr: 1, mb: 1 }}
            key={index}
            label={patient}
            onDelete={() =>
              setPatientList(patientList.filter((p) => p !== patient))
            }
          />
        ))}
        <IconButton sx={{ mb: 1 }} onClick={handleExpandClick}>
          <PersonAdd />
        </IconButton>
      </Stack>
    );
  }

  return (
    <React.Fragment>
      {patientsPicked}
      <Collapse in={expanded}>
        <DataGrid
          onRowClick={handleRowClick}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          sx={{ pt: 1 }}
          rows={patientData}
          columns={[
            {
              field: "acuity",
              headerName: "NEWS",
              headerAlign: "center",
              width: 100,
              disableColumnMenu: true,
              renderCell: (params) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: NEWS[params.value],
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        border: "1px solid #0000001A",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {params.value}
                    </div>
                  </div>
                );
              },
            },

            { field: "title", headerName: "Patient", width: 150 },
            {
              field: "dob",
              headerName: "DOB",
              valueFormatter: (params) => new Date(params).toLocaleDateString(),
              width: 150,
            },
            { field: "location", headerName: "Location", width: 150 },
            {
              field: "conditions",
              headerName: "Conditions",
              flex: 1,
              renderCell(params) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      {params.value.map((condition: string) => (
                        <Chip
                          key={condition}
                          label={condition}
                          color="primary"
                        />
                      ))}
                    </Stack>
                  </Box>
                );
              },
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 100]}
        />
      </Collapse>
    </React.Fragment>
  );
}

export default PatientPicker;
