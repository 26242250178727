import { useEffect, useState } from "react";
// import axios from "../../utils/axios";
import axios from "axios";

function Patients() {
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    axios
      .request({
        method: "get",
        maxBodyLength: Infinity,
        url: "https://api.dev.jabs.ai/api/private/patients",
        headers: {
          "x-api-key":
            "gJTrp43FNCDPZ0YpmZADz0hSBTalvlTEOhsz3SyyCL4HOShGX028CzrwksXMQgb5OAuI4lbcu8xCE4loYqV6FSubbGivcPkpPYnJNvwLfoLUPEVpjzZwwH54mmG28Coz",
          Authorization:
            "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImlPVmt3QnprbGJCSFhEYktWOHJWVCJ9.eyJpc3MiOiJodHRwczovL2phYnMtZGV2LnVzLmF1dGgwLmNvbS8iLCJzdWIiOiJ2eXZESG9Kckpxb3MwdGhSZXQ2UE9GOVhYNUE3YVNNQ0BjbGllbnRzIiwiYXVkIjoiaHR0cHM6Ly9hcGkuZGV2LmphYnMuYWkiLCJpYXQiOjE2OTAzOTQ5NDUsImV4cCI6MTY5MDQ4MTM0NSwiYXpwIjoidnl2REhvSnJKcW9zMHRoUmV0NlBPRjlYWDVBN2FTTUMiLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMifQ.Vv7xNppb1Jk_NqrOYnFx3l83joaEB1VN-pCIw9ggHlQRg_ObDTpIzgtf6qq4D1RzalwABno-EKIVQzUAslK6_m-8MB6xegqm1CDtRsxUyvicfPhI6NhjIuuQjxMGYvnJCD_RYvdjS8RciqQFarmbboMx-HUGO8TUtvEJ_ObQqElIPXdvDg2918kJhT9eia43IBrKL8F5FS2-QzMlCHbQGDH66WEdyHgS8faO56-fW62NLrncnmWXoCIiaoxHSDVMA-S-sOOYI_c2kwrpYVIPHtUUAQFn9NWGEOvSJp9y9ACfzWHAZiy8mC0E7F2RmVnXTxGSe-LI1J4jhfLZsymjKQ",
        },
      })
      .then((response) => {
        setPatients(response.data);
      });
  }, []);

  return <div>{JSON.stringify(patients)}</div>;
}

export default Patients;
