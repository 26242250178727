import React from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import TLAssistImage from "../../assets/images/tlassistant.png";
import DiagnosisTable from "../../components/codingTables/DiagnosisTable";
import ProcedureTable from "../../components/codingTables/ProcedureTable";

import {
  Box,
  Card as MuiCard,
  CardContent,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Popover,
  Button,
  Typography,
} from "@mui/material";

import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function TLAssistant() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        TL Assistant
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: 2,
            p: 1,
            minWidth: 300,
          }}
        >
          <img src={TLAssistImage} alt="assist" />
        </Box>
      </Popover>
    </div>
  );
}

function PatientCodingPage() {
  return (
    <React.Fragment>
      <Helmet title="Susan Calvin" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="h3" display="inline">
            Susan Calvin
          </Typography>
        </Box>
        <TLAssistant />
      </Box>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Home
        </Link>
        <Link component={NavLink} to="/list-starred">
          Starred
        </Link>
        <Typography>Patient name</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          <ProcedureTable />
        </CardContent>
      </Card>

      <Card mb={6}>
        <CardContent>
          <DiagnosisTable />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default PatientCodingPage;
