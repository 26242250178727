import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseApi } from "../../config";

const observationsApi = createApi({
  reducerPath: "observationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseApi.url,
  }),
  endpoints: (builder) => ({
    getObservations: builder.query({
      query: (id) => {
        return {
          url: `/patient/${id}/observations`,
          method: "GET",
          headers: {
            "x-api-key":
              "PMAK-6564d8090d4c2f36a8aa257b-3030dde9b280ee9393e56a5cee917b7bb0",
          },
        };
      },
    }),
  }),
});

export const { useGetObservationsQuery } = observationsApi;
export { observationsApi };
