import React from "react";

import THRRight from "./assets/THR-right.png";
import styled from "@emotion/styled";
import { VitruvianAnimation } from "./assets/VitruvianAnimation";
import { Popover, Typography } from "@mui/material";

const BladderStyle = styled("img")`
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.3));
  top: 45%;
  left: -12%;
  width: 44.9%;
  animation-delay: 550ms;
  ${VitruvianAnimation}
  clip-path: polygon(99% 84%, 8% 82%, 0% 61%, 0% 26%, 7% 11%, 24% 9%, 100% 11%, 99% 27%, 41% 29%, 34% 41%, 25% 56%, 26% 66%, 99% 70%);
`;

function TLR() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <BladderStyle
        src={THRRight}
        aria-owns={open ? "Bladder-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="Bladder-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>Proposed Left Hip Replacement</Typography>
      </Popover>
    </React.Fragment>
  );
}

export default TLR;
