import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import "../vitruvian/assets/animation.css";

interface AIBoxProps {
  children?: React.ReactNode;
  borderColor?: string;
  backgroundColor?: string;
  textcolor?: string;
  padding?: string;
}

const Pop = ({
  children,
  borderColor,
  backgroundColor,
  textcolor,
  padding,
}: AIBoxProps) => {
  const PopContainer = styled(Box)`
    animation-name: pop;
    animation-duration: 150ms;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    background-color: ${backgroundColor};
    border: 1px solid ${borderColor};
    border-radius: 4px;
    color: ${textcolor};
    padding: ${padding};

    p:first-of-type {
      margin-top: 0;
    }
  `;

  return <PopContainer>{children}</PopContainer>;
};

export default Pop;
