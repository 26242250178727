import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import AiApprovalBox from "../aiBox/AiApprovalBox";
import { patientStories } from "../../assets/fakeData/patientStories";

import "../../assets/custom-quill.css";

const PatientStoryDayTrend = () => {
  const dayNum = useSelector((state: any) => state.day.value);
  // const stories = useSelector((state: any) => state.stories.stories);

  const thisPatient = patientStories[0].story;
  const thisDay = thisPatient.days[dayNum];

  return (
    <Box sx={{ mb: 4, px: 2 }}>
      <Typography variant="h4" sx={{ pl: 2, mt: 4, mb: 1 }}>
        Day {thisDay.dayNo} - {new Date(thisDay.date).toDateString()}
      </Typography>

      <AiApprovalBox
        approved={thisDay.dayTrend.approved}
        approvedBy={thisDay.dayTrend.approvedBy}
        content={thisDay.dayTrend.content}
      />
    </Box>
  );
};

export default PatientStoryDayTrend;
