import {
  Avatar,
  Box,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import GuestPic from "../../../assets/images/avatars/guest-user.jpg";
import styled from "@emotion/styled";

import { Mic } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import { changeCommentTimeline } from "../../../redux/slices/commentTimeline";

const Form = styled.form`
  width: 100%;
`;

const NewComment = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const commentData = useSelector(
    (state: any) => state.commentTimeline.comments
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!user) {
      return;
    }
    const value = e.target[0].value;
    const newComment = {
      id: commentData.length + 1,
      content: value,
      by: user.displayName,
      speciality: "G.P",
      avatar: user.avatar,
      date: new Date().toLocaleDateString(),
      replies: [],
    };
    const newCommentTimeline = [...commentData, newComment];
    dispatch(changeCommentTimeline(newCommentTimeline));
    e.target[0].value = null;
  };

  return (
    <Box>
      <Card variant="outlined" sx={{ py: 4, px: 4, mb: 4 }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          {!!user && <Avatar alt={user.displayName} src={user.avatar} />}

          {!user && <Avatar alt="Guest user" src={GuestPic} />}

          <FormControl sx={{ mt: 6, width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-comment">
              Comment...
            </InputLabel>
            <Form onSubmit={handleSubmit}>
              <OutlinedInput
                id="outlined-adornment-comment"
                sx={{ width: "100%" }}
                autoComplete="off"
                inputProps={{ autocomplete: "off" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="voice entry" edge="end">
                      <Mic />
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </Form>
          </FormControl>
        </Stack>
      </Card>
    </Box>
  );
};

export default NewComment;
