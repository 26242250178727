import React from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import { SidebarItemsType } from "../../types/sidebar";
import reduceChildRoutes from "./reduceChildRoutes";

type SidebarNavListProps = {
  depth: number;
  pages: SidebarItemsType[];
};

const SidebarNavList: React.FC<SidebarNavListProps> = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;
  let styling = {};
  if (depth > 0) {
    styling = {
      margin: "0 0 16px 20px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
      borderTop: "1px solid rgba(255, 255, 255, 0.1)",
      borderLeft: "1px solid rgba(255, 255, 255, 0.1)",
      borderRadius: "8px 0 0 8px",
    };
  }

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    [] as JSX.Element[]
  );

  return <Box sx={styling}>{childRoutes}</Box>;
};

export default SidebarNavList;
