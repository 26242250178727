import { ReactComponent as ThinLayer } from "../vendor/Indigo-524-ThinLayer.svg";
import { ReactComponent as UCLH } from "../vendor/uclh-logo.svg";
import { ReactComponent as NHS } from "../vendor/NHS-logo.svg";
import { ReactComponent as Ramsay } from "../vendor/Ramsay_Health_Care_logo.svg";
import { ReactComponent as Northwell } from "../vendor/Northwell_Health.svg";
import { ReactComponent as Phoenix } from "../vendor/phoenix-home-logo.svg";

import styled from "@emotion/styled";

const ThinLayerLogo = styled(ThinLayer)`
  color: ${(props) => props.theme.palette.primary.main};
  width: clamp(150px, 75%, 380px);
  padding-bottom: 2rem;
`;
const RamsayLogo = styled(Ramsay)`
  color: ${(props) => props.theme.palette.primary.main};
  width: 150px;
  padding-bottom: 2rem;
`;
const PhoenixLogo = styled(Phoenix)`
  color: ${(props) => props.theme.palette.primary.main};
  width: 500px;
  padding-bottom: 0;
`;

const UCLHLogo = styled(UCLH)`
  color: ${(props) => props.theme.palette.primary.main};
  width: clamp(150px, 50%, 175px);
  height: 150px;
  padding-bottom: 0.5rem;
`;
const UCLHContainer = styled.div`
  width: 100%;
  height: 160px;
  padding-bottom: 0.5rem;
`;

const NHSLogo = styled(NHS)`
  color: ${(props) => props.theme.palette.primary.main};
  width: 150px;
  height: 150px;
`;

const NorthwellLogo = styled(Northwell)`
  width: 260px;
  height: 200px;
`;

function BrandLogo() {
  const storedTheme = localStorage.getItem("theme");

  switch (storedTheme) {
    case '"RAMSAY"':
      return <RamsayLogo />;

    case '"PHOENIX"':
      return <PhoenixLogo />;

    case '"GREEN"':
      return (
        <UCLHContainer>
          <UCLHLogo />
        </UCLHContainer>
      );
    case '"BLUE"':
      return <NHSLogo />;
    case '"THINLAYER"':
      return <ThinLayerLogo />;
    case '"NORTHWELL"':
      return <NorthwellLogo />;
    default:
      return <ThinLayerLogo />;
  }
}

export default BrandLogo;
