let id = 0;
function createData(
  title: string,
  mrn: string,
  dob: string,
  height: string,
  weight: string,
  location: string,
  acuity: number,
  summary: string,
  conditions: string[],
  notes: any[]
) {
  id += 1;
  return {
    id,
    title,
    mrn,
    dob,
    height,
    weight,
    location,
    acuity,
    summary,
    conditions,
    notes,
  };
}

const patientData = [
  createData(
    "ABARA, Sekani",
    "093275345",
    "1958-04-12",
    "180cm",
    "79kg",
    "Medical Ward",
    0,
    "65-year old Caucasian",
    ["Osteoarthritis Right Hip"],
    []
  ),

  createData(
    "CALVIN, Susan",
    "1434534",
    "1953-03-24",
    "152cm",
    "72kg",
    "Medical ICU",
    3,
    "Ventilator-dependent woman with COPD, CHF, tracheomalacia and respiratory failure presenting from rehab with concern of blood needing to be frequently suctioned from trach, associated with low grade fever and possible hypotension. ",
    ["COPD", "Lung Cancer", "Tracheomalacia", "Respiratory Failure"],
    [
      {
        id: "1",
        date: "2021-10-07",
        content: "Respiratory Therapy: wean PEEP over next 24 hours",
        by: "Dr. Eric Ries",
      },
    ]
  ),
  createData(
    "TURNER, William",
    "093275345",
    "1958-04-12",
    "180cm",
    "79kg",
    "Surgical Ward",
    0,
    "65-year old Caucasian male with a 50-pack-year smoking history, atrial fibrillation and well-controlled CHF admitted preoperatively for cardiac ablation in the morning.",
    ["Atrial Fibrillation", "Congestive Heart Failure", "Smoking"],
    [
      {
        id: "1",
        date: "2021-10-07",
        content: "NPO after midnight",
        by: "Dr. Eric Ries",
      },
    ]
  ),
  createData(
    "MARCOTTE, Ethan",
    "1435748",
    "1967-07-02",
    "188cm",
    "78kg",
    "Neurological ICU",
    3,
    "56-year old white male was admitted to the hospital following a dense hemorrhagic stroke, with loss of speech and L-sided motor function, determined to be as a result of a large aneurysmal bleed at the base of the brain.",
    ["Stroke", "Aneurysm", "Pneumonia", "Sepsis"],
    [
      {
        id: "1",
        date: "2021-10-07",
        content: "Hospice consultation requested by family",
        by: "Dr. Joanne Smith",
      },
    ]
  ),
  createData(
    "LEE, David",
    "555111222",
    "1992-10-07",
    "178cm",
    "79kg",
    "Medical Ward",
    1,
    "31-year old man of Chinese descent with a longstanding history of Crohn's disease, as well as a history of bowel obstruction and anal fissures, presenting with diffuse abdominal discomfort, watery diarrhea for 3 days, fatigue and weight loss.]",
    ["Crohn's Disease", "Weight Loss"],
    []
  ),
  createData(
    "JOHNSON, Sarah",
    "987654321",
    "1980-05-03",
    "162cm",
    "65kg",
    "A&E",
    1,
    "43-year old woman of North African descent with a longstanding history of poorly controlled asthma, presenting with severe shortness of breath at rest, refractory to ventolin inhaler treatments at home. 2 packs per day cigarette usage.",
    ["Asthma", "Smoking"],
    []
  ),
  createData(
    "RODRIGUEZ, Maria",
    "14564566",
    "1975-11-20",
    "165cm",
    "73kg",
    "Medical ICU",
    2,
    "47-year old Hispanic female with longstanding hyperthyroidism presents in thyroid storm, with a temperature of 103 deg F, HR 120 and delirium.",
    ["Hyperthyroidism", "Graves Disease", "Thyroid Storm"],
    []
  ),
  createData(
    "BROWN, James",
    "039458490",
    "1965-02-15",
    "173cm",
    "86kg",
    "Medical ICU",
    2,
    "60-year old African American male with poorly controlled HTN, T2DM and a history of 3-vessel coronary artery bypass surgery (May 2022) presenting with BP 180/110 and a retroorbital headache.",
    ["Hypertension", "Diabetes", "Coronary Artery Disease"],
    []
  ),
  createData(
    "WILSON, Emily",
    "103948573",
    "1995-06-30",
    "163cm",
    "61kg",
    "Surgical Ward",
    1,
    "28-year old Caucasian female with BMI>40 and documented cholelithiasis, status post laparoscopic cholecystectomy 3 days prior to admission, who presents to A&E with redness, swelling and a small amount of purulent drainage from a periumbilical surgical site, associated with a temperature of 101.5 deg F.",
    [
      "Cholecystitus",
      "Laporoscopic Cholecystectomy",
      "Syrgical Would Infection",
      "Obesity",
    ],
    []
  ),
  createData(
    "CHANG, Michael",
    "3948574758",
    "1988-09-08",
    "175cm",
    "73kg",
    "Medical Ward",
    1,
    "35 year-old male with longstanding history of migraine headaches presents with a severe, atypical occipital headache and bilateral visual findings; MRI reveals a 3 cm R occipital mass without shift.",
    ["Migraine", "Intercranial Mass"],
    []
  ),
  createData(
    "PATEL, Lisa",
    "234645456",
    "1972-05-12",
    "168cm",
    "70kg",
    "Surgical Ward",
    0,
    "51-year old South Asian woman with longstanding osteoporosis and osteoarthritis, as well as NSAID-induced gastric ulcers, presenting for R total hip replacement surgery tomorrow.",
    ["Osteoporosis", "Osteoarthritis", "NSAID-induced Ulcer"],
    []
  ),
];

export default patientData;
