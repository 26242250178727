import { createSlice } from "@reduxjs/toolkit";

export interface GraphTypeState {
  value: string;
}

const initialState: GraphTypeState = {
  value: "HR",
};

const graphTypeSlice = createSlice({
  name: "graphType",
  initialState,
  reducers: {
    changeGraphType: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeGraphType } = graphTypeSlice.actions;

export default graphTypeSlice.reducer;
