import React from "react";
import HipAreaPng from "./assets/hip-area-right.png";
import styled from "@emotion/styled";
import { VitruvianAnimation } from "./assets/VitruvianAnimation";
import { Popover, Typography } from "@mui/material";

const BladderStyle = styled("img")`
  top: 45%;
  left: 10.5%;
  width: 44.5%;
  animation-delay: 350ms;
  ${VitruvianAnimation}
  clip-path: polygon(1% 99%, 1% 0%, 46% 0%, 100% 21%, 100% 64%, 100% 85%, 82% 100%);
`;

function HipArea() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <BladderStyle
        src={HipAreaPng}
        aria-owns={open ? "Bladder-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="Bladder-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>Osteoarthritis</Typography>
      </Popover>
    </React.Fragment>
  );
}

export default HipArea;
