import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
// import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { Line } from "react-chartjs-2";
import chartDataThemed from "./chartDataThemed";
import { useDispatch, useSelector } from "react-redux";
import { changeGraphSize } from "../../redux/slices/graphSize";
import { changeGraphAxis } from "../../redux/slices/graphAxis";
import { changeGraphType } from "../../redux/slices/graphType";

function PatientStoryCharts() {
  const dispatch = useDispatch();
  const chartSizeValue = useSelector((state: any) => state.graphSize.value);
  const chartAxisValue = useSelector((state: any) => state.graphAxis.value);
  const chartTypeValue = useSelector((state: any) => state.graphType.value);

  // const [whichChart, setWhichChart] = useState<string>("HR");
  // const [chartOptions, setChartOptions] = useState<any>(options);
  // const [chartHeight, setChartHeight] = useState<number>(195);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          color: "rgba(0,0,0,0.15)",
        },
      },
      y: {
        display: chartAxisValue,
        borderDash: [5, 5],
        grid: {
          color: "rgba(0,0,0,0.15)",
        },
      },
    },
  };
  const theme = useTheme();
  const ChartWrapper = styled(Box)({
    height: chartSizeValue - 44,
    width: "100%",
    borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
  });

  const handleChartChange = (
    event: React.MouseEvent<HTMLElement>,
    newWhichChart: string
  ) => {
    if (newWhichChart) {
      dispatch(changeGraphType(newWhichChart));
    }
  };
  const changeGraphHeight = (
    event: React.MouseEvent<HTMLElement>,
    newHeight: number
  ) => {
    if (newHeight) {
      dispatch(changeGraphSize(newHeight));
      let xAxis = newHeight === 160 ? false : true;
      dispatch(changeGraphAxis(xAxis));
    }
  };

  const findChartObject = (whichChart: string) => {
    const themedChartData = chartDataThemed(theme).find(
      (chart) => chart.chartName === whichChart
    );

    return themedChartData;
  };

  const chartObject = findChartObject(chartTypeValue);

  return (
    <Box
      sx={{
        height: chartSizeValue,
        mb: 0,
        px: 0,
        pt: 2,
        pb: 0,
        position: "relative",
      }}
    >
      <ToggleButtonGroup
        color="primary"
        value={chartTypeValue}
        exclusive
        onChange={handleChartChange}
        size="small"
        sx={{
          mb: 0,
          left: 0,
          position: "sticky",
        }}
      >
        <ToggleButton value="HR">HR</ToggleButton>
        <ToggleButton value="Temp">Temp</ToggleButton>
        <ToggleButton value="RR">RR</ToggleButton>
        <ToggleButton value="BP">BP</ToggleButton>
        <ToggleButton value="SpO2">SpO2</ToggleButton>
        <ToggleButton value="Hct">Hct</ToggleButton>
        <ToggleButton value="Hgb">Hgb</ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        color="primary"
        value={chartSizeValue}
        exclusive
        onChange={changeGraphHeight}
        size="small"
        sx={{
          mb: 0,
          right: 0,
          position: "absolute",
        }}
      >
        <ToggleButton value={160}>Small</ToggleButton>
        <ToggleButton value={350}>Large</ToggleButton>
      </ToggleButtonGroup>
      <ChartWrapper>
        <Line data={(chartObject as any).data} options={options} />
      </ChartWrapper>
    </Box>
  );
}

export default PatientStoryCharts;
