import * as React from "react";

import { Box, Typography } from "@mui/material";

import ReportCardApi from "../../patientCards/ReportCardApi";

const PatientStoryCardsApiTest = ({ reports }: any) => {
  return (
    <Box>
      <Typography variant="h4" sx={{ pl: 2, mt: 4, mb: 1 }}>
        Reports
      </Typography>
      {reports.map((report: any) => {
        return (
          <ReportCardApi
            title={report.title}
            content={report.content}
            color={report.color}
            bgColor={report.bgColor}
            icon={report.icon}
            open={report.open}
            key={report.title}
            date={report.date}
            time={report.time}
            by={report.by}
          />
        );
      })}
    </Box>
  );
};

export default PatientStoryCardsApiTest;
