import React from "react";
import LungWarningPng from "./assets/lungs-warning.png";
import styled from "@emotion/styled";
import { VitruvianAnimation } from "./assets/VitruvianAnimation";
import { Popover, Typography } from "@mui/material";

const LungStyle = styled("img")`
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
  top: 14.5%;
  left: 22%;
  width: 56%;
  ${VitruvianAnimation}
  animation-delay: 250ms;
  clip-path: polygon(15% 99%, 0% 99%, 0% 69%, 0% 52%, 11% 13%, 21% 0%, 45% 0%, 50% 10%, 58% 0%, 79% 0%, 92% 15%, 100% 38%, 100% 100%, 60% 100%, 61% 75%, 53% 59%, 48% 77%, 45% 99%);
  );
`;

function LungLayerAmber() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <LungStyle
        src={LungWarningPng}
        aria-owns={open ? "lung-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="lung-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>50 years smoking history</Typography>
      </Popover>
    </React.Fragment>
  );
}

export default LungLayerAmber;
