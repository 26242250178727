import { orange, red } from "@mui/material/colors";

const noteList = [
  {
    id: 1,
    from: "Dr Eric Ries",
    src: "/static/img/avatars/eric.jpg",
    note: "Second bronchoscopy prior to discharge identified a Right Middle Lobe endobronchial lesion, biopsy pending at time of discharge.",
    color: orange[500],
    acuity: 3,
    patient: "CALVIN, Susan",
    ward: "ICU",
    url: "",
    urlText: "",
    link: "/patient/1",
  },
  {
    id: 2,
    from: "Dr Joanne Baxter",
    src: "/static/img/avatars/joanne.jpg",
    note: "Potential clinical trial here:",
    color: red[500],
    acuity: 0,
    patient: "ABARA, Sekani",
    ward: "ICU",
    url: "https://clinicaltrials.gov/study/NCT04972812?cond=Hemorrhagic%20Stroke&locStr=United%20Kingdom&country=United%20Kingdom&distance=50&rank=2",
    urlText: "https://clinicaltrials.gov/study/NCT04972812",
    link: "mdt/patient/1",
  },
];
export default noteList;
