import React from "react";

import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { NEWS } from "../../constants";

import MDTPanel from "../../components/patient/mdt/MDTPanel";

import {
  Avatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import {
  CloudUpload,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";

// import { PersonAdd } from "@mui/icons-material";
import MdtTeam from "../../components/patient/mdt/MdtTeam";

import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function PatientCard({ patient }: { patient: any }) {
  return (
    <Card mb={6}>
      <Box
        sx={{ borderBottom: 1, pb: 4, mt: 4, mx: 8, borderColor: "divider" }}
      >
        <Grid justifyContent="space-between" alignItems="center" container>
          <Grid item>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button startIcon={<KeyboardArrowLeft />} disabled size="large">
                Prev
              </Button>
            </Stack>
          </Grid>

          <Grid item pb={0}>
            <Stack direction="row" spacing={5} alignItems="center">
              <Avatar
                aria-label={"NEWS score: " + patient.acuity}
                sx={{
                  bgcolor: NEWS[patient.acuity],
                  color: "black",
                  border: "1px solid #0000001A",
                }}
              >
                {patient.acuity}
              </Avatar>
              <Stack spacing={0} alignItems="flex-start">
                <Typography variant="h5" sx={{ mb: 0 }}>
                  {patient.firstName} {patient.lastName}
                </Typography>
                <Typography variant="body1" sx={{ mb: 0, mt: -1 }}>
                  [ {patient.pronouns} ]
                </Typography>
              </Stack>
              {/* <MdtTeam /> */}
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                variant="outlined"
                color="primary"
                startIcon={<CloudUpload />}
              >
                Upload Reports
                <VisuallyHiddenInput type="file" />
              </Button>
              {/* <UploadDocuments /> */}
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button endIcon={<KeyboardArrowRight />} size="large">
                Next: Susan Calvin
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <MDTPanel patient={patient} />
    </Card>
  );
}

function PatientMDT() {
  const demoPatient = {
    id: "2",
    firstName: "Sekani",
    lastName: "Abara",
    pronouns: "He / Him",
    mrn: "3465437",
    age: "72",
    acuity: 0,
    hospitalDuration: "--",
    location: "Outpatient",
    dob: "March 5, 1951",
    height: "170",
    weight: "90",
    BMI: "36",
    conditions: [
      "Osteoarthritis",
      "Obesity",
      "Heavy Smoker",
      "Myocardial Infarction",
      "Stent-LAD Coronary",
    ],
    allergies: [],
  };

  const content = <PatientCard patient={demoPatient} />;

  return (
    <React.Fragment>
      <Helmet title="Sekani Abari" />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={-4}
        mb={4}
      >
        <Breadcrumbs aria-label="Breadcrumb">
          <Link component={NavLink} to="/">
            Home
          </Link>
          <Link component={NavLink} to="/mdt/dashboard">
            MDT Dashboard
          </Link>
          <Link component={NavLink} to="/mdt/3">
            {new Date().toDateString()}
          </Link>
          <Typography>Sekani Abari</Typography>
        </Breadcrumbs>
        <MdtTeam />
      </Stack>
      {/* <Grid
        justifyContent="space-between"
        alignItems="center"
        container
        sx={{ border: "1px solid blue" }}
      >
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button startIcon={<KeyboardArrowLeft />} disabled size="large">
              Prev
            </Button>
          </Stack>
        </Grid>

        <Grid item pb={0}>
          <Stack direction="row" spacing={6} alignItems="center">
            <MdtTeam />
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudUpload />}
            >
              Upload Documents
            </Button>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button endIcon={<KeyboardArrowRight />} size="large">
              Next: Susan Calvin
            </Button>
          </Stack>
        </Grid>
      </Grid> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PatientMDT;
