import { styled } from "@mui/material/styles";
import { useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  Collapse,
  Divider,
  Typography,
} from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const RiskMatrixContainer = styled(Box)(({ theme }) => ({
  display: "grid",

  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
  gridTemplateRows: "45px 45px 45px 45px 45px 45px",
}));

const RiskMatrixCell = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  padding: 5,
}));

const RiskAssessment = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardHeader
        sx={{
          backgroundColor: "#F8F9FC",
          color: "#777777",
          p: 2,
        }}
        title="Risk Assessment"
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <RiskMatrixContainer>
          <RiskMatrixCell sx={{ bgcolor: "primary.main" }}></RiskMatrixCell>
          <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
            <Typography
              sx={{ lineHeight: 1, fontSize: "11px", fontWeight: 800 }}
            >
              INSIGNIFICANT
            </Typography>
            <Typography
              sx={{ lineHeight: 1, fontSize: "11px", fontWeight: 800 }}
            >
              (1)
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
            <Typography
              sx={{ lineHeight: 1, fontSize: "11px", fontWeight: 800 }}
            >
              MINOR
            </Typography>
            <Typography
              sx={{ lineHeight: 1, fontSize: "11px", fontWeight: 800 }}
            >
              (2)
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
            <Typography
              sx={{ lineHeight: 1, fontSize: "11px", fontWeight: 800 }}
            >
              SIGNIFICANT
            </Typography>
            <Typography
              sx={{ lineHeight: 1, fontSize: "11px", fontWeight: 800 }}
            >
              (3)
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
            <Typography
              sx={{ lineHeight: 1, fontSize: "11px", fontWeight: 800 }}
            >
              MAJOR
            </Typography>
            <Typography
              sx={{ lineHeight: 1, fontSize: "11px", fontWeight: 800 }}
            >
              (4)
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
            <Typography
              sx={{ lineHeight: 1, fontSize: "11px", fontWeight: 800 }}
            >
              SEVERE
            </Typography>
            <Typography
              sx={{ lineHeight: 1, fontSize: "11px", fontWeight: 800 }}
            >
              (5)
            </Typography>
          </RiskMatrixCell>

          <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              ALMOST CERTAIN (5)
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FEFE00",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FFA500",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FF0000",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#AA0000",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#AA0000",
            }}
          ></RiskMatrixCell>

          <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              LIKELY (4)
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FEFE00",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FEFE00",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FFA500",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1.2,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              FRAILTY
              <br />
              OBESITY
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FF0000",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#AA0000",
            }}
          ></RiskMatrixCell>

          <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              MODERATE (3)
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#92D051",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FEFE00",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1.2,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              LIVING ALONE
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FEFE00",
            }}
          >
            <Typography
              aria-owns={open ? "athritis" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              sx={{
                fontSize: "11px",
                lineHeight: 1.2,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              ARTHRITIS
            </Typography>
            {/* <Popover
              id="arthritis"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 3 }}>Athritis mitigation</Typography>
            </Popover> */}
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FFA500",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FF0000",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1.2,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              ISCHEMIA
            </Typography>
          </RiskMatrixCell>

          <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              UNLIKELY (2)
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#00be53",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#92D051",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FEFE00",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1.2,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              INFECTION <br />
              DECUBITI
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FEFE00",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FFA500",
            }}
          ></RiskMatrixCell>

          <RiskMatrixCell sx={{ bgcolor: "primary.main", color: "white" }}>
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              RARE (1)
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#00be53",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#00be53",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1.2,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              OXYGENATION
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#92D051",
            }}
          ></RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FEFE00",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1.2,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              BLOOD LOSS
            </Typography>
          </RiskMatrixCell>
          <RiskMatrixCell
            sx={{
              borderBottom: "1px solid #0000001F",
              borderRight: "1px solid #0000001F",
              backgroundColor: "#FEFE00",
            }}
          >
            <Typography
              sx={{
                fontSize: "11px",
                lineHeight: 1.2,
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              P-EMBOLISM
            </Typography>
          </RiskMatrixCell>
        </RiskMatrixContainer>
      </Collapse>
    </Card>
  );
};

export default RiskAssessment;
