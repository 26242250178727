import React from "react";
import HeartWarningPng from "./assets/heart.png";
import styled from "@emotion/styled";
import { VitruvianAnimationForMedium } from "./assets/VitruvianAnimationForMedium";
import { Popover, Typography } from "@mui/material";

const HeartStyle = styled("img")`
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.5));
  top: 21.3%;
  left: 45.2%;
  width: 16.5%;
  animation-delay: 300ms;
  ${VitruvianAnimationForMedium}
`;

function HeartLayer() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <HeartStyle
        src={HeartWarningPng}
        aria-owns={open ? "Heart-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="Heart-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>
          Congestive Heart Failure. Pulmonary hypertension
        </Typography>
      </Popover>
    </React.Fragment>
  );
}

export default HeartLayer;
