const VitruvianAnimationForSmall = `z-index: 1;
position: absolute;
animation-name: vitruvianAnimateIn;
animation-duration: 350ms;  
animation-fill-mode: both;
animation-timing-function: ease-in-out;
transition: all 0.3s ease-in-out;
&:hover {
    cursor: pointer;
    transform: scale(1.3);
    }
`;

export { VitruvianAnimationForSmall };
