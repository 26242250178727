import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import BrandLogo from "../../theme/BrandLogo";
import { NEWS } from "../../constants";

import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  Link,
  Avatar,
} from "@mui/material";

import homeList from "../../components/home/HomeListItems";
import noteList from "../../components/home/NoteListItems";

function RecentLists() {
  return (
    <Box sx={{ width: "100%", px: 8 }}>
      <Typography variant="overline" sx={{ color: "primary.contrastText" }}>
        Recent
      </Typography>
      <Grid container spacing={4} sx={{ my: 0, display: "flex" }}>
        {homeList.map((listItem) => (
          <Grid key={listItem.title} item xs={12} sm={6} md={3} lg={3}>
            <Link
              color="inherit"
              component={NavLink}
              to={listItem.href}
              underline="none"
            >
              <Box
                sx={{
                  border: 1,
                  borderColor: "rgba(255, 255, 255, 0.2)",
                  borderRadius: 2,
                  width: "100%",
                  cursor: "pointer",
                  minHeight: "120px",
                  color: "rgba(255, 255, 255, 0.8)",
                  transition: "all 0.15s ease-in-out",
                  "&:hover": {
                    background: "rgba(0, 0, 0, 0.08)",
                    borderColor: "rgba(255, 255, 255, 0.5)",
                    color: "white",
                  },
                }}
              >
                <Grid container direction="column">
                  <Grid item>
                    <Stack
                      sx={{ px: 3, pt: 3, color: "white" }}
                      direction="row"
                    >
                      <listItem.icon />
                      <Typography sx={{ ml: 2 }} variant="h6">
                        {listItem.title}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" sx={{ p: 3 }}>
                      {listItem.description}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
function NotificationsList() {
  return (
    <Box sx={{ mt: 4, pb: 24, px: 8, width: "100%" }}>
      <Typography variant="overline" sx={{ color: "primary.main" }}>
        Notes
      </Typography>
      {noteList.map((noteItem) => (
        <Link
          color="inherit"
          component={NavLink}
          to={noteItem.link}
          underline="none"
          key={noteItem.id}
        >
          <Card
            sx={{
              my: 2,
              p: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              aria-label="notification"
              sx={{
                bgcolor: NEWS[noteItem.acuity],
                color: "black",
                border: "1px solid #0000001A",
                mr: 3,
              }}
            >
              {noteItem.acuity}
            </Avatar>
            <Box sx={{ minWidth: "150px" }}>
              <Typography variant="body1" sx={{ ml: 2 }}>
                <strong>{noteItem.patient}</strong>
              </Typography>
              <Typography variant="body2" sx={{ ml: 2 }}>
                {noteItem.ward}
              </Typography>
            </Box>
            <Avatar alt={noteItem.from} src={noteItem.src} />
            <Box sx={{ ml: 2 }}>
              <Typography variant="body1" sx={{ ml: 2 }}>
                <strong>{noteItem.from}</strong>
              </Typography>
              <Typography variant="body2" sx={{ ml: 2 }}>
                {noteItem.note}
              </Typography>
              <Typography variant="body2" color="primary" sx={{ ml: 2 }}>
                {noteItem.urlText}
              </Typography>
            </Box>
          </Card>
        </Link>
      ))}
    </Box>
  );
}

function TopItems(theme: any) {
  console.log(theme);
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        borderRadius: "10px",
        pb: 8,
        pt: 4,
      }}
    >
      <RecentLists />
    </Box>
  );
}

function Home() {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Helmet title="Home" />

      <Grid
        container
        spacing={6}
        direction="column"
        justifyContent="center"
        sx={{ minHeight: "calc(100vh - 160px)" }}
      >
        <Grid
          item
          sx={{
            mx: "auto",
            width: "clamp(300px,100%, 900px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BrandLogo />

          <TopItems theme={theme} />
          <NotificationsList />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Home;
