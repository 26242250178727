import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import NewComment from "./NewComment";

import ClinicianComment from "./ClinicianComment";

const CommentTimeline = () => {
  // const { user } = useAuth();
  const commentData = useSelector(
    (state: any) => state.commentTimeline.comments
  );
  return (
    <Box>
      <Typography variant="h4" sx={{ pl: 4, mt: 4, mb: 4 }}>
        Comments
      </Typography>
      <NewComment />

      {/* <Divider sx={{ my: 4 }} /> */}
      {[...commentData].reverse().map((comment: any) => (
        <ClinicianComment
          key={comment.id}
          id={comment.id.toString()}
          content={comment.content}
          by={comment.by}
          specialty={comment.speciality}
          avatar={comment.avatar}
          date={comment.date}
          replies={comment.replies}
        />
      ))}
    </Box>
  );
};

export default CommentTimeline;
