import { createSlice } from "@reduxjs/toolkit";

export interface MarcotteStarState {
  value: boolean;
}

const initialState: MarcotteStarState = {
  value: true,
};

const marcotteStarSlice = createSlice({
  name: "marcotteStar",
  initialState,
  reducers: {
    changeMarcotteStar: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeMarcotteStar } = marcotteStarSlice.actions;

export default marcotteStarSlice.reducer;
