import React from "react";
import { useState } from "react";
import { Task, ViewMode, Gantt } from "gantt-task-react";

import { ViewSwitcherMui } from "./viewSwitcherMui";
import { initTasks } from "./helper";
// import { getStartEndDateForProject } from "./helper";
import { Box, Paper, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";

import { useTheme } from "@mui/material/styles";

import "gantt-task-react/dist/index.css";

declare module "gantt-task-react" {
  export interface Task {
    progress: number;
    styles?: {
      backgroundColor?: string;
      backgroundSelectedColor?: string;
      progressColor?: string;
      progressSelectedColor?: string;
    };
    isDisabled?: boolean;
    project?: string;
    indication?: string;
    grouping?: string;
    discontinuation?: string;
    dependencies?: string[];
    hideChildren?: boolean;
    displayOrder?: number;
  }

  export interface EventOption {
    /**
     * Time step value for date changes.
     */
    timeStep?: number;
    /**
     * Invokes on bar select on unselect.
     */
    onSelect?: (task: Task, isSelected: boolean) => void;
    /**
     * Invokes on bar double click.
     */
    onDoubleClick?: (task: Task) => void;
    /**
     * Invokes on bar click.
     */
    onClick?: (task: Task) => void;
    /**
     * Invokes on end and start time change. Chart undoes operation if method return false or error.
     */
    onDateChange?: (
      task: Task,
      children: Task[]
    ) => void | boolean | Promise<void> | Promise<boolean>;
    /**
     * Invokes on progress change. Chart undoes operation if method return false or error.
     */
    onProgressChange?: (
      task: Task,
      children: Task[]
    ) => void | boolean | Promise<void> | Promise<boolean>;
    /**
     * Invokes on delete selected task. Chart undoes operation if method return false or error.
     */
    onDelete?: (
      task: Task
    ) => void | boolean | Promise<void> | Promise<boolean>;
    /**
     * Invokes on expander on task list
     */
    onExpanderClick?: (task: Task) => void;
  }

  export interface DisplayOption {
    viewMode?: ViewMode;
  }
}

function GanttPanel() {
  const theme = useTheme();
  const [view, setView] = useState<ViewMode>(ViewMode.Day);
  const [tasks, setTasks] = useState<Task[]>(initTasks());
  const [isChecked, setIsChecked] = useState(true);
  let columnWidth = 120;
  if (view === ViewMode.Month) {
    columnWidth = 500;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  } else if (view === ViewMode.Year) {
    columnWidth = 500;
  }

  const handleTaskChange = (task: Task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));

    setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task: Task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {};

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  return (
    <Box>
      <ViewSwitcherMui
        onViewModeChange={setView}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
      />

      <Gantt
        tasks={tasks}
        viewMode={view}
        viewDate={new Date(2023, 2, 23, 18, 0, 0, 0)}
        onDateChange={handleTaskChange}
        onDelete={handleTaskDelete}
        onProgressChange={handleProgressChange}
        onDoubleClick={handleDblClick}
        onSelect={handleSelect}
        onExpanderClick={handleExpanderClick}
        listCellWidth={isChecked ? "175px" : ""}
        fontFamily="Nunito,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'"
        columnWidth={columnWidth}
        projectBackgroundColor={orange[700]}
        projectBackgroundSelectedColor={orange[700]}
        projectProgressColor={orange[700]}
        projectProgressSelectedColor={orange[700]}
        barProgressColor={theme.palette.info.main}
        barProgressSelectedColor={theme.palette.primary.main}
        TooltipContent={({ task }) => {
          return (
            <Paper variant="outlined" sx={{ px: 5, py: 4 }}>
              {task.grouping && (
                <Typography variant="overline">
                  <strong>Group: {task.grouping}</strong>{" "}
                </Typography>
              )}
              <Typography variant="h4">{task.name}</Typography>

              {task.indication && (
                <Typography mt={3}>
                  <strong>Indication: </strong> {task.indication}
                </Typography>
              )}

              {task.discontinuation && (
                <Typography>
                  <strong>Discontinuation: </strong> {task.discontinuation}
                </Typography>
              )}
            </Paper>
          );
        }}
      />
    </Box>
  );
}

export default GanttPanel;
