import * as React from "react";
import { useState } from "react";
import { Chip, Collapse, IconButton, Stack } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";

function TeamPicker() {
  const [teamList, setTeamList] = useState<string[]>([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let teamsPicked = (
    <Stack
      direction="row"
      spacing={1}
      my={4}
      sx={{ flexWrap: "wrap", alignItems: "center" }}
    >
      <Chip sx={{ minWidth: 180 }} label="No Team Member Selected" />
      <IconButton onClick={handleExpandClick}>
        <PersonAdd />
      </IconButton>
    </Stack>
  );
  if (teamList.length > 0) {
    teamsPicked = (
      <Stack
        direction="row"
        spacing={1}
        my={4}
        sx={{ flexWrap: "wrap", alignItems: "center" }}
      >
        {teamList.map((team: string, index: number) => (
          <Chip
            key={index}
            label={team}
            onDelete={() => setTeamList(teamList.filter((t) => t !== team))}
          />
        ))}
        <IconButton onClick={handleExpandClick}>
          <PersonAdd />
        </IconButton>
      </Stack>
    );
  }

  return (
    <React.Fragment>
      {teamsPicked}
      <Collapse in={expanded}>
        <div>Team Picker</div>
      </Collapse>
    </React.Fragment>
  );
}

export default TeamPicker;
