import { SidebarItemsType } from "../../types/sidebar";

import {
  AddBox,
  Groups,
  Home,
  List,
  LocalHospital,
  ManageSearch,
  Person,
  PostAdd,
  Preview,
  Receipt,
  RecentActors,
  Star,
  ViewWeek,
  Warning as DischargeDue,
  WorkspacePremium,
} from "@mui/icons-material";

const homeSection = [
  {
    href: "/",
    icon: Home,
    title: "Home",
    amount: 0,
    category: "Home",
  },
] as SidebarItemsType[];

const listsSection = [
  {
    href: "/lists",
    icon: List,
    title: "LISTS",
    amount: 0,
    children: [
      {
        href: "/list-starred",
        icon: Star,
        title: "Starred",
        amount: 10,
      },
      {
        href: "/list-discharge-due",
        icon: DischargeDue,
        title: "Discharge Due",
        amount: 6,
      },
      {
        href: "/list-tuesday",
        icon: List,
        title: "Tuesday rounds",
        amount: 26,
        category: "Personal",
      },
      {
        href: "/list-icu-study",
        icon: LocalHospital,
        title: "ICU Study",
        amount: 12,
        category: "Ward",
      },
      {
        href: "/create-list",
        icon: AddBox,
        title: "Create List",
        category: "Button",
      },
    ],
  },
] as SidebarItemsType[];

const now = new Date();
const week = 7;

const plusOne = new Date();
plusOne.setDate(plusOne.getDate() + week * 1);
const plusTwo = new Date();
plusTwo.setDate(plusTwo.getDate() + week * 2);

const mdtSection = [
  {
    href: "/mdts",
    icon: Groups,
    title: "MDT",
    children: [
      {
        href: "/mdt/dashboard",
        icon: RecentActors,
        title: "Dashboard",
        category: "TopButton",
      },
      {
        href: "/mdt/3",
        icon: Groups,
        title: now.toDateString() + ", 15.00",
        amount: 12,
        category: "",
      },
      {
        href: "/mdt/2",
        icon: Groups,
        title: plusOne.toDateString() + ", 15.00",
        amount: 9,
        category: "",
      },
      {
        href: "/mdt/1",
        icon: Groups,
        title: plusTwo.toDateString() + ", 15.30",
        amount: 3,
        category: "",
      },
      {
        href: "/mdt/create-meeting",
        icon: AddBox,
        title: "Create Meeting",
        category: "Button",
      },
    ],
  },
] as SidebarItemsType[];

const screensSection = [
  {
    href: "/pages",
    icon: Preview,
    title: "PAGES",
    children: [
      {
        href: "/coding-list",
        icon: ManageSearch,
        title: "Coding list",
      },
      {
        href: "/coding-detail",
        icon: PostAdd,
        title: "Coding detail",
      },
      {
        href: "/patient-coding",
        icon: Receipt,
        title: "Coding finance",
      },
      {
        href: "/accreditation",
        icon: WorkspacePremium,
        title: "Accreditation Dashboard",
      },
      {
        href: "/gp-view",
        icon: Person,
        title: "GP view",
      },
      {
        href: "/management",
        icon: ViewWeek,
        title: "Kanban",
      },
    ],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "",
    pages: homeSection,
    custom: false,
  },
  {
    title: "Lists",
    pages: listsSection,
    custom: false,
  },

  {
    title: "MDT",
    pages: mdtSection,
    custom: true,
  },
  {
    title: "Demo",
    pages: screensSection,
    custom: false,
  },
];

export default navItems;
