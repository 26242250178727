import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import IconSelect from "../../components/forms/formComponents/IconSelect";
import PatientPicker from "../../components/forms/formComponents/PatientPicker";
import TeamPicker from "../../components/forms/formComponents/TeamPicker";

import {
  Box,
  Button,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { AddCircle } from "@mui/icons-material";
import { changeNewList } from "../../redux/slices/newList";

import { useDispatch } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function ListCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listName, setListName] = useState<string>("");
  const [listDesc, setListDesc] = useState<string>("");
  const handleCreateList = () => {
    dispatch(
      changeNewList({
        name: listName,
        desc: listDesc,
      })
    );
    navigate("/new-list");
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{ display: "flex", alignItems: "flex-start", mt: 3, px: 3 }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconSelect />
            <TextField
              color="primary"
              sx={{ ml: 4 }}
              label="Name"
              variant="outlined"
              onChange={(e) => setListName(e.target.value)}
            />
          </Box>
          <TextField
            color="primary"
            sx={{ ml: 3, width: "40%" }}
            label="Description"
            variant="outlined"
            multiline
            onChange={(e) => setListDesc(e.target.value)}
          />
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="outlined"
            color="primary"
            size="large"
            startIcon={<AddCircle />}
            sx={{ height: "51px", ml: 4 }}
            onClick={handleCreateList}
          >
            <Typography variant="h5">Create List</Typography>
          </Button>
        </Box>
        <Divider mt={6} mb={6} />
        <Box px={3}>
          <Typography variant="h4">Patients</Typography>
          <PatientPicker />
        </Box>
        <Divider mt={2} mb={6} />
        <Box px={3}>
          <Typography variant="h4" mt={6}>
            Team
          </Typography>
          <TeamPicker />
        </Box>
      </CardContent>
    </Card>
  );
}

function CreateList() {
  return (
    <React.Fragment>
      <Helmet title="Create List" />
      <Breadcrumbs aria-label="Breadcrumb" mb={5}>
        <Link component={NavLink} to="/">
          Home
        </Link>
        <Typography>Create List</Typography>
      </Breadcrumbs>
      <Stack direction="row" pb={3} spacing={2} alignItems="center">
        <Typography variant="h3"> Create List</Typography>
      </Stack>

      <Divider mb={6} mt={2} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ListCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CreateList;
