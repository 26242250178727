import React from "react";
import PiccPng from "./assets/picc-line.png";
import styled from "@emotion/styled";
import { VitruvianAnimation } from "./assets/VitruvianAnimation";
import { Popover, Typography } from "@mui/material";

const PiccStyle = styled("img")`
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.2));
  top: 19.5%;
  left: 15.5%;
  width: 34%;
  ${VitruvianAnimation}
  animation-delay: 700ms;
  clip-path: polygon(
    100% 25%,
    100% 0%,
    26% 0%,
    3% 20%,
    0% 100%,
    28% 100%,
    20% 64%,
    20% 39%,
    38% 17%,
    72% 7%,
    89% 9%,
    90% 21%
  );
`;

function PiccLayer() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <PiccStyle
        src={PiccPng}
        aria-owns={open ? "Picc-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="Picc-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>
          Peripherally inserted central catheter (PICC)
        </Typography>
      </Popover>
    </React.Fragment>
  );
}

export default PiccLayer;
