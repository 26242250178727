export const TimelineDetails = [
  {
    jsx: (
      <div>
        <p>
          70 y/o obese Caucasian female with a history of severe,
          oxygen-dependent chronic obstructive pulmonary disease (COPD)
          presenting with blood in her tracheal tube and mild shortness of
          breath. Her medical history includes non-small cell lung cancer
          (NSCLC). She presents to the Emergency Department (ED) today from a
          respiratory rehabilitation facility where she was found diaphoretic.
          Her trach tube was suction, resulting in removal of a mucus plug
          followed by persistent blood in the trach tube.
        </p>
        <h5>1. RESPIRATORY COMPROMISE</h5>
      </div>
    ),
    title: "Summary",
  },
  {
    jsx: (
      <div>
        <p>
          70 y/o obese Caucasian female with a history of severe,
          oxygen-dependent chronic obstructive pulmonary disease (COPD)
          presenting with blood in her tracheal tube and mild shortness of
          breath. Her medical history includes non-small cell lung cancer
          (NSCLC). She presents to the Emergency Department (ED) today from a
          respiratory rehabilitation facility where she was found diaphoretic.
          Her trach tube was suction, resulting in removal of a mucus plug
          followed by persistent blood in the trach tube.
        </p>
        <h4>1. RESPIRATORY COMPROMISE</h4>
        <p>
          Concern for mechanical problem with tube or airway obstructing lesion;
          bronchoscopy done showing no obstruction and friable, bleeding
          epithelia. Presumptive diagnosis of pneumonia, begun on Zosyn in ER.
        </p>
        <h4>2. BACTERMIA WITH INDWELLING PICC LINE</h4>
        <p>
          Blood cultures were collected from PICC line; case discussed with
          surgery and ID consultants, particularly with regard to potential
          removal of the patient's PICC line.{" "}
        </p>
        <h4>3. URINARY TRACT INFECTION WITH INDWELLING FOLEY CATHETER</h4>
        <p>
          Urinanalysis consistent with UTI, culture pending. Consider adjusting
          antibiotics pending culture results, as well as changing out Foley
          catheter in MICU.
        </p>
        <h4>4. NUMEROUS OTHER CHRONIC DISEASES</h4>
        <p>
          Patient is ventilator-dependent and bedridden, and predisposed to
          further lung, bladder and line infections, as well as stasis ulcers.
          Pressure ulcer precautions in MICU.
        </p>
      </div>
    ),
    title: "Admission Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>ECHO: Mild Latrial dilation; </p>
        <p>
          RV size/motion within normal limits; aortic valve leaflets mildly
          thickened without stenosis w/o vegetations; trace aortic
          regurgitation; mitral valve leaflets mildly thickened w/o vegetations;
          1+ mitral regurgitation; moderate pulmonary artery systolic
          hypertension; pulmonic valve within normal limits; no pericardial
          effusion or suggestion of endocarditis.
        </p>
      </div>
    ),
    title: "Radiology Report",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>No obstructions, although friable, bleeding epithelia was noted</p>
      </div>
    ),
    title: "Bronchoscopy Report",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>...</p>
      </div>
    ),
    title: "X-Ray",
  },
  {
    jsx: (
      <div>
        <p>
          Blood Culture x4: + Enterococcus faecalis, sensitive to Ampicillin,
          Vancomycin & Penicillin
        </p>
        <p>
          Blood Culture (PICC Line): + Pseudomonas aeroginosa, pan-sensitive
        </p>
        <p>
          Urine Culture: + Proteus mirabilis, resistant to Ampicillin,
          Amoxacillin, Gentamycin & Bactrim; sensitive to Ciprofloxacin &
          Cefalosporins (chronic Foley catheter use)
        </p>
        <p>
          Brochoalveolar lavage (BAL): + Pseudomonas aeroginosa, pan-sensitive
        </p>
      </div>
    ),
    title: "Microbiology Report",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>...</p>
      </div>
    ),
    title: "X-Ray",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          Second bronchoscopy prior to discharge identified a Right Middle Lobe
          endobronchial lesion, biopsy pending at time of discharge.
        </p>
      </div>
    ),
    title: "Bronchoscopy Report",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>...</p>
      </div>
    ),
    title: "X-Ray",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>...</p>
      </div>
    ),
    title: "X-Ray",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          NURSING PROGRESS NOTE 7PM - 7AM
          <br />
          (Florence Nightingale - Clara Barton)
          <br />
        </p>
        <p>
          DNR STATUS: Full Code
          <br />
          Neuro: Alert, follows commands. Slow to respond at times. MAE.
          Mouthing words occ., nodding head to questions. No indications of
          pain.
          <br />
          Resp: No vent changes made overnight. LS coarse/diminished. Sxned
          infrequently for small amounts of tan/yellow, thick secretions. Sats
          95-100%. RR15-20. No cough or SOB noted.
        </p>
      </div>
    ),
    title: "Nursing Progress Note",
  },
  {
    jsx: (
      <div>
        <p>
          Blood Culture x4: + Enterococcus faecalis, sensitive to Ampicillin,
          Vancomycin & Penicillin
        </p>
        <p>
          Blood Culture (PICC Line): + Pseudomonas aeroginosa, pan-sensitive
        </p>
        <p>
          Urine Culture: + Proteus mirabilis, resistant to Ampicillin,
          Amoxacillin, Gentamycin & Bactrim; sensitive to Ciprofloxacin &
          Cefalosporins (chronic Foley catheter use)
        </p>
        <p>
          Brochoalveolar lavage (BAL): + Pseudomonas aeroginosa, pan-sensitive
        </p>
      </div>
    ),
    title: "Microbiology Report",
  },
];
