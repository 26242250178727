import { createSlice } from "@reduxjs/toolkit";

export interface NewMeetingState {
  desc: string;
  date: string;
  time: string;
  set: boolean;
}

const initialState: NewMeetingState = {
  desc: "",
  date: "",
  time: "",
  set: false,
};
const newMeetingSlice = createSlice({
  name: "newMeeting",
  initialState,
  reducers: {
    changeNewMeeting: (state, action) => {
      state.desc = action.payload.desc;
      state.date = action.payload.date;
      state.time = action.payload.time;
      state.set = true;
    },
  },
});

export const { changeNewMeeting } = newMeetingSlice.actions;

export default newMeetingSlice.reducer;
