import { styled } from "@mui/material/styles";
import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Typography,
} from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Science } from "@mui/icons-material";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const comboHeader = (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 3,
    }}
  >
    <Science />
    {new Date("2024-01-10").toLocaleDateString() + " - Pre-Op Labs"}
  </Box>
);

const PreOpLabs = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardHeader
        sx={{
          backgroundColor: "#F8F9FC",
          color: "#777777",
        }}
        title={comboHeader}
        onClick={handleExpandClick}
        action={
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <CardContent>
          <Typography variant="h5" mb={1}>
            Chem Panel
          </Typography>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{}}>
              <Typography variant="body2">Sodium</Typography>
              <Typography variant="body2">Potassium</Typography>
              <Typography variant="body2">Chloride</Typography>
              <Typography variant="body2">BUN</Typography>
              <Typography variant="body2" sx={{ color: "#DA6C08;" }}>
                <strong>Creatinine</strong>
              </Typography>
              <Typography variant="body2" sx={{ color: "#DA6C08;" }}>
                <strong>Glucose</strong>
              </Typography>
              <Typography variant="body2">AST</Typography>
              <Typography variant="body2">ALT</Typography>
              <Typography variant="body2">Bilirubin</Typography>
              <Typography variant="body2">Calcium</Typography>
            </Box>
            <Box sx={{ flexGrow: "1" }}>
              <Typography variant="body2">
                <strong>137 mEq/L</strong>
              </Typography>
              <Typography variant="body2">
                <strong>4.1 mEq/L</strong>
              </Typography>
              <Typography variant="body2">
                <strong>99 mEq/L</strong>
              </Typography>
              <Typography variant="body2">
                <strong>12 mg/dL</strong>1
              </Typography>
              <Typography variant="body2" sx={{ color: "#DA6C08;" }}>
                <strong>1.8 mg/dL</strong>1
              </Typography>
              <Typography variant="body2" sx={{ color: "#DA6C08;" }}>
                <strong>105 mg/dL</strong>1
              </Typography>
              <Typography variant="body2">
                <strong>35 U/L</strong>
              </Typography>
              <Typography variant="body2">
                <strong>27 U/L</strong>
              </Typography>
              <Typography variant="body2">
                <strong>1.1 mg/dL</strong>
              </Typography>
              <Typography variant="body2">
                <strong>8.6 mg/dL</strong>
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ mt: 4 }} />
          <Typography variant="h5" mb={1} mt={4}>
            CBC
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{}}>
              <Typography variant="body2">WBC</Typography>
              <Typography variant="body2">Hgb</Typography>
              <Typography variant="body2">Hct</Typography>
              <Typography variant="body2">Platelets</Typography>
            </Box>
            <Box sx={{ flexGrow: "1" }}>
              <Typography variant="body2">
                <strong>5.6 x10^3/uL</strong>
              </Typography>
              <Typography variant="body2">
                <strong>14.2 g/dL</strong>
              </Typography>
              <Typography variant="body2">
                <strong>39%</strong>
              </Typography>
              <Typography variant="body2">
                <strong>158k</strong>
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ mt: 4 }} />
          <Typography variant="h5" mb={1} mt={4}>
            UA
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Box sx={{}}>
              <Typography variant="body2">Color</Typography>
              <Typography variant="body2">Appearance</Typography>
              <Typography variant="body2">Specific Gravity</Typography>
              <Typography variant="body2" sx={{ color: "#DA6C08" }}>
                <strong>Glucose</strong>
              </Typography>
              <Typography variant="body2">Ketones</Typography>
              <Typography variant="body2">Nitrite</Typography>
              <Typography variant="body2">Leukocyte Esterase</Typography>
            </Box>
            <Box sx={{ flexGrow: "1" }}>
              <Typography variant="body2">
                <strong>Yellow</strong>
              </Typography>
              <Typography variant="body2">
                <strong>Clear</strong>
              </Typography>
              <Typography variant="body2">
                <strong>1.015</strong>
              </Typography>
              <Typography variant="body2" sx={{ color: "#DA6C08;" }}>
                <strong>105 mg/D</strong>
              </Typography>
              <Typography variant="body2">
                <strong>Negative</strong>
              </Typography>
              <Typography variant="body2">
                <strong>Negative</strong>
              </Typography>
              <Typography variant="body2">
                <strong>Negative</strong>
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default PreOpLabs;
