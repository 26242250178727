import {
  Avatar,
  Box,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import styled from "@emotion/styled";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import GuestPic from "../../../assets/images/avatars/guest-user.jpg";
import { AddComment, Mic } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { changeCommentTimeline } from "../../../redux/slices/commentTimeline";

interface RepliesProps {
  open: boolean;
  position: number;
}

const Form = styled.form`
  width: 100%;
`;
const Reply = ({ open, position }: RepliesProps) => {
  const { user } = useAuth();
  const [expanded, setExpanded] = useState(open);
  const dispatch = useDispatch();
  const commentData = useSelector(
    (state: any) => state.commentTimeline.comments
  );
  // console.log("comment data" + JSON.stringify(commentData));

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!user) {
      return;
    }
    const newReply = {
      id: 1,
      content: e.target[0].value,
      by: user.displayName,
      speciality: "G.P",
      avatar: user.avatar,
      date: new Date().toLocaleDateString(),
    };
    const theseReplies = [...commentData[position].replies, newReply];

    const newComment = {
      ...commentData[position],
      replies: theseReplies,
    };

    const newCommentTimeline = [
      ...commentData.slice(0, position),
      newComment,
      ...commentData.slice(position + 1),
    ];
    e.target[0].value = "";
    handleExpandClick();

    dispatch(changeCommentTimeline(newCommentTimeline));
  };

  return (
    <Box>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", paddingBottom: 1 }}
      >
        <IconButton onClick={handleExpandClick}>
          <AddComment />
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <Box sx={{ width: "100%" }}>
          <Stack
            direction="row"
            spacing={2}
            py={4}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {!!user && <Avatar alt={user.displayName} src={user.avatar} />}

            {!user && <Avatar alt="Guest user" src={GuestPic} />}
            <FormControl sx={{ mt: 6, width: "100%" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-reply">
                Reply...
              </InputLabel>
              <Form onSubmit={handleSubmit}>
                <OutlinedInput
                  id="outlined-adornment-reply"
                  sx={{ width: "100%" }}
                  autoComplete="off"
                  inputProps={{ autocomplete: "off" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="voice entry" edge="end">
                        <Mic />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </Form>
            </FormControl>
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
};
export default Reply;
