import React from "react";
import { Helmet } from "react-helmet-async";
import ScreenImage from "../../assets/images/Health Code List View_V4.png";

function CodingList() {
  return (
    <React.Fragment>
      <Helmet title="Coding List" />
      <img src={ScreenImage} alt="GP view" style={{ width: "100%" }} />
    </React.Fragment>
  );
}

export default CodingList;
