import React from "react";
import { useSelector } from "react-redux";
// import { useGetReportsQuery } from "../../../redux/store";
import reportsData from "../../../assets/fakeData/reportsData";

import Vitruvian from "../../vitruvian/Vitruvian";
// import PatientStoryCharts from "../../patientChart/PatientStoryCharts";
// import { Box, CircularProgress} from "@mui/material";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import PatientStoryBasicDetailsApiTest from "../apiTestFiles/PatientStoryBasicDetailsApiTest";
import PatientStorySummary from "../PatientStorySummary";
import PatientStoryDayTrend from "../PatientStoryDayTrend";
import PatientStoryCarePlan from "../PatientStoryCarePlan";
import PatientStoryCardsApiTest from "./PatientStoryCardsApiTest";

import PatientCodes from "../PatientCodes";
import Timeline from "../timeline/Timeline";

const GridBasicDetails = styled(Grid)(({ theme }) => ({
  paddingRight: 16,
  paddingLeft: 16,
  paddingTop: 16,
  [theme.breakpoints.up("sm")]: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));
const SecondColumn = styled(Grid)(({ theme }) => ({
  paddingRight: 8,
  paddingLeft: 8,
  paddingBottom: 16,
  paddingTop: 16,
  [theme.breakpoints.up("sm")]: {
    paddingRight: 24,
  },
  [theme.breakpoints.up("lg")]: {
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingRight: 8,
  },
}));
const ThirdColumn = styled(Grid)(({ theme }) => ({
  paddingRight: 16,
  paddingLeft: 16,
  paddingTop: 16,
  [theme.breakpoints.up("sm")]: {
    paddingRight: 32,
    paddingLeft: 32,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 16,
    borderTop: "none",
  },
}));

function PatientStoryApiTest({ patient }: { patient: any }) {
  const showCodes = useSelector((state: any) => state.showCodes.value);
  const dayValue = useSelector((state: any) => state.day.value);

  //Api
  // const { data, error, isLoading } = useGetReportsQuery(patient.id);
  console.log("reportsData", reportsData);
  const testDay = dayValue;
  let showReports = null;
  let showTimeline = null;

  const theseReports = reportsData.reports[testDay].reports;

  const allReports = reportsData.reports;
  showReports = <PatientStoryCardsApiTest reports={theseReports} />;

  showTimeline = <Timeline reports={allReports} />;

  return (
    <React.Fragment>
      <Grid container pb={4} sx={{ alignItems: "center" }} columns={24}>
        <Grid item xs={24}>
          {showTimeline}
        </Grid>

        <Grid item container pb={4} columns={24}>
          <Grid
            item
            pt={5}
            pl={6}
            pb={4}
            xs={10}
            sm={5}
            md={4}
            lg={4}
            xl={4}
            xxl={3}
          >
            {/* vitruvian  */}
            <Vitruvian width="100%" />
          </Grid>

          <GridBasicDetails
            item
            xs={14}
            sm={7}
            md={8}
            lg={6}
            xl={6}
            xxl={5}
            pb={4}
          >
            {/* Basics  */}
            <PatientStoryBasicDetailsApiTest patient={patient} />
          </GridBasicDetails>

          <SecondColumn item xs={24} sm={12} md={12} lg={7} xl={7} xxl={8}>
            {/* Summary  */}
            <PatientStorySummary />
            <PatientStoryDayTrend />
            <PatientStoryCarePlan />
            {showCodes && <PatientCodes />}
          </SecondColumn>

          <ThirdColumn item xs={24} sm={24} md={24} lg={7} xl={7} xxl={8}>
            {/* Reports  */}
            {showReports}
          </ThirdColumn>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PatientStoryApiTest;
