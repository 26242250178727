import React from "react";
import TracheaWarningPng from "./assets/trachea.png";
import styled from "@emotion/styled";
import { VitruvianAnimationForSmall } from "./assets/VitruvianAnimationForSmall";
import { Popover, Typography } from "@mui/material";

const TracheaStyle = styled("img")`
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.5));
  top: 16.7%;
  left: 48.6%;
  width: 3.4%;
  animation-delay: 200ms;
  ${VitruvianAnimationForSmall}
`;

function TracheaLayer() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <TracheaStyle
        src={TracheaWarningPng}
        aria-owns={open ? "Trachea-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="Trachea-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 2 }}>Tracheomalacia - bleeding</Typography>
      </Popover>
    </React.Fragment>
  );
}

export default TracheaLayer;
