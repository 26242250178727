import React, { useState, useEffect, ReactNode } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import PatientCardDraggable from "../../components/patientCards/PatientCardDraggable";
import patientCardData from "../../assets/fakeData/patientCardData";

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import {
  GridView,
  TableRows,
  AddCircle,
  Warning,
  Update,
  Route,
} from "@mui/icons-material";

import {
  Avatar,
  AvatarGroup,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  IconButton,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const PageIcon = styled(Warning)`
  color: ${(props) => props.theme.palette.accent.main};
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const patients = patientCardData;
const singleColumn = {
  column1: {
    title: "Patients",
    description: "Patients",
    items: patients,
  },
};

const onDragEnd = (result: DropResult, columns: any, setColumns: any) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];

    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

interface LaneProps {
  column: {
    title: string;
    description: string;
  };
  children: ReactNode;
}

const Lane = ({ column, children }: LaneProps) => {
  return <Stack>{children}</Stack>;
};

function DischargeDueListPage() {
  const [columns, setColumns] = useState(singleColumn);
  const [documentReady, setDocumentReady] = useState(false);
  const [whichSort, setWhichSort] = useState<string>("");

  useEffect(() => {
    setDocumentReady(true);
  }, []);

  const handleSortChange = (event: object, value: string, setColumns: any) => {
    setWhichSort(value);

    switch (value) {
      case "risk":
        const sortedArray = patients.sort((a, b) => {
          const acuityA = a.patient.acuity;
          const acuityB = b.patient.acuity;
          if (acuityA < acuityB) {
            return 1;
          }
          if (acuityA > acuityB) {
            return -1;
          }
          // names must be equal
          return 0;
        });

        setColumns({
          singleColumn: {
            ...patients,
            items: sortedArray,
          },
        });
        break;
      default:
        const defaultArray = patients.sort((a, b) => {
          const idA = parseInt(a.patient.id);
          const idB = parseInt(b.patient.id);
          return idA - idB;
        });

        setColumns({
          singleColumn: {
            ...patients,
            items: defaultArray,
          },
        });
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Discharge Due" />
      <Breadcrumbs aria-label="Breadcrumb" mb={5}>
        <Link component={NavLink} to="/">
          Home
        </Link>
      </Breadcrumbs>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h3" gutterBottom display="flex">
          <PageIcon />
          Discharge Due
        </Typography>
        <AvatarGroup max={8}>
          <Avatar alt="Dr Eric Ries" src="/static/img/avatars/eric.jpg" />
          <Avatar>RH</Avatar>
          <Avatar alt="Dr. Amy K. Farha" src="/static/img/avatars/joanne.jpg" />
          <IconButton color="primary" aria-label="card view">
            <AddCircle />
          </IconButton>
        </AvatarGroup>
      </Box>

      <Divider mt={2} mb={1} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ToggleButtonGroup
          color="primary"
          onChange={(event, value) =>
            handleSortChange(event, value, setColumns)
          }
          value={whichSort}
          exclusive
          sx={{
            mb: 2,
            mt: 1,
          }}
        >
          <ToggleButton value="risk">
            <Warning />
          </ToggleButton>
          <ToggleButton value="discharge">
            <Update />
          </ToggleButton>
          <ToggleButton value="workflow">
            <Route />
          </ToggleButton>
        </ToggleButtonGroup>
        <Stack direction="row" spacing={2} mb={2}>
          <IconButton color="primary" aria-label="card view">
            <GridView />
          </IconButton>
          <IconButton aria-label="table view">
            <TableRows />
          </IconButton>
        </Stack>
      </Stack>
      {!!documentReady && (
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns)
            .slice(0, 1)
            .map(([columnId, column]) => (
              <Lane key={columnId} column={column}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          minHeight: 50,
                        }}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.patient.id}
                              draggableId={item.patient.id}
                              index={index}
                            >
                              {(provided) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <PatientCardDraggable
                                      patient={item.patient}
                                    />
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </Lane>
            ))}
        </DragDropContext>
      )}
    </React.Fragment>
  );
}

export default DischargeDueListPage;
